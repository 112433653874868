import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { differenceInCalendarWeeks } from "date-fns";
import CompanyWidget from "../../common/CompanyWidget";
import PriorityWidget from "../../common/PriorityWidget";
import { DataContext } from "../../../context/dataContext";
import { CompaniesDocument } from "../../../model/companies.types";
import { OrdersDocument, OrderState } from "../../../model/orders.types";
import dbService, { UpdateAction, ORDERS, PACKAGINGORDERS, PACKAGINGS } from "../../../services/dbService";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS, CREATELOCATIONS, EDITLOCATIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import {
  I_CIFA,
  I_CIFS,
  I_CIPA,
  I_CIPS,
  I_COUR,
  I_CPT,
  I_DAP,
  I_DDP,
  I_DPU,
  I_EXW,
  I_FAS,
  I_FCA,
  I_FOBA,
  I_FOBS,
  INCOTERMS
} from "../../../utils/suppliersUtils";
import { T_PACKAGINGORDERED2, T_PACKAGINGORDEREDITED, T_PACKAGINGORDERUNLINKED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import orderUtils, { ETAChangeNotificationInformation } from "../../../utils/orderUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import RelatedOrders from "../../common/RelatedOrders";
import { PackagingPrice, PackagingsDocument } from "../../../model/packagings.types";
import { PackagingOrder, PackagingOrderDocument } from "../../../model/packagingOrders.types";
import packagingUtils from "../../../utils/packagingUtils";
import DateInput from "../../common/DateInput";
import CancelPackagingOrderModal from "./CancelPackagingOrderModal";
import slackService from "../../../services/slackService";
import { getNotificationTargetsContext, NotificationTypeFilter } from "../../../utils/notificationUtils";
import { NotificationTypes } from "../../../model/configuration/notificationConfiguration.types";
import CustomTooltip from "../../common/Tooltip";

interface IntermediatePackagingOrder extends Omit<PackagingOrder, "supplier" | "destination"> {
  supplier: BSON.ObjectId | null;
  destination: BSON.ObjectId | null;
}

interface CreatePackagingOrderModalProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
  packagingOrder?: PackagingOrderDocument;
  order?: OrdersDocument;
}

interface CreatePackagingOrderModalState {
  ordersWithPackaging: Array<{ order: OrdersDocument; selected: boolean }>;
  selectedManufacturer: string;
  availableIncoterms: { [key: string]: Array<string> };
  packagingOrder: IntermediatePackagingOrder;
  show: boolean;
  showOffers: boolean;
  stage: "select" | "settings";
  saving: boolean;
  showCancel: boolean;
  orderQuantity: string;
  purchasePrice: string;
  totalPrice: string;
  removedOrders: Array<BSON.ObjectId>;
}

class CreatePackagingOrderModal extends PureComponent<CreatePackagingOrderModalProps, CreatePackagingOrderModalState> {
  _isMounted = false;
  constructor(props: CreatePackagingOrderModalProps) {
    super(props);
    const edit = !!props.packagingOrder;
    this.state = {
      ordersWithPackaging: [],
      selectedManufacturer: "",
      availableIncoterms: {},
      packagingOrder: edit ? _.cloneDeep(props.packagingOrder!) : this.getDefaultPackagingOrder(props.packaging),
      show: !!props.order,
      showOffers: false,
      stage: edit ? "settings" : "select",
      saving: false,
      showCancel: false,
      orderQuantity: edit ? props.packagingOrder!.orderQuantity.toString() : "0",
      purchasePrice: edit && props.packagingOrder!.purchasePrice ? props.packagingOrder!.purchasePrice.toString() : "0",
      totalPrice: edit ? props.packagingOrder!.totalPrice.toString() : "0",
      removedOrders: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.collectData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<CreatePackagingOrderModalProps>) {
    if (this._isMounted) {
      if (
        !_.isEqual(prevProps.packaging, this.props.packaging) ||
        !_.isEqual(prevProps.packagingOrder, this.props.packagingOrder) ||
        !_.isEqual(prevProps.context.orders, this.props.context.orders) ||
        !_.isEqual(prevProps.context.externalManufacturerOrders, this.props.context.externalManufacturerOrders)
      ) {
        this.collectData();
      }
      // If an order was loaded after the constructor was passed (case when orders are still loading) we need to set
      // the show flag as soon as an order appears
      if (!prevProps.order && this.props.order) this.setState({ show: true });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => {
    const { packaging, packagingOrder } = this.props;
    const edit = !!packagingOrder;
    this.setState({
      show: false,
      showOffers: false,
      stage: packagingOrder ? "settings" : "select",
      packagingOrder: packagingOrder ? _.cloneDeep(packagingOrder) : this.getDefaultPackagingOrder(packaging),
      ordersWithPackaging: this.getResetOrders(),
      saving: false,
      showCancel: false,
      selectedManufacturer: "",
      orderQuantity: edit ? packagingOrder!.orderQuantity.toString() : "0",
      purchasePrice: edit && packagingOrder!.purchasePrice ? packagingOrder!.purchasePrice.toString() : "0",
      totalPrice: edit ? packagingOrder!.totalPrice.toString() : "0",
      removedOrders: []
    });
  };

  /**
   * Builds the default packaging order object
   * @param packaging a packaging document
   */
  getDefaultPackagingOrder = (packaging: PackagingsDocument) => {
    const firstSupplier = packaging.suppliers.length > 0 ? packaging.suppliers[0] : null;
    const firstPrice = firstSupplier && firstSupplier.prices.length > 0 ? firstSupplier.prices[0] : null;
    return {
      packaging: packaging._id,
      created: new Date(),
      orderQuantity: 0,
      supplier: firstSupplier ? firstSupplier._id : null,
      expectedDelivery: new Date(),
      expiry: null,
      incoterm: firstPrice && firstPrice.incoterm ? firstPrice.incoterm : "",
      destination: null,
      notify: "asap logistics GmbH",
      carrier: "",
      packages: "",
      totalPrice: 0,
      purchasePrice: 0,
      purchaseCurrency: firstPrice && firstPrice.purchaseCurrency ? firstPrice.purchaseCurrency : "EUR",
      person: userService.getUserId(),
      relatedOrders: [],
      delivered: null,
      ordered: null,
      note: "",
      timeline: [],
      lot: ""
    };
  };

  /**
   * Reset the selected flags on all orders that contain an open order for the packaging.
   * @returns { Array<{ order: OrdersDocument; selected: boolean }> } Reset orders
   */
  getResetOrders = () => {
    const ordersWithPackaging = _.cloneDeep(this.state.ordersWithPackaging);
    // Don't reset when editing
    if (!!this.props.packagingOrder) return ordersWithPackaging;
    ordersWithPackaging.forEach(o => (o.selected = false));
    return ordersWithPackaging;
  };

  /**
   * Collects all data that is needed for the modal. These are the incoterm <-> supplier map and the orders that
   * contain open packaging orders for the packaging.
   */
  collectData = () => {
    const { packaging, context, order, packagingOrder } = this.props;
    const { ordersWithPackaging: ordersWithPackagingState } = this.state;
    const availableIncoterms: { [key: string]: Array<string> } = {};
    packaging.suppliers.forEach(s =>
      s.prices.forEach(p => {
        const key = s._id.toString();
        if (p.incoterm && !availableIncoterms[key]) availableIncoterms[key] = [p.incoterm];
        else p.incoterm && !availableIncoterms[key].includes(p.incoterm) && availableIncoterms[key].push(p.incoterm);
      })
    );
    const ordersWithPackaging = [];
    for (let i = 0; i < context.orders.length; i++) {
      const orderDoc = context.orders[i];
      if (
        ![
          OrderState.OFFER_APPROVED,
          OrderState.OFFER_RELEASED,
          OrderState.ORDER_COMMODITIES,
          OrderState.WAITING
        ].includes(orderDoc.state)
      )
        continue;
      if (orderDoc.calculations[0].packagings.some(p => p._id.toString() === packaging._id.toString() && !p.ordered)) {
        ordersWithPackaging.push({
          order: orderDoc,
          selected:
            (!!order && order._id.toString() === orderDoc._id.toString()) ||
            ordersWithPackagingState.some(o => o.selected && o.order._id.toString() === orderDoc._id.toString())
        });
      }
    }
    if (!!packagingOrder) {
      // If a packaging order is being edited load list of related orders
      for (let i = 0; i < packagingOrder.relatedOrders.length; i++) {
        const cO = packagingOrder.relatedOrders[i];
        const orderDoc: OrdersDocument = baseUtils.getDocFromCollection(context.orders, cO);
        if (orderDoc) {
          const orderWithPackaging = ordersWithPackaging.find(
            owc => owc.order._id.toString() === orderDoc._id.toString()
          );
          // Set selected to true if it was already added
          if (orderWithPackaging) {
            orderWithPackaging.selected = true;
          } else if (orderDoc.calculations[0].packagings.some(p => p._id.toString() === packaging._id.toString())) {
            // Otherwise add the related order
            ordersWithPackaging.push({
              order: orderDoc,
              selected: true
            });
          }
        }
      }
    }
    if (this._isMounted)
      this.setState({
        availableIncoterms,
        ordersWithPackaging: ordersWithPackaging,
        removedOrders: []
      });
  };

  /**
   * Handles changes of the packaging order.
   * @param e Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { availableIncoterms } = this.state;
    let orderQuantity = this.state.orderQuantity;
    let totalPrice = this.state.totalPrice;
    let purchasePrice = this.state.purchasePrice;
    const packagingOrder = _.cloneDeep(this.state.packagingOrder);
    const name = e.target.name;
    let value: string | number = e.target.value;
    // Handle numbers
    if (e.target.type === "number") {
      value = value.replaceAll(/^0+/g, "0");
      if (!value.includes(".")) value = Number(value).toString();
      if (+value >= 0) {
        if (name === "orderQuantity") {
          orderQuantity = value;
          packagingOrder.orderQuantity = +value;
        } else if (name === "purchasePrice") {
          purchasePrice = value;
          packagingOrder.purchasePrice = +value;
        } else if (name === "totalPrice") {
          totalPrice = value;
          packagingOrder.totalPrice = +value;
        }
      }
    } else {
      if (name === "expectedDelivery") {
        // @ts-ignore
        packagingOrder[name] = value ? new Date(value) : new Date();
      } else if (name === "expiry" && value) {
        // @ts-ignore
        packagingOrder[name] = new Date(value);
      } else if (name === "supplier") {
        // @ts-ignore
        packagingOrder[name] = new BSON.ObjectId(value);
      } else {
        // @ts-ignore
        packagingOrder[name] = value;
      }
    }
    // If supplier is changed update the incoterm
    if (name === "supplier") packagingOrder.incoterm = availableIncoterms[value] ? availableIncoterms[value][0] : "";
    // If supplier or incoterm are updated update the prices
    if (["supplier", "incoterm"].includes(name)) {
      const updatedPrices = this.updatePrices(packagingOrder);
      purchasePrice = updatedPrices.purchasePrice ? updatedPrices.purchasePrice.toString() : "0";
      totalPrice = updatedPrices.totalPrice.toString();
    }
    this.setState({ packagingOrder: packagingOrder, orderQuantity, purchasePrice, totalPrice });
  };

  /**
   * Handles blur of the order quantity. In this case the prices are automatically filled in.
   */
  handleBlurQuantity = () => {
    const packagingOrder = _.cloneDeep(this.state.packagingOrder);
    const updatedPrices = this.updatePrices(packagingOrder);
    this.setState({
      packagingOrder: updatedPrices,
      purchasePrice: updatedPrices.purchasePrice ? updatedPrices.purchasePrice.toString() : "0",
      totalPrice: updatedPrices.totalPrice.toString()
    });
  };

  /**
   * Handles the removal of an order in order to disconnect the order and packaging order
   * @param order the order id
   * @param remove flag if order should be removed or readded
   */
  handleRelatedOrder = (order: BSON.ObjectId, remove?: boolean) => {
    let removedOrders = _.cloneDeep(this.state.removedOrders);
    const isInRemoved = removedOrders.some(o => o.toString() === order.toString());
    if (remove && !isInRemoved) {
      removedOrders.push(order);
    } else if (!remove && isInRemoved) {
      // Undo removal
      removedOrders = removedOrders.filter(o => o.toString() !== order.toString());
    }
    this.setState({ removedOrders });
  };

  /**
   * Handles ordering a packaging.
   */
  handleOrder = async () => {
    const { packaging, context } = this.props;
    const { ordersWithPackaging, removedOrders } = this.state;
    const packagingOrder = _.cloneDeep(this.state.packagingOrder);
    const etaChangeNotificationTargets = getNotificationTargetsContext(
      NotificationTypes.ORDER_TW_CONFLICT,
      context,
      NotificationTypeFilter.SLACK
    );
    let pOrderId = this.props.packagingOrder ? this.props.packagingOrder._id : null;
    this.setState({ saving: true });
    let success;
    const edit = !!this.props.packagingOrder;
    if (!packagingOrder.expiry) packagingOrder.expiry = null;
    packagingOrder.destination = new BSON.ObjectId(packagingOrder.destination!.toString());
    if (removedOrders && removedOrders.length > 0) {
      // Filter out removed orders
      packagingOrder.relatedOrders = packagingOrder.relatedOrders.filter(
        o => !removedOrders.some(rO => rO.toString() === o.toString())
      );
    }
    if (!edit) {
      packagingOrder.created = new Date();
      packagingOrder.ordered = new Date();
      if (
        ordersWithPackaging.some(
          o => o.selected && !removedOrders.some(order => order.toString() === o.order._id.toString())
        )
      ) {
        const { actions: orderUpdateActions, notificationInformation } = this.getOrderUpdateActions(
          packaging,
          packagingOrder,
          ordersWithPackaging,
          removedOrders
        );
        if (packagingOrder.relatedOrders.length !== orderUpdateActions.length) {
          await slackService.sendMessage(
            "#interne-fehlermeldungen",
            `Orders will not be updated for packaging order. \n Packaging Order: ${JSON.stringify(
              packagingOrder
            )}; \n Removed Orders: ${JSON.stringify(removedOrders)}; \n Actions: ${JSON.stringify(
              orderUpdateActions
            )}; \n Orders with Packaging: ${JSON.stringify(
              ordersWithPackaging.map(owp => {
                return { identifier: owp.order.identifier, selected: owp.selected };
              })
            )}; `
          );
          toast.error("An unexpected error occurred. Please try again later.");
          return;
        }
        success = await dbService.callFunction("createPackagingOrderOrStock", [
          packagingOrder,
          orderUpdateActions,
          "order"
        ]);
        if (!!success) pOrderId = success as BSON.ObjectId;
        if (success) {
          orderUtils.sendETAChangeMessages(notificationInformation, false, etaChangeNotificationTargets);
        }
      } else {
        const res = await dbService.insertDocument(PACKAGINGORDERS, packagingOrder);
        success = !!res && !!res.insertedId;
        if (success) pOrderId = res!.insertedId;
      }
    } else {
      let actions: Array<UpdateAction> = [
        {
          collection: PACKAGINGORDERS,
          filter: { _id: this.props.packagingOrder!._id },
          replace: packagingOrder
        }
      ];
      const { actions: orderUpdateActions, notificationInformation } = this.getOrderUpdateActions(
        packaging,
        packagingOrder,
        ordersWithPackaging,
        removedOrders
      );
      actions = actions.concat(orderUpdateActions);
      success = await dbService.updatesAsTransaction(actions);
      if (success) {
        orderUtils.sendETAChangeMessages(notificationInformation, false, etaChangeNotificationTargets);
      }
    }
    await toastUtils.databaseOperationToast(
      !!success,
      "Packaging successfully ordered",
      "Error ordering packaging",
      () => {
        context.updateDocumentInContext(PACKAGINGS, packaging._id);
        if (pOrderId) context.updateDocumentInContext(PACKAGINGORDERS, pOrderId);
        ordersWithPackaging.forEach(o => {
          if (o.selected) context.updateDocumentInContext(ORDERS, o.order._id);
        });
        this.handleHide();
      }
    );
  };

  /**
   * Handles changing the selected manufacturer for which the open orders are filtered. Also resets the selected flags.
   * @param e Change that triggered the event
   */
  handleChangeSelectedManufacturer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedManufacturer: e.target.value, ordersWithPackaging: this.getResetOrders() });
  };

  /**
   * Handles clicking the checkbox to check all orders. Only checks the orders that are visible to the user.
   */
  handleCheckAllOrders = () => {
    const { selectedManufacturer } = this.state;
    const ordersWithPackaging = _.cloneDeep(this.state.ordersWithPackaging);
    // Check if all are checked or not
    const all = ordersWithPackaging.some(
      o =>
        orderUtils.isOrder(o.order) &&
        (!selectedManufacturer ||
          (!o.order.settings.filler && o.order.settings.manufacturer.toString() === selectedManufacturer) ||
          (o.order.settings.filler && o.order.settings.filler === selectedManufacturer)) &&
        !o.selected
    );
    // Set the flags
    ordersWithPackaging.forEach(o => {
      if (
        orderUtils.isOrder(o.order) &&
        (!selectedManufacturer ||
          (!o.order.settings.filler && o.order.settings.manufacturer.toString() === selectedManufacturer) ||
          (o.order.settings.filler && o.order.settings.filler === selectedManufacturer))
      )
        o.selected = all;
    });
    this.setState({ ordersWithPackaging: ordersWithPackaging });
  };

  /**
   * Handles checking a single order.
   * @param _id ID of the order
   */
  handleCheckOrder = (_id: BSON.ObjectId) => {
    const ordersWithPackaging = _.cloneDeep(this.state.ordersWithPackaging);
    for (let i = 0; i < ordersWithPackaging.length; i++) {
      const o = ordersWithPackaging[i];
      if (o.order._id.toString() === _id.toString()) {
        o.selected = !o.selected;
        // We will not find another match
        break;
      }
    }
    this.setState({ ordersWithPackaging });
  };

  /**
   * Handle clicking the next button. Calculates the required packaging amount in relation of the selected orders.
   */
  handleClickNext = () => {
    const { ordersWithPackaging } = this.state;
    const packagingOrder = _.cloneDeep(this.state.packagingOrder);
    const selectedOrders = ordersWithPackaging.filter(o => o.selected);
    if (selectedOrders.length > 0) {
      packagingOrder.relatedOrders = selectedOrders.map(so => so.order._id);
      packagingOrder.destination = selectedOrders[0].order.settings.filler
        ? new BSON.ObjectId(selectedOrders[0].order.settings.filler)
        : selectedOrders[0].order.settings.manufacturer;
    } else {
      packagingOrder.relatedOrders = [];
    }
    this.setState({ stage: "settings", packagingOrder: packagingOrder });
  };

  /**
   * Get actions to update orders
   * @param packaging the packaging document
   * @param packagingOrder the packaging order
   * @param ordersWithPackaging list of orders related to the packaging order
   * @param removedOrders list of orders that should not be added
   * @returns {Array<Action>} list of actions to update orders
   */
  getOrderUpdateActions = (
    packaging: PackagingsDocument,
    packagingOrder: PackagingOrder | IntermediatePackagingOrder,
    ordersWithPackaging: Array<{ order: OrdersDocument; selected: boolean }>,
    removedOrders?: Array<BSON.ObjectId>
  ): {
    actions: Array<UpdateAction>;
    notificationInformation: Array<ETAChangeNotificationInformation>;
  } => {
    const { packagingOrder: packagingOrderEdited } = this.props;
    const notificationInformation: Array<ETAChangeNotificationInformation> = [];
    const edit = !!packagingOrderEdited;
    let actions: Array<UpdateAction> = [];
    ordersWithPackaging
      .filter(o => o.selected)
      .forEach(o => {
        // When creating skip if order was removed later on
        if (!edit && removedOrders && removedOrders.some(o2 => o2.toString() === o.order._id.toString())) return;
        if (edit && removedOrders && removedOrders.some(o2 => o2.toString() === o.order._id.toString())) {
          // Handle edited packaging order with removed related order, Reset order related fields
          actions.push({
            collection: ORDERS,
            filter: { _id: o.order._id },
            update: {
              "calculations.0.packagings.$[c].ordered": null,
              "calculations.0.packagings.$[c].userOrdered": null,
              "calculations.0.packagings.$[c].delivered": null,
              "calculations.0.packagings.$[c].userDelivered": null,
              "calculations.0.packagings.$[c].eta": null
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: T_PACKAGINGORDERUNLINKED,
                date: new Date(),
                packaging: packaging._id,
                person: userService.getUserId()
              }
            },
            arrayFilters: [{ "c._id": packaging._id }]
          });
        } else {
          const calcPost = _.cloneDeep(o.order.calculations[0]);
          const pricePost = calcPost.packagings.find(p => p._id.toString() === packaging._id.toString())!;
          const pricePre = o.order.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString())!;
          pricePost.supplier = packagingOrder.supplier!;
          pricePost.price = packagingOrder.totalPrice / packagingOrder.orderQuantity;
          pricePost.totalprice = pricePost.price * pricePre.orderquantity!;
          // Recalculate price information
          const newInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(o.order, calcPost);
          actions.push({
            collection: ORDERS,
            filter: { _id: o.order._id },
            update: {
              "calculations.0.packagings.$[c].supplier": new BSON.ObjectId(packagingOrder.supplier!.toString()),
              "calculations.0.packagings.$[c].orderquantity": packagingOrder.orderQuantity,
              "calculations.0.packagings.$[c].price": packagingOrder.totalPrice / packagingOrder.orderQuantity,
              "calculations.0.packagings.$[c].totalprice": packagingOrder.totalPrice,
              "calculations.0.packagings.$[c].ordered": packagingOrderEdited
                ? packagingOrderEdited.created
                : new Date(),
              "calculations.0.packagings.$[c].userOrdered": userService.getUserId(),
              "calculations.0.packagings.$[c].delivered": null,
              "calculations.0.packagings.$[c].userDelivered": null,
              "calculations.0.packagings.$[c].eta": packagingOrder.expectedDelivery,
              "calculations.0.info": newInfo
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: packagingOrderEdited ? T_PACKAGINGORDEREDITED : T_PACKAGINGORDERED2,
                date: new Date(),
                amount: packagingOrder.orderQuantity,
                packaging: packaging._id,
                supplier: packagingOrder.supplier,
                person: userService.getUserId(),
                price: packagingOrder.totalPrice
              }
            },
            arrayFilters: [{ "c._id": packaging._id }]
          });

          // Check for target date conflict if target date of order is set
          if (o.order.targetDate) {
            // weekStartsOn 1 indicates that first day of week is monday instead of sunday
            const targetWeekDiff = differenceInCalendarWeeks(packagingOrder.expectedDelivery, o.order.targetDate, {
              weekStartsOn: 1
            });
            // If diff >= -1 then newEta target week is a week before, the same or a later week
            if (targetWeekDiff >= -1) {
              notificationInformation.push({
                materialName: packagingUtils.resolvePackagingProperties(packaging),
                materialId: packaging._id.toString(),
                materialAmountDescription: packagingOrder.orderQuantity + " pcs.",
                orderIdentifier: o.order.identifier.toString(),
                orderId: o.order._id.toString(),
                orderTargetDate: o.order.targetDate,
                materialOrderTargetDate: packagingOrder.expectedDelivery
              });
            }
          }
        }
      });
    return { actions, notificationInformation };
  };

  /**
   * Calculates the amount of packaging that is needed for the selected orders.
   * @param useOrderQuantity optional, flag to indicate to use order quantity if existing
   * @returns Needed amount of packaging
   */
  calculateRequiredAmount = (useOrderQuantity?: boolean) => {
    const { packaging } = this.props;
    const { ordersWithPackaging, removedOrders } = this.state;
    const selectedOrders = ordersWithPackaging.filter(
      o => o.selected && !removedOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    if (useOrderQuantity && selectedOrders.length !== 1) return 0;
    return selectedOrders.reduce((sum, o) => {
      const price = o.order.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString())!;
      if (useOrderQuantity) return sum + (price.orderquantity || 0);
      return sum + price.amount * (1 + price.buffer / 100) * o.order.calculations[0].units;
    }, 0);
  };

  /**
   * Resolves the best price for the packaging in relation to the specified options. The first price is returned in
   * case of no matches.
   * @param packagingOrder packaging order that contains options like incoterm and order quantity
   * @returns { PackagingPrice } Price that matches the options or first available if none matched
   */
  resolveMatchingPrice = (packagingOrder: IntermediatePackagingOrder | PackagingOrder) => {
    const { packaging } = this.props;
    if (!packagingOrder.supplier) return;
    const sup = packaging.suppliers.find(s => packagingOrder.supplier!.toString() === s._id.toString());
    if (!sup || sup.prices.length === 0) return;
    let price: PackagingPrice | undefined;
    for (let i = 0; i < sup.prices.length; i++) {
      const p = sup.prices[i];
      if (
        ((!p.incoterm && !packagingOrder.incoterm) || p.incoterm === packagingOrder.incoterm) &&
        p.moq <= packagingOrder.orderQuantity
      ) {
        if (!price || price.price > p.price) price = p;
      }
    }
    if (!price) return sup.prices[0];
    return price;
  };

  /**
   * Updates the total price, purchase price and currency if there was an update to order quantity, supplier
   * or incoterm.
   * @param packagingOrder packaging order
   * @returns { IntermediatePackagingOrder } Updated packaging order
   */
  updatePrices = (packagingOrder: IntermediatePackagingOrder | PackagingOrder) => {
    const { orderQuantity } = this.state;
    const price = this.resolveMatchingPrice(packagingOrder);
    if (!price) return packagingOrder;
    packagingOrder.totalPrice = price.price * +orderQuantity;
    packagingOrder.purchasePrice = price.purchasePrice
      ? price.purchasePrice * +orderQuantity
      : packagingOrder.totalPrice;
    packagingOrder.purchaseCurrency = price.purchaseCurrency ? price.purchaseCurrency : "EUR";
    return packagingOrder;
  };

  /**
   * Resolves all open packaging orders for the current packaging
   * @returns { offers: Array<{ order: OrdersDocument, selected: boolean }>,
   *            orders: Array<{ order: OrdersDocument, selected: boolean }> } All offers and orders that need the
   * packaging and do not already contain an order for it
   */
  resolveOpenOrdersForPackaging = () => {
    const { ordersWithPackaging } = this.state;
    const orders = [],
      offers = [];
    for (let i = 0; i < ordersWithPackaging.length; i++) {
      const order = ordersWithPackaging[i];
      if (orderUtils.isOrder(order.order)) orders.push(order);
      else offers.push(order);
    }
    return { offers, orders };
  };

  /**
   * Check the input values for errors.
   * @returns { Array<string> } All errors that were found
   */
  checkForErrors = () => {
    const { packagingOrder } = this.state;
    const errors = [];
    if (packagingOrder.orderQuantity <= 0) errors.push("Order quantity has to be above 0");
    if (!packagingOrder.supplier) errors.push("Supplier has to be set");
    if (!packagingOrder.destination) errors.push("Destination has to be set");
    if (packagingOrder.totalPrice <= 0) errors.push("Total price has to be above 0");
    return errors;
  };

  /**
   * Check the input values for possible conflicts
   */
  checkForWarnings = () => {
    const { packagingOrder, ordersWithPackaging, removedOrders } = this.state;
    const deliveryDate = packagingOrder.expectedDelivery;
    const selectedOrders = ordersWithPackaging.filter(
      o => o.selected && !removedOrders.some(o2 => o.order._id.toString() === o2.toString())
    );
    const warnings: any = {};
    const setWarning = (type: string, warning: string) => {
      if (warnings[type]) warnings[type] += ", " + warning;
      else warnings[type] = warning;
    };
    for (let order of selectedOrders) {
      if (order.order.targetDate && order.order.targetDate < deliveryDate) {
        setWarning(
          "deliveryDate",
          `Delivery date exceeds target date of AT-${order.order.identifier}: ${baseUtils.formatDate(
            order.order.targetDate
          )}`
        );
      }
    }
    return warnings;
  };

  /**
   * Renders the orders basic information (identifier and title).
   * @param order Orders document
   * @returns { JSX.Element } Order information
   */
  renderOrderInformation = (order: OrdersDocument) => {
    const isOrder = orderUtils.isOrder(order);
    return (
      <span>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link to={"/order/" + order._id.toString()} className="kt-user-card-v2__name kt-link">
              {(isOrder ? "AT-" : "AN-") + order.identifier}
            </Link>
            <span className="kt-user-card-v2__email">{order.title}</span>
          </div>
        </div>
      </span>
    );
  };

  /**
   * Renders the orders table. Also supports offers, but those are not selectable.
   * @param orders Orders that should be displayed
   * @param selectable Determines whether there are checkboxes for the orders or not
   * @param offers Flag indicating that offers are shown
   * @returns { JSX.Element } Order table
   */
  renderOrderTable = (
    orders: Array<{ order: OrdersDocument; selected: boolean }>,
    selectable: boolean,
    offers?: boolean
  ) => {
    const { packaging, context } = this.props;
    const { selectedManufacturer } = this.state;
    return (
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              {selectable && (
                <th style={{ width: "5%" }}>
                  <label className="kt-checkbox" style={{ marginBottom: "15px" }}>
                    <input
                      type="checkbox"
                      checked={
                        !orders.some(
                          o =>
                            (!selectedManufacturer ||
                              (!o.order.settings.filler &&
                                o.order.settings.manufacturer.toString() === selectedManufacturer) ||
                              (o.order.settings.filler && o.order.settings.filler === selectedManufacturer)) &&
                            !o.selected
                        )
                      }
                      onChange={this.handleCheckAllOrders}
                    />
                    <span />
                  </label>
                </th>
              )}
              <th style={{ width: selectable ? "25%" : "30%" }}>{offers ? "Offer" : "Order"}</th>
              <th style={{ width: "30%" }}>Company</th>
              <th style={{ width: "14%" }}>Required</th>
              <th style={{ width: "13%" }}>Priority</th>
              <th style={{ width: "13%" }}>Target Date</th>
            </tr>
          </thead>
          <tbody>
            {orders
              .filter(
                o =>
                  !selectedManufacturer ||
                  (!o.order.settings.filler && o.order.settings.manufacturer.toString() === selectedManufacturer) ||
                  (o.order.settings.filler && o.order.settings.filler === selectedManufacturer)
              )
              .map(o => {
                const company: CompaniesDocument = baseUtils.getDocFromCollection(
                  context.companies,
                  o.order.createdFor
                )!;
                const price = o.order.calculations[0].packagings.find(
                  p => p._id.toString() === packaging._id.toString()
                )!;
                const required = price.amount * (1 + price.buffer / 100) * o.order.calculations[0].units;
                return (
                  <tr key={o.order._id.toString()}>
                    {selectable && (
                      <td className="align-middle">
                        <label className="kt-checkbox">
                          <input
                            type="checkbox"
                            checked={o.selected}
                            onChange={() => this.handleCheckOrder(o.order._id)}
                          />
                          <span />
                        </label>
                      </td>
                    )}
                    <td className="align-middle">{this.renderOrderInformation(o.order)}</td>
                    <td className="align-middle">
                      <CompanyWidget company={company} />
                    </td>
                    <td className="align-middle">
                      <div className="text-success font-weight-bold">{required} pcs.</div>
                      {price && price.orderquantity && price.orderquantity !== required ? (
                        <CustomTooltip tooltipText="Order quantity set during commodity check">
                          <span className="text-muted font-weight-normal">{price.orderquantity} pcs.</span>
                        </CustomTooltip>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="align-middle">
                      <PriorityWidget priority={o.order.priority} />
                    </td>
                    <td className="align-middle">
                      {o.order.targetDate ? baseUtils.formatDate(o.order.targetDate) : "-"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  };

  /**
   * Renders the modal body for the select stage.
   * @returns { JSX.Element } Modal body for select
   */
  renderSelectModalBody = () => {
    const { packaging, context } = this.props;
    const { selectedManufacturer, showOffers } = this.state;
    const { offers, orders } = this.resolveOpenOrdersForPackaging();
    return (
      <>
        <div className="kt-widget kt-widget--user-profile-3 mb-4">
          <div className="kt-widget__top">
            <div className="kt-widget__media d-none d-sm-block">
              <img src={packagingUtils.getPackagingImage(packaging)} alt="color" />
            </div>
            <div className="kt-widget__content align-self-center">
              <div className="kt-widget__head">
                <div className="kt-widget__user">
                  <span className="kt-widget__username kt-font-bold kt-font-dark">
                    {packagingUtils.resolvePackagingProperties(packaging)}
                  </span>
                </div>
              </div>
              <div className="kt-widget__info mb-2">
                <div style={{ color: "#8a8a8a" }}>
                  {packaging.article_number && (
                    <span className="kt-font-dark kt-font-bold mr-3">
                      <br />
                      Art. Nr: {packaging.article_number}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {orders.length > 0 ? (
          <>
            <div className="float-right w-50">
              <select
                className="form-control"
                value={selectedManufacturer}
                onChange={this.handleChangeSelectedManufacturer}
              >
                <option value="">All ({orders.length})</option>
                {context.manufacturers.map(m => (
                  <option key={m._id.toString()} value={m._id.toString()}>
                    {m.name} (
                    {
                      orders.filter(
                        o =>
                          (!o.order.settings.filler && o.order.settings.manufacturer.toString() === m._id.toString()) ||
                          (o.order.settings.filler && o.order.settings.filler === m._id.toString())
                      ).length
                    }
                    )
                  </option>
                ))}
              </select>
            </div>
            {this.renderOrderTable(orders, true)}
          </>
        ) : (
          <div className="alert alert-warning">
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              There are currently no orders that use this packaging. <br />
              However, the packaging can be ordered without assigning orders.
            </div>
          </div>
        )}
        {offers.length > 0 && (
          <>
            <button className="btn btn-sm btn-secondary" onClick={() => this.setState({ showOffers: !showOffers })}>
              {showOffers ? "Hide offers" : "Show Offers (" + offers.length + ")"}
            </button>
            {showOffers && this.renderOrderTable(offers, false, true)}
          </>
        )}
      </>
    );
  };

  /**
   * Renders the modal body for the settings stage.
   * @returns { JSX.Element } Modal body for settings
   */
  renderSettingsModalBody = () => {
    const { packaging, context } = this.props;
    const {
      availableIncoterms,
      packagingOrder,
      orderQuantity,
      ordersWithPackaging,
      purchasePrice,
      totalPrice,
      removedOrders
    } = this.state;
    const { currencies, manufacturers, suppliers } = context;
    const comSuppliersString = packaging.suppliers.map(s => s._id.toString());
    const comSuppliers = suppliers.filter(sup => comSuppliersString.includes(sup._id.toString()));
    const matchingPrice = this.resolveMatchingPrice(packagingOrder);
    const edit = !!this.props.packagingOrder;
    const selectedOrders = ordersWithPackaging.filter(o => o.selected);
    const canCancel = accessUtils.canPerformAction(ACTIONS.PACKAGINGCANCEL);
    const requiredAmount = this.calculateRequiredAmount();
    const requiredOrderQuantity = this.calculateRequiredAmount(true);
    const warnings = this.checkForWarnings();

    return (
      <>
        <div className="h5 text-dark mb-5">
          Packaging Order Settings
          {edit && (
            <span className="float-right">
              <button
                className={"btn btn-sm btn-secondary" + (canCancel ? "" : " disabled")}
                onClick={() => (canCancel ? this.setState({ showCancel: true }) : undefined)}
                disabled={!canCancel}
              >
                Cancel Order
              </button>
            </span>
          )}
        </div>
        {selectedOrders.length > 0 && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Required</label>
            <div className="col-9 align-self-center">
              <span className="text-dark font-weight-bold">{requiredAmount} pcs.</span>
            </div>
          </div>
        )}
        {selectedOrders.length > 0 && requiredOrderQuantity > 0 && requiredOrderQuantity !== requiredAmount && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Order Quantity</label>
            <div className="col-9 align-self-center">
              <CustomTooltip tooltipText="Order quantity set during commodity check">
                <span className="text-dark font-weight-bold">{requiredOrderQuantity} pcs.</span>
              </CustomTooltip>
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Quantity</label>
          <div className="col-9">
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={orderQuantity}
                name="orderQuantity"
                min={0}
                onChange={this.handleChange}
                onBlur={this.handleBlurQuantity}
              />
              <div className="input-group-append">
                <span className="input-group-text">pcs.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Supplier</label>
          <div className="col-9">
            <select
              className="form-control"
              value={packagingOrder.supplier ? packagingOrder.supplier.toString() : ""}
              name="supplier"
              onChange={this.handleChange}
            >
              <option value="" disabled hidden>
                Please Select
              </option>
              {comSuppliers.map(s => (
                <option key={s._id.toString()} value={s._id.toString()}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Incoterm</label>
          <div className="col-9">
            <select
              className="form-control"
              value={packagingOrder.incoterm || ""}
              name="incoterm"
              onChange={this.handleChange}
            >
              <option value="">-</option>
              {packagingOrder.supplier &&
              availableIncoterms[packagingOrder.supplier.toString()] &&
              availableIncoterms[packagingOrder.supplier.toString()].length > 0
                ? availableIncoterms[packagingOrder.supplier.toString()].map(i => (
                    <option value={i} key={i}>
                      {i ? i : "None specified"}
                    </option>
                  ))
                : INCOTERMS.map(i => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  ))}
            </select>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Destination</label>
          <div className="col-9">
            <select
              className={"form-control" + (packagingOrder.relatedOrders.length > 0 ? " disabled" : "")}
              value={packagingOrder.destination ? packagingOrder.destination.toString() : ""}
              name="destination"
              onChange={this.handleChange}
              disabled={
                packagingOrder.relatedOrders.length > 0 &&
                this.props.packagingOrder &&
                !!this.props.packagingOrder.destination
              }
            >
              <option value="" disabled hidden>
                Please Select
              </option>
              {manufacturers.map(m => (
                <option key={m._id.toString()} value={m._id.toString()}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
            {packagingOrder.incoterm &&
            [I_FCA, I_CIFA, I_CIFS, I_CIPA, I_CIPS, I_DDP, I_DAP, I_DPU, I_CPT, I_COUR].includes(
              packagingOrder.incoterm
            )
              ? "ETA"
              : "Pickup Date"}
          </label>
          <div className="col-9">
            <OverlayTrigger
              show={!warnings["deliveryDate"] ? false : undefined}
              overlay={
                <Tooltip id="deliveryDateWarning">
                  <span>{warnings["deliveryDate"]}</span>
                </Tooltip>
              }
            >
              <div>
                <DateInput
                  value={packagingOrder.expectedDelivery}
                  onBlur={this.handleChange}
                  name={"expectedDelivery"}
                  min={new Date()}
                  classes={"form-control " + (warnings["deliveryDate"] ? "is-invalid" : "")}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">LOT</label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={packagingOrder.lot ? packagingOrder.lot : ""}
              name="lot"
              onChange={this.handleChange}
              placeholder="LOT"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Est. Batch Expiration</label>
          <div className="col-9">
            <DateInput
              value={packagingOrder.expiry}
              onBlur={this.handleChange}
              name={"expiry"}
              min={new Date()}
              allowClear={true}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">
            {packagingOrder.incoterm === I_EXW
              ? "Pickup Company"
              : packagingOrder.incoterm && [I_FCA, I_CIFA, I_CIFS].includes(packagingOrder.incoterm)
              ? "Destination Port"
              : packagingOrder.incoterm && [I_FAS, I_FOBA, I_FOBS].includes(packagingOrder.incoterm)
              ? "Departure Port"
              : "Carrier"}
          </label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={packagingOrder.carrier ? packagingOrder.carrier : ""}
              onChange={this.handleChange}
              name="carrier"
              placeholder="Carrier"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Packages</label>
          <div className="col-9">
            <input
              className="form-control"
              type="text"
              value={packagingOrder.packages ? packagingOrder.packages : ""}
              name="packages"
              onChange={this.handleChange}
              placeholder="e.g. 4 x 25kg"
            />
          </div>
        </div>
        {packagingOrder.incoterm &&
          [I_FCA, I_FAS, I_FOBA, I_FOBS, I_CIFA, I_CIFS].includes(packagingOrder.incoterm) && (
            <div className="form-group row mb-2">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Notify</label>
              <div className="col-9">
                <select className="form-control" value={packagingOrder.notify} name="notify" disabled>
                  <option value="asap logistics GmbH">asap logistics GmbH</option>
                </select>
              </div>
            </div>
          )}
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
          <div className="col-9">
            <textarea
              className="form-control"
              value={packagingOrder.note ? packagingOrder.note : ""}
              name="note"
              onChange={this.handleChange}
              placeholder="Additional Note"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Total Price</label>
          <div className="col-9">
            <div className="input-group">
              <OverlayTrigger
                show={!warnings["price"] ? false : undefined}
                overlay={
                  <Tooltip id="priceWarning">
                    <span>{warnings["price"]}</span>
                  </Tooltip>
                }
              >
                <input
                  className={"form-control " + (warnings["price"] ? "is-invalid" : "")}
                  type="number"
                  value={totalPrice}
                  name="totalPrice"
                  min={0}
                  onChange={this.handleChange}
                />
              </OverlayTrigger>
              <div className="input-group-append">
                <span className="input-group-text">€</span>
              </div>
            </div>
            {matchingPrice && (
              <small className="kt-font-dark">
                Reference: {baseUtils.formatEuro(matchingPrice.price)} (
                {baseUtils.formatEuro(matchingPrice.price * packagingOrder.orderQuantity)} total) - last updated:{" "}
                {matchingPrice.date ? baseUtils.formatDate(matchingPrice.date) : "unknown"}
              </small>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Total Purchase Price</label>
          <div className="col-9">
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                value={purchasePrice}
                name="purchasePrice"
                min={0}
                onChange={this.handleChange}
              />
              <div className="input-group-append">
                <select
                  className="form-control"
                  value={packagingOrder.purchaseCurrency || ""}
                  name="purchaseCurrency"
                  onChange={this.handleChange}
                >
                  <option value="" disabled hidden>
                    ?
                  </option>
                  {currencies &&
                    currencies.map(currency => (
                      <option key={currency.code} value={currency.code}>
                        {currency.code}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {matchingPrice && !!matchingPrice.purchasePrice && !!matchingPrice.purchaseCurrency && (
              <small className="kt-font-dark">
                Reference: {baseUtils.formatCurrency(matchingPrice.purchasePrice, matchingPrice.purchaseCurrency)} /
                {" pcs."}(
                {baseUtils.formatCurrency(
                  matchingPrice.purchasePrice * packagingOrder.orderQuantity,
                  matchingPrice.purchaseCurrency
                )}{" "}
                total) - last updated: {matchingPrice.date ? baseUtils.formatDate(matchingPrice.date) : "unknown"}
              </small>
            )}
          </div>
        </div>
        {packagingOrder.relatedOrders && packagingOrder.relatedOrders.length > 0 && (
          <div className="form-group row mb-2">
            <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Related Orders</label>
            <div className="col-9">
              <RelatedOrders
                orders={packagingOrder.relatedOrders}
                context={context}
                removedOrders={removedOrders}
                onRelatedOrder={this.handleRelatedOrder}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  /**
   * Renders the next button. Also checks if the selected orders are for different manufacturers. In that case the
   * user can not proceed.
   * @returns { JSX.Element } Next button
   */
  renderNextButton = () => {
    const { ordersWithPackaging } = this.state;
    let differentManufacturers = false;
    const selectedOrders = ordersWithPackaging.filter(o => o.selected);
    const firstManufacturer =
      selectedOrders.length > 0
        ? selectedOrders[0].order.settings.filler || selectedOrders[0].order.settings.manufacturer.toString()
        : "";
    for (let i = 0; i < selectedOrders.length; i++) {
      const o = selectedOrders[i].order;
      if ((o.settings.filler || o.settings.manufacturer.toString()) !== firstManufacturer) {
        differentManufacturers = true;
        break;
      }
    }
    if (!differentManufacturers) {
      return (
        <button className="btn btn-success" onClick={this.handleClickNext}>
          Next
        </button>
      );
    }
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="multiple-manufacturers">
            <span className="text-danger">
              <b>Can't order packaging for different manufacturers in single packaging order!</b>
            </span>
          </Tooltip>
        }
        placement="left"
      >
        <button className="btn btn-success disabled">Next</button>
      </OverlayTrigger>
    );
  };

  render() {
    const { packaging, context } = this.props;
    const { saving, show, showCancel, stage } = this.state;
    const errors = this.checkForErrors();
    const edit = !packaging.disabled && !!this.props.packagingOrder;
    const canCreate =
      !packaging.disabled &&
      (edit
        ? accessUtils.canEditData(EDITLOCATIONS.PACKAGINGORDER)
        : accessUtils.canCreateData(CREATELOCATIONS.PACKAGINGORDER));

    return (
      <>
        <button
          className={"btn btn-secondary" + (edit ? " mr-1" : "") + (canCreate ? "" : " disabled")}
          onClick={canCreate ? this.handleShow : undefined}
          disabled={!canCreate}
        >
          {edit ? "Edit" : "New Order"}
        </button>
        <Modal show={show && !showCancel} onHide={this.handleHide} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {edit ? "Edit Order of" : "Order"} {packagingUtils.getShortPackagingInfo(packaging)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {stage === "select" && this.renderSelectModalBody()}
            {stage === "settings" && this.renderSettingsModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            {stage === "settings" && !edit && (
              <button className="btn btn-secondary" onClick={() => this.setState({ stage: "select" })}>
                Back
              </button>
            )}
            {stage === "select" ? (
              this.renderNextButton()
            ) : errors.length > 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"packaging-invalid"}>
                    {errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-success disabled">
                  {edit ? (
                    "Save changes"
                  ) : (
                    <>
                      <i className="fa fa-check" />
                      Order
                    </>
                  )}
                </button>
              </OverlayTrigger>
            ) : (
              <button
                className={"btn btn-success" + (saving ? " disabled" : "")}
                onClick={this.handleOrder}
                disabled={saving}
              >
                {edit ? (
                  "Save changes"
                ) : (
                  <>
                    <i className="fa fa-check" />
                    Order
                  </>
                )}
              </button>
            )}
          </Modal.Footer>
        </Modal>
        {edit && (
          <CancelPackagingOrderModal
            packaging={packaging}
            packagingOrder={this.props.packagingOrder!}
            context={context}
            show={showCancel}
            onHide={(show: boolean) => this.setState({ showCancel: false, show })}
          />
        )}
      </>
    );
  }
}

export default CreatePackagingOrderModal;
