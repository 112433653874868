import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomOrder } from "../../CustomTypes";
import { useDataContext } from "../../../../context/dataContext";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import {
  convertRawbidsPriceResult,
  fetchRawbidsPrices,
  getMatchingRequestAmounts,
  getPackagingSizes,
  getRawbidsSupplierId,
  RawbidsRequestResult
} from "../../../../utils/rawbidsUtils";
import orderUtils from "../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import DisconnectedCommodities from "./DisconnectedCommodities";
import RawbidsCommodityPrices from "./RawbidsCommodityPrices";
import dbService from "../../../../services/dbService";
import toastUtils from "../../../../utils/toastUtils";
import RawbidsCommoditiesCheck from "./RawbidsCommoditiesCheck";
import { calculation, OrderState } from "../../../../model/orders.types";
import OrderHelper from "../../OrderHelper";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";
import notificationService, { R_OFFERAPPROVED, R_ORDERFILEUPLOAD } from "../../../../services/notificationService";
import slackService from "../../../../services/slackService";
import pdfUtils from "../../../../utils/pdf/pdfUtils";
import calculationReportGeneration from "../../../../utils/pdf/calculationReportGeneration";
import dateUtils from "../../../../utils/dateUtils";
import { T_OFFERPDF, T_REPORTPDF, T_REQUESTAPPROVEDWITHRAWBIDS } from "../../../../utils/timelineUtils";
import userService from "../../../../services/userService";
import dbOrderService from "../../../../services/dbServices/dbOrderService";
import SplashScreen from "../../../common/SplashScreen";

enum ApprovalStep {
  CONNECT_COMMODITIES,
  UPDATE_PRICES,
  CONFIRM_PRICES
}

interface RawbidsApprovalModalProps {
  disabled?: boolean;
  order: CustomOrder;
}

interface RawbidsApprovalModalState {
  show: boolean;
  step: ApprovalStep;
  rawbidsPrices: Array<RawbidsRequestResult>;
  connectedCommodities: Array<CommoditiesDocument>;
  disconnectedCommodities: Array<CommoditiesDocument>;
}

const getDefaultState = (show: boolean): RawbidsApprovalModalState => {
  return {
    show,
    step: ApprovalStep.CONNECT_COMMODITIES,
    rawbidsPrices: [],
    connectedCommodities: [],
    disconnectedCommodities: []
  };
};

const RawbidsApprovalModal: React.FunctionComponent<RawbidsApprovalModalProps> = ({ disabled, order }) => {
  const dataContext = useDataContext();
  const { commodities, general } = dataContext;

  const [state, setState] = useState<RawbidsApprovalModalState>(getDefaultState(false));
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [calculations, setCalculations] = useState<Array<calculation>>([]);

  const rawbidsSupplierId = useMemo(() => getRawbidsSupplierId(general), [general]);

  // Load connected and disconnected commodities as well as existing prices on modal open
  useEffect(() => {
    if (!state.show) return;
    setLoading(true);
    let connectedCommodities: Array<CommoditiesDocument> = [];
    let disconnectedCommodities: Array<CommoditiesDocument> = [];
    let rawbidsPrices: Array<RawbidsRequestResult> = [];

    // Check if any commodities still need to be connected to RB
    const neededCommodities = order.recipe.map(r => r.id.toString());
    for (let i = 0; i < neededCommodities.length; i++) {
      const com = commodities.find(c => c._id.toString() === neededCommodities[i]);
      if (!com) {
        console.error("Could not resolve", neededCommodities[i]);
        continue;
      }
      if (com.rawbidsData) {
        connectedCommodities.push(com);
      } else {
        disconnectedCommodities.push(com);
      }
    }

    // If all commodities are connected, retrieve required amounts
    if (connectedCommodities.length === neededCommodities.length) {
      // Get all required amounts for each commodity in the recipe
      const commodityRequiredAmounts: Array<{ calculationId: string; commodityId: string; totalAmount: number }> = [];
      for (let i = 0; i < order.calculations[0].prices.length; i++) {
        for (let j = 0; j < order.calculations.length; j++) {
          const totalAmount = orderUtils.getTotalAmountWithBuffer(
            +order.settings.perUnit,
            +order.calculations[j].units,
            order.calculations[j].prices[i].amount,
            order.calculations[j].prices[i].buffer,
            order.settings.type
          );
          commodityRequiredAmounts.push({
            calculationId: order.calculations[j].id,
            commodityId: order.calculations[j].prices[i]._id.toString(),
            totalAmount: totalAmount / (1000 * 1000) // amount in kg
          });
        }
      }

      // Retrieve recently fetched prices (if available, for needed MOQs and not older than 7 days)
      for (let i = 0; i < connectedCommodities.length; i++) {
        const currentCommodity = connectedCommodities[i];
        const rbId = currentCommodity.rawbidsData?.rawbidsId;
        if (!rbId) continue;

        // Match required amounts with next best possible request quantity
        const packagingSizes = getPackagingSizes(currentCommodity.rawbidsData);
        const neededAmounts = commodityRequiredAmounts
          .filter(amounts => amounts.commodityId === currentCommodity._id.toString())
          .map(a => a.totalAmount);
        const requestAmounts = getMatchingRequestAmounts(packagingSizes, neededAmounts, [2, 4]);
        const rbRequest: RawbidsRequestResult = {
          neededAmounts: neededAmounts,
          requestedAmounts: requestAmounts,
          requestedCommodityId: currentCommodity._id.toString(),
          rawbidsId: rbId,
          priceResult: []
        };
        const rBSupplier = currentCommodity.suppliers.find(s => s._id.toString() === rawbidsSupplierId);
        if (rBSupplier) {
          // Get all prices which are not older than 7 days
          const recentPrices = rBSupplier.prices.filter(
            p => p.date && p.date.getTime() >= new Date().getTime() - 7 * 24 * 60 * 60 * 1000
          );
          recentPrices.forEach(price => {
            if (requestAmounts.includes(price.moq)) {
              rbRequest.priceResult.push({
                _id: price._id,
                totalPrice: price.price.toString(),
                purchasePrice: price.purchasePrice ? price.purchasePrice.toString() : "",
                purchasePriceCurrency: price.purchaseCurrency,
                incoterm: price.incoterm,
                moq: price.moq.toString(),
                deliveryTime: price.deliverytime.toString(),
                age: price.date,
                note: price.note
              });
            }
          });
        }
        rawbidsPrices.push(rbRequest);
      }
    }
    setState(prevState => {
      const newState = { ...prevState };
      newState.connectedCommodities = connectedCommodities;
      newState.disconnectedCommodities = disconnectedCommodities;
      newState.rawbidsPrices = rawbidsPrices;
      if (disconnectedCommodities.length === 0) {
        newState.step = ApprovalStep.UPDATE_PRICES;
      }
      return newState;
    });
    setLoading(false);
  }, [state.show, order, rawbidsSupplierId, commodities]);

  // Set up initial calculation in last step of the modal
  useEffect(() => {
    if (state.step !== ApprovalStep.CONFIRM_PRICES) return;
    setLoading(true);
    const type = order.settings.type;
    const updatedCalculations = _.cloneDeep(order.calculations);
    updatedCalculations.forEach(c => {
      c.prices.forEach(price => {
        const commodity = state.connectedCommodities.find(c => c._id.toString() === price._id.toString());
        if (!price.orderquantity) {
          const totalAmount = orderUtils.getTotalAmountWithBuffer(
            +order.settings.perUnit,
            +c.units,
            price.amount,
            price.buffer,
            type
          );
          price.orderquantity = OrderHelper.getOrderQuantityForType(totalAmount, type);
        }

        if (rawbidsSupplierId && commodity) {
          // Since prices were requested from the RB API and saved in commodity (or are not older than 7 days), consider them as requested and updated
          price.requested = true;
          price.updated = true;
          // Set all suppliers to RB supplier
          price.supplier = new BSON.ObjectId(rawbidsSupplierId);

          const matchingMOQ = orderCalculationUtils.getMatchingMOQ(
            commodity,
            new BSON.ObjectId(rawbidsSupplierId),
            +price.orderquantity
          );
          const requestAmount = price.orderquantity < matchingMOQ ? matchingMOQ : price.orderquantity;
          const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
            commodity,
            order.settings.manufacturer,
            requestAmount,
            price.supplier
          );
          const newPrice = commodityPrice.price;
          const newTotalPrice = requestAmount * newPrice.price;
          price.supplier = commodityPrice._id;
          price.price = newPrice.price;
          price.totalprice = newTotalPrice;
          price.orderquantity = requestAmount;
          price.incoterm = newPrice.incoterm;
          price.delivery = newPrice.delivery;
          price.deliverytime = newPrice.deliverytime;
          price.purchasePrice = newPrice.purchasePrice;
          price.purchaseCurrency = newPrice.purchaseCurrency;
        }
      });
      c.packagings.forEach(price => {
        if (!price.orderquantity) price.orderquantity = price.amount * +c.units;
      });
    });
    setCalculations(updatedCalculations);
    setLoading(false);
  }, [order, state.step, state.connectedCommodities, rawbidsSupplierId]);

  const handleHide = useCallback(() => {
    setState(prevState => {
      return { ...prevState, show: false };
    });
  }, []);

  const handleShow = useCallback(() => {
    setState(getDefaultState(true));
  }, []);

  const handleNextStep = useCallback(() => {
    setState(prevState => {
      return { ...prevState, step: prevState.step + 1 };
    });
  }, []);

  const handlePreviousStep = useCallback(() => {
    setState(prevState => {
      return { ...prevState, step: prevState.step - 1 };
    });
  }, []);

  const handleUpdatePrices = useCallback(async () => {
    setLoading(true);
    const { rawbidsPrices } = state;
    const updatedPrices = _.cloneDeep(rawbidsPrices);
    const backendUpdateNeeded: Array<RawbidsRequestResult> = [];

    try {
      // Filter all moqs for which prices are missing and fetch those from RB
      for (const rbPrice of updatedPrices) {
        const missingMOQs = rbPrice.requestedAmounts.filter(
          amount => !rbPrice.priceResult.some(pR => pR.moq === amount.toString())
        );
        if (missingMOQs.length === 0) continue;
        const result = await fetchRawbidsPrices(rbPrice.rawbidsId, missingMOQs);
        if (result) {
          rbPrice.priceResult = rbPrice.priceResult.concat(convertRawbidsPriceResult(result));
          backendUpdateNeeded.push(rbPrice);
        } else {
          toast.error("Error on fetching commodity prices from Rawbids");
        }
      }
      // If new prices could be fetched, update RB supplier in backend
      if (backendUpdateNeeded.length > 0) {
        const res = await dbService.callFunction("updateRBSupplierPrices", [backendUpdateNeeded], true);
        await toastUtils.databaseOperationToast(
          res,
          "Updating RB supplier prices successful",
          "Failed to update RB supplier prices"
        );
      } else {
        toast.success("All prices are up to date");
      }
      setState(prevState => {
        return { ...prevState, rawbidsPrices: updatedPrices };
      });
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      setLoading(false);
    }
  }, [state.rawbidsPrices]);

  const handleCalculationChange = useCallback(
    (calculation: calculation, updatedPrice: string | BSON.ObjectId) => {
      const updatedCalculations = _.cloneDeep(calculations);
      const newCalculation = updatedCalculations.find(c => c.id === calculation.id);
      if (!newCalculation) return;

      const newPrice = calculation.prices.find(p => p._id.toString() === updatedPrice.toString());
      const priceIndex = newCalculation.prices.findIndex(p => p._id.toString() === updatedPrice.toString());
      if (newPrice && priceIndex >= 0) newCalculation.prices.splice(priceIndex, 1, newPrice);
      const newCalculationInfo = orderCalculationUtils.recalculateInfoOnCommodityChanges(order, newCalculation);
      if (!newCalculationInfo) {
        toast.error("Calculation info could not be recalculated, therefore calculations could not be updated");
        return;
      }
      newCalculation.info = newCalculationInfo;
      const index = calculations.findIndex(c => c.id.toString() === newCalculation.id.toString());
      updatedCalculations.splice(index, 1, newCalculation);
      setCalculations(updatedCalculations);
    },
    [calculations]
  );

  const handleApprove = useCallback(async () => {
    setSaving(true);
    try {
      // Create Offer and calculation protocol
      const pdfOfferResult = await pdfUtils.createOfferPDF(order, dataContext, false, order.createdFrom);
      if (!pdfOfferResult.result || !pdfOfferResult.path) {
        toast.error("Offer PDF creation failed: " + pdfOfferResult.message);
        return;
      }
      const convertedSettings = {
        ...order.settings,
        manufacturer: order.settings.manufacturer._id,
        filler: order.settings.filler?._id.toString()
      };
      const data = JSON.stringify({
        html: calculationReportGeneration.createCalculationProtocol(calculations, convertedSettings, dataContext),
        fileName: "Report_AN-" + order.identifier + "_V1_" + dateUtils.timeStampDate() + ".pdf"
      });
      const pdfCalcReportResult = await pdfUtils.uploadAndReturnPath(data);
      if (!pdfCalcReportResult) {
        toast.error("Calculation report creation failed");
        return;
      }

      // Create timeline entries and update db
      const timelineEntry: Array<any> = [
        {
          _id: new BSON.ObjectId(),
          type: T_REQUESTAPPROVEDWITHRAWBIDS,
          date: new Date(),
          person: userService.getUserId()
        }
      ];
      timelineEntry.push({
        _id: new BSON.ObjectId(),
        type: T_OFFERPDF,
        date: new Date(),
        path: pdfOfferResult.path,
        person: userService.getUserId(),
        version: orderUtils.getPDFVersion(order, T_OFFERPDF),
        offernumber: order.identifier
      });
      timelineEntry.push({
        _id: new BSON.ObjectId(),
        type: T_REPORTPDF,
        date: new Date(),
        path: pdfCalcReportResult,
        person: userService.getUserId(),
        version: orderUtils.getPDFVersion(order, T_REPORTPDF),
        offernumber: order.identifier
      });
      const result = await dbOrderService.switchState(order._id, OrderState.OFFER_APPROVED, timelineEntry, {
        $set: { calculations },
        $inc: { version: 1 }
      });
      if (result && result.modifiedCount) {
        toast.success("Offer and associated PDF successfully created");
        notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
          de: ": Angebotsdokument",
          en: ": Offer document"
        });
        notificationService.notify(R_OFFERAPPROVED, order._id);
        const url = process.env.REACT_APP_BASE_URL;
        const notificationMessage = `:information_source: Offer <${url}order/${order._id.toString()}|*${
          "AN-" + order.identifier
        }*> has been approved.`;
        slackService.sendMessage(order.createdFrom._id, notificationMessage);
        handleHide();
      } else {
        toast.error("Offer could not be updated");
      }
    } catch (e) {
      console.error(e);
      toast.error(`Error on approving with Rawbids: ${e.message}`);
    } finally {
      setSaving(false);
    }
  }, [calculations, order, dataContext]);

  const allPricesFetched = useMemo(
    () => state.rawbidsPrices.every(p => p.priceResult.length >= p.requestedAmounts.length),
    [state.rawbidsPrices]
  );

  const [errors, warnings] = useMemo(() => {
    const { rawbidsPrices, step } = state;
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    if (step === ApprovalStep.UPDATE_PRICES) {
      if (rawbidsPrices.some(p => p.priceResult.length === 0)) {
        errors.push("One or more prices are missing");
      }
      if (!allPricesFetched) {
        warnings.push("More prices could be fetched");
      }
    }
    if (step === ApprovalStep.CONFIRM_PRICES) {
      if (calculations.some(c => c.prices.some(p => p.supplier.toString() !== rawbidsSupplierId))) {
        // If amount is changed and no MOQ for rb supplier is found, customer is listed as supplier instead of rawbids
        errors.push("Amount cannot be lower than the MOQ of the supplier");
      }
    }
    return [errors, warnings];
  }, [state.rawbidsPrices, state.step, allPricesFetched, calculations, rawbidsSupplierId]);

  return (
    <>
      <button
        type="button"
        className={"btn btn-sm btn-upper btn btn-sm btn-upper btn-dark bg-rawbids" + (disabled ? " disabled" : "")}
        onClick={disabled ? undefined : handleShow}
        disabled={disabled}
      >
        <img
          style={{ width: 12, height: 12, borderRadius: 0, objectFit: "cover" }}
          alt="R"
          src="https://app.rawbids.com/rawbidsFavicon.ico"
          className="country-icon mr-1"
        />
        APPROVE
      </button>
      <Modal
        show={state.show}
        onHide={handleHide}
        centered
        name="RawbidsApprovalModal"
        size="xl"
        backdropClassName={"customBackdrop"}
        style={{ zIndex: 950 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Approve with Rawbids Commodities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.step === ApprovalStep.CONNECT_COMMODITIES && (
            <>
              <div className="h5 text-dark">Connect Commodities</div>
              <div className="mb-3">
                In order to approve with Rawbids prices, all commodities need to be connected with a Rawbids commodity.
                The following commodities are still missing a connection:
              </div>
              <DisconnectedCommodities commodities={state.disconnectedCommodities} />
            </>
          )}
          {state.step === ApprovalStep.UPDATE_PRICES && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div className="h5 text-dark my-0">Rawbids Commodity Prices</div>
                <ErrorOverlayButton
                  className="btn btn-dark bg-rawbids"
                  buttonText="Update Prices"
                  errors={allPricesFetched ? ["All prices are up to date"] : []}
                  onClick={handleUpdatePrices}
                />
              </div>

              {loading ? (
                <div>
                  <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                  <h5 className="text-center text-dark">Loading commodity prices...</h5>
                  <div className="text-center text-dark">Please wait. This may take a while.</div>
                </div>
              ) : state.rawbidsPrices.length > 0 ? (
                <RawbidsCommodityPrices commodities={state.connectedCommodities} requestResults={state.rawbidsPrices} />
              ) : (
                <div className="mt-3">
                  No Prices requested yet. Updating the prices will request those prices from Rawbids.
                </div>
              )}
            </>
          )}
          {state.step === ApprovalStep.CONFIRM_PRICES && (
            <>
              <div className="h5 text-dark mt-3">Update Calculation</div>
              {loading ? (
                <div>
                  <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
                  <h5 className="text-center text-dark">Loading initial calculation...</h5>
                  <div className="text-center text-dark">Please wait. This may take a while.</div>
                </div>
              ) : (
                <RawbidsCommoditiesCheck
                  order={order}
                  commodities={state.connectedCommodities}
                  rawbidsSupplierId={rawbidsSupplierId}
                  calculations={calculations}
                  onCalculationChange={handleCalculationChange}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ErrorOverlayButton
            className="btn btn-secondary"
            buttonText={state.step > ApprovalStep.UPDATE_PRICES ? "Back" : "Close"}
            saving={loading}
            onClick={state.step > ApprovalStep.UPDATE_PRICES ? handlePreviousStep : handleHide}
          />
          {state.step === ApprovalStep.UPDATE_PRICES && (
            <ErrorOverlayButton
              className="btn btn-dark bg-rawbids"
              buttonText="Next"
              saving={loading}
              warnings={warnings}
              errors={errors}
              onClick={handleNextStep}
            />
          )}
          {state.step === ApprovalStep.CONFIRM_PRICES && (
            <ErrorOverlayButton
              className="btn btn-dark bg-rawbids"
              buttonText={
                <>
                  {saving && (
                    <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                      <svg className="button-splash-spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                      </svg>
                    </div>
                  )}
                  <img
                    style={{ width: 12, height: 12, borderRadius: 0, objectFit: "cover" }}
                    alt="R"
                    src="https://app.rawbids.com/rawbidsFavicon.ico"
                    className="country-icon mr-1"
                  />
                  <span className="align-middle">Approve</span>
                </>
              }
              saving={saving}
              warnings={warnings}
              errors={errors}
              onClick={handleApprove}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RawbidsApprovalModal;
