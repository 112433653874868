import React from "react";
import * as Realm from "realm-web";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import {
  AdditionalGeneralPrice,
  AdditionalProductionStep,
  CalculationSupplier,
  CalculationType,
  CapsuleCalculation,
  CapsuleCalculationSupplier,
  CommodityCalculation,
  CustomCommoditiesDocument,
  CustomPackagingsDocument,
  ExtendedCapsule,
  PackagingCalculation,
  PackagingCalculationSupplier,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "../components/configurator/CustomTypes";
import { SuppliersDocument } from "../model/suppliers.types";
import { CommodityBatch, CommodityPrice } from "../model/commodities.types";
import { PackagingPrice } from "../model/packagings.types";
import { CapsulePrice, CapsulesDocument, CapsuleSupplier, CapsuleSupplierType } from "../model/capsules.types";
import { ManufacturersDocument } from "../model/manufacturers.types";
import { PackagingTypes, ProductTypes } from "../components/configurator/configuratorConstants";
import { PackagingStockDocument } from "../model/packagingStock.types";
import { CUSTOMER } from "./commodityUtils";
import { DataContext } from "../context/dataContext";
import packagingUtils from "./packagingUtils";
import baseUtils from "./baseUtils";
import calculationHelper, {
  BLENDING,
  BLISTERING,
  BOTTLING,
  ENCAPSULATION,
  LIQUID,
  PRODUCTION_TYPES,
  TABLETING
} from "../components/configurator/calculationDetails/calculationHelper";
import { CustomOrder } from "../components/order/CustomTypes";
import orderCalculationUtils from "./orderCalculationUtils";
import { CalculationManufacturerPrice, StandardCalculationInfo } from "../model/orders.types";
import { T_CAPSULE, T_LIQUID, T_POWDER } from "../components/order/OrderHelper";
import { NumValue, WeightUnit } from "../model/common.types";
import { DEFAULTWEIGHTUNIT } from "./warehouseUtils";

// averages of current values
const DENSITY_DEFAULTS: any = {
  powder: 0.5,
  oil: 0.93,
  beadlet: 0.85,
  concentrates: 0.8,
  granules: 0.8
};

export const MARGIN_BUFFER = 0.5;

/**
 * Get a commodity calculation object
 * @param commodity a commodity document
 * @param suppliers list of suppliers
 * @param perUnit amount per unit
 * @param amountInMg amount of the commodity in mg
 * @param type current selected product type
 * @param calculation a calculation object
 * @returns {CommodityCalculation} the commodity calculation
 */
function getCommodityPrice(
  commodity: CustomCommoditiesDocument,
  suppliers: Array<SuppliersDocument>,
  perUnit: number,
  amountInMg: number,
  type: string,
  calculation: CalculationType
): CommodityCalculation {
  const buffer = [ProductTypes.SERVICE, ProductTypes.CUSTOM].includes(type) ? 0 : 5;
  const units = +calculation.units;
  const quantity = getTotalAmountWithBuffer(perUnit, units, amountInMg, type, buffer); // mg, MOQ in kg, + 5% buffer
  const supplier: { _id: Realm.BSON.ObjectId; name: string; price: CommodityPrice } = getSupplierForMOQ(
    commodity,
    suppliers,
    quantity,
    type
  )! as { _id: Realm.BSON.ObjectId; name: string; price: CommodityPrice };
  const totalPrice = getTotalPrice(quantity, supplier.price.price, type);
  return {
    id: calculation.id,
    auto: true,
    buffer: buffer, //default 5%
    totalAmount: quantity,
    estimatedPrice: supplier.price.price, // estimated price, not edited later on
    price: supplier.price.price, // default same as estimated price, may be changed later on (purchasePrice + incoterm aufschlag)
    supplier: supplier,
    deliveryTime: supplier.price.deliverytime, // from selected supplier price/calculation
    orderQuantity: null, // default null
    requested: false, // default false
    updated: false, // default false
    ordered: null, // default null
    delivered: null, // default null
    userOrdered: null,
    userDelivered: null,
    eta: null, // default null
    totalPrice: totalPrice,
    purchasePrice: supplier.price.purchasePrice, // default null
    purchaseCurrency: supplier.price.purchaseCurrency, // default empty string
    delivery: supplier.price.delivery, // Deprecated, copy value from prices otherwise empty string
    incoterm: supplier.price.incoterm // Copy value from prices
  };
}

/**
 * Update a commodity calculation and their price
 * @param commodity a selected commodity
 * @param commodityCalculation the associated commodity calculation to update
 * @param suppliers array of suppliers
 * @param units units
 * @param perUnit amount per unit
 * @param type current product type
 */
function updateCommodityPrice(
  commodity: SelectedCommoditiesDocument,
  commodityCalculation: CommodityCalculation,
  suppliers: Array<SuppliersDocument>,
  units: number,
  perUnit: number,
  type: string
) {
  const buffer = commodityCalculation.buffer;
  const quantity = getTotalAmountWithBuffer(perUnit, units, +commodity.amount!, type, buffer); // mg, MOQ in kg, + 5% buffer
  const supplier: { _id: Realm.BSON.ObjectId; name: string; price: CommodityPrice } = getSupplierForMOQ(
    commodity,
    suppliers,
    quantity,
    type
  )! as { _id: Realm.BSON.ObjectId; name: string; price: CommodityPrice };
  const totalPrice = getTotalPrice(quantity, supplier.price.price, type);
  commodityCalculation.totalAmount = quantity;
  commodityCalculation.estimatedPrice = supplier.price.price;
  commodityCalculation.price = supplier.price.price;
  commodityCalculation.deliveryTime = supplier.price.deliverytime;
  commodityCalculation.supplier = supplier;
  commodityCalculation.totalPrice = totalPrice;
  commodityCalculation.delivery = supplier.price.delivery;
  commodityCalculation.incoterm = supplier.price.incoterm;
  commodityCalculation.purchasePrice = supplier.price.purchasePrice;
  commodityCalculation.purchaseCurrency = supplier.price.purchaseCurrency;
}

/**
 * Change the supplier of a commodity
 * @param commodity selected commodity document
 * @param calculation calculation object
 * @param preferences preferences object
 * @param suppliers Array of suppliers
 * @param newSupplier new supplier
 * @param type current product type
 */
function updateCommoditySupplier(
  commodity: SelectedCommoditiesDocument,
  calculation: CalculationType,
  preferences: Preferences,
  suppliers: Array<SuppliersDocument>,
  newSupplier: BSON.ObjectId | "ownstock" | "customer",
  type: string
) {
  const commodityCalculation = commodity.calculations.find(calc => calc.id.toString() === calculation.id.toString());
  if (!commodityCalculation) return;
  const buffer = commodityCalculation.buffer;
  const quantity = getTotalAmountWithBuffer(
    +preferences.amountPerUnit,
    +calculation.units,
    +commodity.amount!,
    type,
    buffer
  );
  const manufacturer = preferences.selectedManufacturer!;
  let supplier: CalculationSupplier;
  if (newSupplier === "ownstock") {
    supplier = {
      _id: "ownstock",
      name: "Stock",
      price: getCommodityStockPriceForManufacturer(commodity.stock, manufacturer)
    };
  } else if (newSupplier === "customer") {
    supplier = { _id: "customer", name: "Customer", price: createCustomerCommodityPrice() };
  } else {
    const newSupplierDocument = suppliers.find(supp => supp._id.toString() === newSupplier.toString());
    if (!newSupplierDocument) console.error("No supplier found for", newSupplier.toString());
    supplier = getSupplierForMOQ(commodity, [newSupplierDocument!], quantity, type, true)!;
  }
  const totalPrice = getTotalPrice(quantity, supplier.price.price, type);
  commodityCalculation.totalAmount = quantity;
  commodityCalculation.estimatedPrice = supplier.price.price;
  commodityCalculation.price = supplier.price.price;
  commodityCalculation.deliveryTime = supplier.price.deliverytime;
  commodityCalculation.supplier = supplier;
  commodityCalculation.totalPrice = totalPrice;
  commodityCalculation.delivery = supplier.price.delivery;
  commodityCalculation.incoterm = supplier.price.incoterm;
  commodityCalculation.purchaseCurrency = supplier.price.purchaseCurrency;
  commodityCalculation.purchasePrice = supplier.price.purchasePrice;
}

/**
 * Get price object for supplied by stock
 * @param stock stock array of a commodity document
 * @param manufacturer manufacturer document
 * @returns {CommodityPrice} price object with average price from stock
 */
function getCommodityStockPriceForManufacturer(
  stock: Array<CommodityBatch>,
  manufacturer: ManufacturersDocument
): CommodityPrice {
  const averagePrice = getAverageStockPrice(stock, manufacturer);
  return {
    _id: new BSON.ObjectId(),
    moq: 0,
    price: averagePrice,
    deliverytime: 7,
    delivery: "",
    note: "",
    date: null,
    purchasePrice: null,
    purchaseCurrency: "",
    incoterm: ""
  };
}

/**
 * Create price object for customer as supplier
 * @returns {CommodityPrice} commodity price object with 0 as price
 */
function createCustomerCommodityPrice(): CommodityPrice {
  return {
    _id: new BSON.ObjectId(),
    moq: 0,
    price: 0,
    deliverytime: 7,
    delivery: "",
    note: "",
    date: null,
    purchasePrice: null,
    purchaseCurrency: "",
    incoterm: ""
  };
}

/**
 * Get a packaging calculation object
 * @param packaging a packaging document
 * @param suppliers list of suppliers
 * @param amount amount of the packaging
 * @param calculation a calculation object
 * @returns {PackagingCalculation} the packaging calculation
 */
function getPackagingPrice(
  packaging: CustomPackagingsDocument,
  suppliers: Array<SuppliersDocument>,
  amount: number,
  calculation: CalculationType
): PackagingCalculation {
  const quantity = +calculation.units * amount;
  const supplier: { _id: BSON.ObjectId; name: string; price: PackagingPrice } = getPackagingSupplierForMOQ(
    packaging,
    suppliers,
    quantity
  )! as { _id: Realm.BSON.ObjectId; name: string; price: PackagingPrice };
  const totalPrice = quantity * supplier.price.price;
  return {
    id: calculation.id,
    auto: true,
    buffer: 0, //default 0
    totalAmount: quantity,
    estimatedPrice: supplier.price.price,
    price: supplier.price.price,
    supplier: supplier,
    deliveryTime: supplier.price.deliverytime,
    orderQuantity: null,
    requested: false,
    updated: false,
    ordered: null,
    delivered: null,
    userOrdered: null,
    userDelivered: null,
    eta: null,
    totalPrice: totalPrice,
    delivery: supplier.price.delivery
  };
}

/**
 * Get a packaging calculation object in correlation to a given price object, given units and amount
 * @param priceObject the related price object
 * @param typeObject the typeObject of the packaging calculation
 * @param relatedCalculation the id of the overall calculation object
 * @param units units to calculate with
 * @param amount amount to calculate with
 * @returns {PackagingCalculation} result calculation object
 */
function getPackagingByPriceObject(
  priceObject: PackagingPrice,
  typeObject:
    | { type: "ownstock" }
    | { type: "customer"; name: string }
    | { type: "supplier"; id: BSON.ObjectId; name: string },
  relatedCalculation: BSON.ObjectId | string,
  units: number,
  amount: number
): PackagingCalculation {
  const quantity = units * amount;
  const totalPrice = quantity * priceObject.price;
  return {
    id: new BSON.ObjectId(relatedCalculation),
    auto: true,
    buffer: 0, //default 0
    totalAmount: quantity,
    estimatedPrice: priceObject.price,
    price: priceObject.price,
    supplier: {
      _id: typeObject.type !== "supplier" ? typeObject.type : typeObject.id,
      price: priceObject,
      name: typeObject.type === "ownstock" ? "owstock" : typeObject.name
    },
    deliveryTime: priceObject.deliverytime,
    orderQuantity: null,
    requested: false,
    updated: false,
    ordered: null,
    delivered: null,
    userOrdered: null,
    userDelivered: null,
    eta: null,
    totalPrice: totalPrice,
    delivery: priceObject.delivery
  };
}

/**
 * Updating a calculation by a given packaging calculation object
 * @param calculationBefore calculation before update
 * @param packagingCalculation related packaging calculation
 * @param additionalProductionStep information object for a possible additional production step
 * @returns {CalculationType} the updated calculation object
 */
function updateCalculationWithPackagingPrice(
  calculationBefore: CalculationType,
  packagingCalculation: PackagingCalculation,
  additionalProductionStep: AdditionalProductionStep | undefined
): CalculationType {
  // receive calculation values from the existing calculation
  let unitPriceNaked = calculationBefore.unitPriceNaked;
  const units = Number(calculationBefore.units);

  // receive calculation values from the existing packaging calculation
  let packagingCalculationTotalPrice = packagingCalculation.totalPrice ? packagingCalculation.totalPrice : 0;
  let additionalProductionPrice: AdditionalGeneralPrice | undefined = undefined;

  // checking if an addition production type has to be to considered
  if (additionalProductionStep) {
    const order = additionalProductionStep.order;
    // price for powder and liquid depends on weight of powder/liquid
    const totalAmount = [T_POWDER, T_LIQUID].includes(order.settings.type)
      ? (order.settings.perUnit * order.calculations[0].units) / (1000 * 1000)
      : additionalProductionStep?.amountPerUnit * units;
    const productionPrice = calculationHelper.getPriceFromManufacturer(
      additionalProductionStep.manufacturer,
      additionalProductionStep.productType,
      additionalProductionStep.productionType,
      "amount",
      totalAmount.toString()
    );
    const unitProductionPrice = Number(productionPrice) * additionalProductionStep.amountPerUnit;
    additionalProductionPrice = {
      type: additionalProductionStep.productionType,
      price: Number(productionPrice),
      unitPrice: unitProductionPrice
    };
  }

  // recalculate unit prices based on the margin type
  // Attention: turnover will not be increased, margin adjustments are responsible by sales person
  unitPriceNaked +=
    packagingCalculationTotalPrice / units + (additionalProductionPrice ? additionalProductionPrice.unitPrice : 0);
  const unitMargin = calculationBefore.unitPrice - unitPriceNaked;
  const totalMargin = calculationBefore.totalPrice - unitPriceNaked * units;
  const percentMargin = Math.round((unitMargin / unitPriceNaked) * 100 * 100) / 100;

  // build result object
  return {
    ...calculationBefore,
    id: new BSON.ObjectId(),
    oldId: calculationBefore.id.toString(),
    unitPriceNaked,
    unitMargin,
    totalMargin,
    percentMargin,
    additionalGeneralPrices: additionalProductionPrice
  };
}

/**
 * Update a packaging calculation
 * @param packaging selected packaging document
 * @param packagingCalculation associated packaging calculation
 * @param suppliers array of suppliers
 * @param units units
 */
function updatePackagingPrice(
  packaging: SelectedPackagingsDocument,
  packagingCalculation: PackagingCalculation,
  suppliers: Array<SuppliersDocument>,
  units: number
) {
  const quantity = units * +packaging.amount!;
  const supplier: { _id: BSON.ObjectId; name: string; price: PackagingPrice } = getPackagingSupplierForMOQ(
    packaging,
    suppliers,
    quantity
  )! as { _id: Realm.BSON.ObjectId; name: string; price: PackagingPrice };
  const totalPrice = quantity * supplier.price.price;
  packagingCalculation.totalAmount = quantity;
  packagingCalculation.estimatedPrice = supplier.price.price;
  packagingCalculation.price = supplier.price.price;
  packagingCalculation.deliveryTime = supplier.price.deliverytime;
  packagingCalculation.supplier = supplier;
  packagingCalculation.totalPrice = totalPrice;
  packagingCalculation.delivery = supplier.price.delivery;
}

/**
 * Update the supplier of a packaging
 * @param packaging selected packaging object
 * @param calculation calculation object
 * @param preferences preferences object
 * @param suppliers list of suppliers
 * @param newSupplier new supplier
 * @param context the data context
 */
function updatePackagingSupplier(
  packaging: SelectedPackagingsDocument,
  calculation: CalculationType,
  preferences: Preferences,
  suppliers: Array<SuppliersDocument>,
  newSupplier: BSON.ObjectId | "ownstock" | "customer",
  context: React.ContextType<typeof DataContext>
) {
  const packagingStock = context.packagingStock.filter(pS => pS.packaging.toString() === packaging._id.toString());
  const packagingCalculation = packaging.calculations?.find(calc => calc.id.toString() === calculation.id.toString());
  if (!packagingCalculation) return;
  const quantity = +calculation.units * +packaging.amount!;
  const manufacturer = preferences.selectedFiller || preferences.selectedManufacturer!;
  let supplier: PackagingCalculationSupplier;
  if (newSupplier === "ownstock") {
    supplier = {
      _id: "ownstock",
      name: "Stock",
      price: getPackagingStockPriceForManufacturer(packagingStock, manufacturer)
    };
  } else if (newSupplier === "customer") {
    supplier = { _id: "customer", name: "Customer", price: createCustomerPackagingPrice() };
  } else {
    const newSupplierDocument = suppliers.find(supp => supp._id.toString() === newSupplier.toString());
    if (!newSupplierDocument) console.error("No supplier found for", newSupplier.toString());
    supplier = getPackagingSupplierForMOQ(packaging, [newSupplierDocument!], quantity, true)!;
  }
  const totalPrice = quantity * supplier.price.price;
  packagingCalculation.totalAmount = quantity;
  packagingCalculation.estimatedPrice = supplier.price.price;
  packagingCalculation.price = supplier.price.price;
  packagingCalculation.deliveryTime = supplier.price.deliverytime;
  packagingCalculation.supplier = supplier;
  packagingCalculation.totalPrice = totalPrice;
  packagingCalculation.delivery = supplier.price.delivery;
}

/**
 * Get price object for supplied by stock
 * @param stock stock array of a packaging document
 * @param manufacturer manufacturer document
 * @returns {PackagingPrice} price object with average price from stock
 */
function getPackagingStockPriceForManufacturer(
  stock: Array<PackagingStockDocument>,
  manufacturer: ManufacturersDocument
): PackagingPrice {
  const averagePrice = getAverageStockPrice(stock, manufacturer);
  return {
    _id: new BSON.ObjectId(),
    moq: 0,
    price: averagePrice,
    deliverytime: 7,
    delivery: "",
    note: "",
    date: null
  };
}

/**
 * Create price object for customer as supplier
 * @returns {PackagingPrice} packaging price object with 0 as price
 */
function createCustomerPackagingPrice(): PackagingPrice {
  return {
    _id: new BSON.ObjectId(),
    moq: 0,
    price: 0,
    deliverytime: 7,
    delivery: "",
    note: "",
    date: null
  };
}

/**
 * Create price object for customer as supplier
 * @returns {CapsulePrice} capsule price object with 0 as price
 */
function createCustomerCapsulePrice(): CapsulePrice {
  return {
    _id: new BSON.ObjectId(),
    moq: 0,
    price: 0,
    deliverytime: 7,
    delivery: "",
    note: "",
    date: null
  };
}

/**
 * Get a capsule calculation object
 * @param capsule the capsule document
 * @param suppliers all suppliers
 * @param manufacturers all manufacturers
 * @param quantity the total quantity of capsules in 1k
 * @param calculation the calculation object
 * @param preferedManufacturer optional, if given it will be checked if that manufacturer has prices for a capsule. If yes, that manufacturer will be used
 * @returns {CapsuleCalculation} the capsule calculation object
 */
function getCapsulePrice(
  capsule: CapsulesDocument,
  suppliers: Array<SuppliersDocument>,
  manufacturers: Array<ManufacturersDocument>,
  quantity: number,
  calculation: CalculationType,
  preferedManufacturer?: ManufacturersDocument
): CapsuleCalculation {
  const supplier = getCapsuleSupplierForMOQ(
    capsule,
    suppliers,
    manufacturers,
    quantity,
    false,
    preferedManufacturer
  )! as {
    _id: Realm.BSON.ObjectId;
    name: string;
    price: CapsulePrice;
  };
  const totalPrice = supplier.price.price * quantity;
  return {
    id: calculation.id,
    auto: true,
    buffer: 0, //default 0
    totalAmount: quantity,
    estimatedPrice: supplier.price.price,
    price: supplier.price.price,
    supplier: supplier,
    deliveryTime: supplier.price.deliverytime,
    totalPrice: totalPrice,
    delivery: supplier.price.delivery
  };
}

/**
 * Update a capsule calculation
 * @param capsule selected capsule document
 * @param capsuleCalculation associated capsule calculation
 * @param suppliers array of suppliers
 * @param manufacturers array of manufacturers
 * @param units units
 * @param perUnit amount per unit
 * @param preferedManufacturer optional, if given it will be checked if that manufacturer has prices for a capsule. If yes, that manufacturer will be used
 */
function updateCapsulePrice(
  capsule: ExtendedCapsule,
  capsuleCalculation: CapsuleCalculation,
  suppliers: Array<SuppliersDocument>,
  manufacturers: Array<ManufacturersDocument>,
  units: number,
  perUnit: number,
  preferedManufacturer?: ManufacturersDocument
) {
  const quantity = (units * perUnit) / 1000;
  const supplier = getCapsuleSupplierForMOQ(
    capsule,
    suppliers,
    manufacturers,
    quantity,
    false,
    preferedManufacturer
  )! as {
    _id: Realm.BSON.ObjectId;
    name: string;
    price: CapsulePrice;
  };
  const totalPrice = supplier.price.price * quantity;
  capsuleCalculation.totalAmount = quantity;
  capsuleCalculation.estimatedPrice = supplier.price.price;
  capsuleCalculation.price = supplier.price.price;
  capsuleCalculation.supplier = supplier;
  capsuleCalculation.deliveryTime = supplier.price.deliverytime;
  capsuleCalculation.totalPrice = totalPrice;
  capsuleCalculation.delivery = supplier.price.delivery;
}

/**
 * Update the supplier of a capsule
 * @param capsule selected capsule document
 * @param calculation calculation object
 * @param preferences preferences object
 * @param suppliers list of suppliers
 * @param manufacturers list of suppliers
 * @param newSupplier new supplier
 */
function updateCapsuleSupplier(
  capsule: ExtendedCapsule,
  calculation: CalculationType,
  preferences: Preferences,
  suppliers: Array<SuppliersDocument>,
  manufacturers: Array<ManufacturersDocument>,
  newSupplier: BSON.ObjectId | "ownstock" | "customer"
) {
  const capsuleCalculation = capsule.calculations.find(calc => calc.id.toString() === calculation.id.toString());
  if (!capsuleCalculation) return;
  const quantity = (+calculation.units * +preferences.amountPerUnit) / 1000;
  let supplier: CapsuleCalculationSupplier;
  if (newSupplier === "customer") {
    supplier = { _id: "customer", name: "Customer", price: createCustomerCapsulePrice() };
  } else {
    const newSupplierDocument = suppliers.find(supp => supp._id.toString() === newSupplier.toString());
    if (!newSupplierDocument) console.error("No supplier found for", newSupplier.toString());
    const newManufacturerDocument = manufacturers.find(supp => supp._id.toString() === newSupplier.toString());
    if (!newManufacturerDocument) console.error("No manufacturer found for", newSupplier.toString());
    supplier = getCapsuleSupplierForMOQ(capsule, [newSupplierDocument!], [newManufacturerDocument!], quantity, true)!;
  }
  const totalPrice = quantity * supplier.price.price;
  capsuleCalculation.totalAmount = quantity;
  capsuleCalculation.estimatedPrice = supplier.price.price;
  capsuleCalculation.price = supplier.price.price;
  capsuleCalculation.supplier = supplier;
  capsuleCalculation.deliveryTime = supplier.price.deliverytime;
  capsuleCalculation.totalPrice = totalPrice;
  capsuleCalculation.delivery = supplier.price.delivery;
}

/**
 * Get supplier with lowest price and matching MOQ for current commodity and quantity
 * @param commodity a commodity document
 * @param suppliers list of suppliers
 * @param quantity the overall quantity to check the moq against
 * @param type the current active type
 * @param singleSupplier flag if only a single supplier should be selected, this implies suppliers.length is 1
 * @returns {CalculationSupplier | undefined }
 * CalculationSupplier object with id, name and price of the best matching supplier and price, undefined if no suppliers for the commodity were found
 */
function getSupplierForMOQ(
  commodity: CustomCommoditiesDocument,
  suppliers: Array<SuppliersDocument>,
  quantity: number,
  type: string,
  singleSupplier?: boolean
): CalculationSupplier | undefined {
  const commoditySuppliers = commodity.suppliers;
  const getMoq = (price: CommodityPrice) =>
    [ProductTypes.CUSTOM, ProductTypes.SERVICE, ProductTypes.SOFTGEL].includes(type)
      ? type === ProductTypes.SERVICE
        ? price.moq
        : price.moq * 1000
      : price.moq * 1000 * 1000;
  // Collect all prices
  let allPrices: Array<{ id: Realm.BSON.ObjectId; price: CommodityPrice }> = [];
  let lowestMOQ: number;
  commoditySuppliers.forEach(cSupplier => {
    if (!(singleSupplier && cSupplier._id.toString() !== suppliers[0]._id.toString())) {
      cSupplier.prices.forEach(sPrice => {
        const moq = getMoq(sPrice);
        if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
        allPrices.push({ id: cSupplier._id as Realm.BSON.ObjectId, price: sPrice });
      });
    }
  });
  if (allPrices.length === 0) {
    toast.error(
      <span>
        No supplier and prices found. Commodity can't be added until a supplier is added:{" "}
        <a href={"/commodity/" + commodity._id.toString()}>{commodity.title.en}</a>
      </span>,
      { autoClose: false }
    );
    console.error("No suppliers found for commodity", commodity._id.toString());
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => getMoq(price.price) <= quantity);
  } else {
    // If we did not found a matching moq take the lowest moq
    allPrices = allPrices.filter(price => getMoq(price.price) === lowestMOQ);
  }
  return getLowestPriceFromSupplier<CalculationSupplier>(commoditySuppliers, suppliers, allPrices);
}
/**
 * Get supplier with lowest price and matching MOQ for current commodity and quantity
 * @param packaging a packaging document
 * @param suppliers list of suppliers
 * @param quantity the overall quantity to check the moq against
 * @param singleSupplier flag if only a single supplier should be selected, this implies suppliers.length is 1
 * @returns {PackagingCalculationSupplier | undefined}
 * PackagingCalculationSupplier object with id, name and price of the best matching supplier and price or undefined if no suppliers were found for the packaging
 */
function getPackagingSupplierForMOQ(
  packaging: CustomPackagingsDocument,
  suppliers: Array<SuppliersDocument>,
  quantity: number,
  singleSupplier?: boolean
): PackagingCalculationSupplier | undefined {
  const packagingSuppliers = packaging.suppliers;
  // Collect all prices
  let allPrices: Array<{ id: BSON.ObjectId; price: PackagingPrice }> = [];
  let lowestMOQ: number;
  packagingSuppliers.forEach(pSupplier => {
    if (!(singleSupplier && pSupplier._id.toString() !== suppliers[0]._id.toString())) {
      pSupplier.prices.forEach(sPrice => {
        const moq = sPrice.moq;
        if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
        allPrices.push({ id: pSupplier._id as BSON.ObjectId, price: sPrice });
      });
    }
  });
  if (allPrices.length === 0) {
    toast.error(
      <span>
        No supplier and prices found. Packaging can't be used until a supplier is added:{" "}
        <a href={"/packaging/" + packaging._id.toString()}>{packagingUtils.getShortPackagingInfo(packaging)}</a>
      </span>,
      { autoClose: false }
    );
    console.error("No suppliers found for packaging", packaging._id.toString());
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => price.price.moq <= quantity);
  } else {
    // If we did not found a matching moq take the lowest moq
    allPrices = allPrices.filter(price => price.price.moq === lowestMOQ);
  }
  return getLowestPriceFromSupplier<PackagingCalculationSupplier>(packagingSuppliers, suppliers, allPrices);
}

/**
 * Checks all capsule supplier prices for their moq and saves the prices for the lowest moqs
 * @param capsuleSuppliers all relevant suppliers from a capsule
 * @param suppliers list of suppliers
 * @param manufacturers list of manufacturers
 * @param singleSupplier optional, flag if only a single supplier should be selected, this implies suppliers.length is 1
 * @returns { {allPrices: Array<{id: BSON.ObjectId, price: CapsulePrice, type?: string | undefined}>, lowestMOQ: number} } object with all prices and the lowest moq
 */
function calculateAllCapsulePrices(
  capsuleSuppliers: Array<CapsuleSupplier>,
  suppliers: Array<SuppliersDocument>,
  manufacturers: Array<ManufacturersDocument>,
  singleSupplier?: boolean
): { allPrices: Array<{ id: BSON.ObjectId; price: CapsulePrice; type?: string }>; lowestMOQ: number } {
  let allPrices: Array<{ id: BSON.ObjectId; price: CapsulePrice; type?: string }> = [];
  let lowestMOQ: number = Infinity;
  capsuleSuppliers.forEach(cSupplier => {
    let targetArray =
      manufacturers[0] !== undefined && cSupplier.type === CapsuleSupplierType.MANUFACTURER ? manufacturers : suppliers;
    let targetId = targetArray[0]?._id.toString();
    if (targetId && !(singleSupplier && cSupplier._id.toString() !== targetId)) {
      cSupplier.prices.forEach(sPrice => {
        const moq = sPrice.moq;
        if (!lowestMOQ || moq < lowestMOQ) lowestMOQ = moq;
        allPrices.push({
          id: cSupplier._id as BSON.ObjectId,
          price: sPrice,
          ...(cSupplier.type ? { type: cSupplier.type } : {})
        });
      });
    }
  });
  return { allPrices: allPrices, lowestMOQ: lowestMOQ };
}

/**
 * Get supplier with lowest price and matching MOQ for a capsule
 * @param capsule a capsule document
 * @param suppliers list of suppliers
 * @param manufacturers list of manufacturers
 * @param quantity the total quantity needed
 * @param singleSupplier optional, flag if only a single supplier should be selected, this implies suppliers.length is 1
 * @param preferredManufacturer optional, if given it will be checked if that manufacturer has prices for a capsule. If yes, that manufacturer will be used
 * @returns {CapsuleCalculationSupplier | undefined}
 * CapsuleCalculationSupplier object with id, name and price of the best matching supplier and price or undefined if no suppliers were found for the capsule
 */
function getCapsuleSupplierForMOQ(
  capsule: CapsulesDocument,
  suppliers: Array<SuppliersDocument>,
  manufacturers: Array<ManufacturersDocument>,
  quantity: number,
  singleSupplier?: boolean,
  preferredManufacturer?: ManufacturersDocument
): CapsuleCalculationSupplier | undefined {
  const capsuleSuppliers = capsule.suppliers;
  // Collect all prices
  let allPrices: Array<{ id: BSON.ObjectId; price: CapsulePrice; type?: string }> = [];
  let lowestMOQ: number;
  // check if any capsule suppliers match with the selected manufacturer
  const matchingCapsuleSuppliers = capsuleSuppliers.filter(
    cSup => cSup._id.toString() === preferredManufacturer?._id.toString()
  );
  // if a match was found, use those prices as priority (if valid)
  if (matchingCapsuleSuppliers.length > 0) {
    ({ allPrices, lowestMOQ } = calculateAllCapsulePrices(
      matchingCapsuleSuppliers,
      suppliers,
      manufacturers,
      singleSupplier
    ));
  }
  if (allPrices.length === 0 || matchingCapsuleSuppliers.length === 0) {
    ({ allPrices, lowestMOQ } = calculateAllCapsulePrices(capsuleSuppliers, suppliers, manufacturers, singleSupplier));
  }
  if (allPrices.length === 0) {
    toast.error(
      <span>
        No supplier and prices found. Capsule can't be used until a supplier or manufacturer is added:{" "}
        <a href={"/capsule/" + capsule._id.toString()}>{baseUtils.buildCapsuleString(capsule)}</a>
      </span>,
      { autoClose: false }
    );
    console.error("No suppliers found for capsule", capsule._id.toString());
    return;
  }
  const foundMatchingMOQ = quantity >= lowestMOQ!;
  if (foundMatchingMOQ) {
    // If we found a matching moq remove all non-matching moqs
    allPrices = allPrices.filter(price => price.price.moq <= quantity);
  } else {
    // If we did not found a matching moq take the lowest moq
    allPrices = allPrices.filter(price => price.price.moq === lowestMOQ);
  }
  return getLowestPriceFromSupplier<CapsuleCalculationSupplier>(capsuleSuppliers, suppliers, allPrices, manufacturers);
}

/**
 * Get lowest price from extracted suppliers for packaging or commodity
 * @param objectSuppliers collected suppliers for a packaging, commodity or capsule document
 * @param suppliers all suppliers
 * @param allPrices all collected prices for a packaging or commodity document
 * @param manufacturers optional, all manufacturers, needed for capsule calculation
 * @returns {T extends CalculationSupplier | PackagingCalculationSupplier | CapsuleCalculationSupplier}
 * a CalculationSupplier, PackagingCalculationSupplier or CapsuleCalculationSupplier object with id, name and price of the best matching supplier and price
 */
function getLowestPriceFromSupplier<
  T extends CalculationSupplier | PackagingCalculationSupplier | CapsuleCalculationSupplier
>(
  objectSuppliers: Array<{
    _id: BSON.ObjectId;
    prices: Array<PackagingPrice | CommodityPrice | CapsulePrice>;
    type?: string;
  }>,
  suppliers: Array<SuppliersDocument>,
  allPrices: Array<{ id: Realm.BSON.ObjectId; price: PackagingPrice | CommodityPrice | CapsulePrice }>,
  manufacturers?: Array<ManufacturersDocument>
): T {
  allPrices.sort((a, b) => a.price.price - b.price.price);
  // Check if supplier has another price with same MOQ that is higher
  const lowestPrice = allPrices[0];
  const lowestPriceSupplier = objectSuppliers.find(supp => supp._id.toString() === lowestPrice.id.toString())!;
  const pricesSameMOQ = lowestPriceSupplier.prices.filter(price => price.moq === lowestPrice.price.moq);
  pricesSameMOQ.sort((a, b) => b.price - a.price);
  const finalPrice = pricesSameMOQ[0];
  const isManufacturerType = lowestPriceSupplier.type !== undefined && lowestPriceSupplier.type === "manufacturer";
  const finalSupplier =
    manufacturers && isManufacturerType
      ? manufacturers.find(man => man._id.toString() === lowestPrice.id.toString())!
      : suppliers.find(supp => supp._id.toString() === lowestPrice.id.toString())!;
  return {
    _id: finalSupplier._id,
    name: finalSupplier.name,
    price: finalPrice,
    ...(manufacturers && isManufacturerType ? { type: "manufacturer" } : {})
  } as T;
}

/**
 * Calculate the total amount for a given commodity
 * @param perUnit amount per unit
 * @param units amount of units
 * @param amount amount of the commodity
 * @param type currently selected product type
 * @returns {number} the total amount
 */
function getTotalAmount(perUnit: number, units: number, amount: number, type: string): number {
  switch (type) {
    case ProductTypes.POWDER:
    case ProductTypes.LIQUID:
      return amount * units;
    case ProductTypes.CAPSULES:
    case ProductTypes.TABLETS:
      return amount * perUnit * units;
    case ProductTypes.SOFTGEL:
    case ProductTypes.CUSTOM:
      return perUnit * units;
    case ProductTypes.SERVICE:
      return 1;
    default:
      console.error("No matching product type", type);
      return 0;
  }
}

/**
 * Calculate the total amount for a given commodity
 * @param perUnit amount per unit
 * @param units amount of units
 * @param amount amount of the commodity
 * @param type currently selected product type
 * @param buffer the buffer in percent
 * @returns {number} the total amount + buffer
 */
function getTotalAmountWithBuffer(
  perUnit: number,
  units: number,
  amount: number,
  type: string,
  buffer: number
): number {
  // no buffer for service
  if (type === ProductTypes.SERVICE) return getTotalAmount(perUnit, units, amount, type);
  return getTotalAmount(perUnit, units, amount, type) * (1 + buffer / 100);
}

/**
 * Compute total price for a commodity
 * @param totalAmount total amount of the commodity
 * @param kgPrice price per kilogram
 * @param type current selected product type
 * @return {number} the calculated total price
 */
function getTotalPrice(totalAmount: number, kgPrice: number, type: string): number {
  if (type === ProductTypes.SERVICE) return kgPrice;
  if ([ProductTypes.SOFTGEL, ProductTypes.CUSTOM].includes(type)) return (totalAmount / 1000) * kgPrice;
  return (totalAmount / (1000 * 1000)) * kgPrice;
}

/**
 * Converts the amount given as a string from one unit to another
 * @param amount the amount to convert as a string
 * @param from the current unit
 * @param to the target unit
 * @returns {string} the converted amount as string
 */
function convertAmount(amount: string, from: string, to: string): string {
  // Don't do any conversion if parsing would result in 0, e.g. 0,0... to not lose the decimals
  if (+amount === 0) return amount;
  const conc = from + to;
  switch (conc) {
    case "ugmg":
    case "mgg":
    case "gkg":
      return (+amount / 1000).toString();
    case "mgug":
    case "gmg":
    case "kgg":
      return (+amount * 1000).toString();
    case "kgmg":
    case "gug":
      return (+amount * 1000 * 1000).toString();
    case "mgkg":
    case "ugg":
      return (+amount / (1000 * 1000)).toString();
    case "ugkg":
      return (+amount / (1000 * 1000 * 1000)).toString();
    case "kgug":
      return (+amount * 1000 * 1000 * 1000).toString();
    default:
      return amount;
  }
}

/**
 * Converts the NumValue from its current unit to the given unit
 * @param from the NumValue which should be converted
 * @param to the unit to which the conversion should be done
 * @returns {NumValue} the converted amount or, if the conversion failed because of an unknown unit, the original NumValue
 */
function convertNumValueWeight(from: NumValue, to: WeightUnit): NumValue {
  const fromUnit = from.unit === WeightUnit.ug ? "ug" : from.unit; // make sure the display value for ug ("\u00b5g") is not used for conversion
  const convertedAmount = convertAmount(from.value.toString(), fromUnit, to);
  if (+convertedAmount === from.value) return from;
  return {
    value: +convertedAmount,
    unit: to
  };
}

/**
 * Add the given numValues
 * @param unit optional, the unit the result should be in
 * @param numValues the numValues to add (one or multiple)
 * @returns {NumValue} the result of the added numValue in the given unit or the unit of the first numValue, if no unit was given
 */
function addNumValueWeight(unit?: WeightUnit, ...numValues: Array<NumValue>): NumValue {
  if (numValues.length === 0) return { value: 0, unit: unit ?? DEFAULTWEIGHTUNIT };
  const conversionUnit = unit ?? numValues[0].unit;
  return numValues.reduce(
    (acc, numValue) => {
      const convertedValue = convertNumValueWeight(numValue, conversionUnit as WeightUnit);
      return {
        value: acc.value + convertedValue.value,
        unit: conversionUnit
      };
    },
    { value: 0, unit: conversionUnit }
  );
}

/**
 * Builds a string with the amount and the biggest suitable unit
 * @param amountInMg The amount in milligrams
 * @param toFixed Number of digits after the decimal point
 * @param round Flag to indicate whether the number should be rounded or not, exclusive with toFixed param
 * @param useMg optional, Flag to indicate to use mg as a unit unless amount is less than 1 mg
 * @returns {string} A string with the amount and the best suitable unit
 */
function formatAmount(amountInMg: string | number, toFixed?: number, round?: boolean, useMg?: boolean): string {
  // Use absolute value and store the sign, otherwise negative values are problematic
  const sign = Math.sign(Number(amountInMg));
  const amountNum = Math.abs(Number(amountInMg));
  const amountStr = amountNum.toString();

  let tuple = [amountStr, "mg"];
  if (!useMg && amountNum >= 1000 * 1000) tuple = [convertAmount(amountStr, "mg", "kg"), "kg"];
  else if (!useMg && amountNum >= 1000) tuple = [convertAmount(amountStr, "mg", "g"), "g"];
  else if (amountNum < 1) tuple = [convertAmount(amountStr, "mg", "ug"), "\u00b5g"];

  if (toFixed) tuple[0] = parseFloat((+tuple[0]).toFixed(toFixed)).toString();
  else if (round) tuple[0] = (Math.round(+tuple[0] * 100) / 100).toString();
  // Apply sign again
  tuple[0] = (Number(tuple[0]) * sign).toString();
  return tuple.join("");
}

/**
 * Converts the amount to mg and formats it to the biggest suitable unit
 * @param amount The amount to convert as a string
 * @param from The unit the amount currently is
 * @param toFixed Number of digits after the decimal point to be displayed
 * @returns {string} A string with converted and formatted amount and the best suitable unit
 */
function convertAndFormatAmount(amount: string, from: string, toFixed?: number): string {
  const convertedAmount = convertAmount(amount, from, "mg");
  return formatAmount(convertedAmount, toFixed);
}

/**
 * Gets default density for a given composition
 * @param form the composition of the commodity
 * @returns default density
 */
function getDefaultDensity(form: string) {
  return form in DENSITY_DEFAULTS ? DENSITY_DEFAULTS[form] : 0.5;
}

/**
 * Computes the volume for a given extended commodity
 * @param commodity extended commodity object with amount and transformed form property
 * @returns tuple with the computed volume and flag if a density default was used or not
 */
function getVolume(commodity: SelectedCommoditiesDocument): any {
  //default values for each commodity type may differ
  const densityExists = !!commodity.density;
  const density = densityExists ? commodity.density : commodity.form ? getDefaultDensity(commodity.form!.en) : 0.5;
  // unit of density is g/ccm unit of amount is mg
  return [commodity.amount! / 1000 / density, densityExists];
}

/**
 * Computes the volume for a given recipe selection
 * @param commodities List of extended commodity objects representing a recipe
 * @returns {{value: number, noDefault: boolean}} object with volume and flag if no defaults were used or not
 */
function getRecipeVolume(commodities: Array<SelectedCommoditiesDocument>): { value: number; noDefault: boolean } {
  let volume = 0;
  let noDefault = true;
  for (let i = 0; i < commodities.length; i++) {
    let [tmpVolume, tmpFlag] = getVolume(commodities[i]);
    volume += tmpVolume;
    noDefault = noDefault && tmpFlag;
  }
  return { value: volume, noDefault };
}

/**
 * Filters manufacturers based on the given type and selected capsule
 * @param manufacturers the manufacturers to filter
 * @param type the product type to be filtered by
 * @param selectedCapsule optional, If indicated, filter the manufacturers according to whether they offer this capsule
 * @returns {Array<ManufacturersDocument>} the filtered manufacturers
 */
const getFilteredManufacturers = (
  manufacturers: Array<ManufacturersDocument>,
  type: string,
  selectedCapsule?: CapsulesDocument
): Array<ManufacturersDocument> => {
  if ([ProductTypes.SOFTGEL, ProductTypes.CUSTOM, ProductTypes.SERVICE].includes(type)) return manufacturers;
  let filteredManufacturers = manufacturers.filter(man => Object.keys(man).some(key => key.startsWith(type)));
  if (type === ProductTypes.CAPSULES && selectedCapsule) {
    filteredManufacturers = filteredManufacturers.filter(
      man =>
        man.capsules &&
        man.capsules.encapsulation &&
        man.capsules.encapsulation.some(obj => obj.size === selectedCapsule.capsule_size)
    );
  }
  return filteredManufacturers;
};

/**
 * Get the correct settings type for the given tab
 * @param tab currently active tab
 * @returns {"powder" | "liquid" | "softgel" | "custom" | "service" | "capsule" | "tablet" | undefined} correct order settings type or undefined if tab was not found
 */
function getTypeForTab(
  tab: string
): "powder" | "liquid" | "softgel" | "custom" | "service" | "capsule" | "tablet" | undefined {
  switch (tab) {
    case ProductTypes.CAPSULES:
      return "capsule";
    case ProductTypes.TABLETS:
      return "tablet";
    case ProductTypes.POWDER:
      return "powder";
    case ProductTypes.LIQUID:
      return "liquid";
    case ProductTypes.CUSTOM:
      return "custom";
    case ProductTypes.SOFTGEL:
      return "softgel";
    case ProductTypes.SERVICE:
      return "service";
  }
}

/**
 * Get the correct tab for given type
 * @param type the order settings type
 * @returns {string} the corresponding tab
 */
function getTabForType(type: string): string {
  switch (type) {
    case "capsule":
      return ProductTypes.CAPSULES;
    case "tablet":
      return ProductTypes.TABLETS;
    case "powder":
      return ProductTypes.POWDER;
    case "liquid":
      return ProductTypes.LIQUID;
    case "custom":
      return ProductTypes.CUSTOM;
    case "softgel":
      return ProductTypes.SOFTGEL;
    case "service":
      return ProductTypes.SERVICE;
    default:
      return ProductTypes.CAPSULES;
  }
}

/**
 * Prepare a calculation for the final order object for the selected commodity and calculation
 * @param commodity the selected commodity
 * @param calculationId calculation id of the required calculation
 * @param withDefaults flag if defaults should be added, e.g. if creating a new order from an already delivered one
 * @returns calculation price object
 */
function getRecipeCalculationEntry(
  commodity: SelectedCommoditiesDocument,
  calculationId: BSON.ObjectId,
  withDefaults?: boolean
) {
  const matchingCalculation = commodity.calculations.find(calc => calc.id.toString() === calculationId.toString())!;
  return {
    _id: commodity._id,
    amount: +commodity.amount!,
    buffer: +matchingCalculation.buffer,
    estimatedprice: +matchingCalculation.estimatedPrice,
    supplier: matchingCalculation.supplier._id,
    auto: matchingCalculation.auto,
    orderquantity: withDefaults ? null : matchingCalculation.orderQuantity,
    price: matchingCalculation.price,
    deliverytime: matchingCalculation.deliveryTime,
    requested: withDefaults ? null : matchingCalculation.requested,
    updated: withDefaults ? null : matchingCalculation.updated,
    ordered: withDefaults ? null : matchingCalculation.ordered,
    delivered: withDefaults ? null : matchingCalculation.delivered,
    userOrdered: withDefaults ? null : matchingCalculation.userOrdered,
    userDelivered: withDefaults ? null : matchingCalculation.userDelivered,
    eta: withDefaults ? null : matchingCalculation.eta,
    totalprice: matchingCalculation.totalPrice,
    purchasePrice: matchingCalculation.purchasePrice,
    purchaseCurrency: matchingCalculation.purchaseCurrency,
    delivery: matchingCalculation.delivery,
    incoterm: matchingCalculation.incoterm
  };
}

/**
 * Prepare a calculation for the final order object for the selected packaging and calculation
 * @param packaging the selected packaging
 * @param calculationId calculation id of the required calculation
 * @param withDefaults flag if defaults should be added, e.g. if creating a new order from an already delivered one
 * @returns calculation packagings object
 */
function getPackagingCalculationEntry(
  packaging: SelectedPackagingsDocument,
  calculationId: BSON.ObjectId,
  withDefaults?: boolean
) {
  const matchingCalculation = packaging.calculations!.find(calc => calc.id.toString() === calculationId.toString())!;
  return {
    _id: packaging._id,
    amount: +packaging.amount!,
    buffer: +matchingCalculation.buffer,
    estimatedprice: +matchingCalculation.estimatedPrice,
    supplier: matchingCalculation.supplier._id,
    auto: matchingCalculation.auto,
    orderquantity: withDefaults ? null : matchingCalculation.orderQuantity,
    price: matchingCalculation.price,
    deliverytime: matchingCalculation.deliveryTime,
    requested: withDefaults ? null : matchingCalculation.requested,
    updated: withDefaults ? null : matchingCalculation.updated,
    ordered: withDefaults ? null : matchingCalculation.ordered,
    delivered: withDefaults ? null : matchingCalculation.delivered,
    userOrdered: withDefaults ? null : matchingCalculation.userOrdered,
    userDelivered: withDefaults ? null : matchingCalculation.userDelivered,
    eta: withDefaults ? null : matchingCalculation.eta,
    totalprice: matchingCalculation.totalPrice,
    delivery: matchingCalculation.delivery
  };
}

/**
 * Computes the amount per unit according to the product type
 * @param productType the current product type
 * @param preferences preferences object
 * @param recipe all selected commodities
 * @returns {number | undefined} the amount per unit
 */
function getAmountPerUnit(
  productType: string,
  preferences: Preferences,
  recipe: Array<SelectedCommoditiesDocument>
): number | undefined {
  switch (productType) {
    case ProductTypes.CAPSULES:
    case ProductTypes.TABLETS:
    case ProductTypes.CUSTOM:
    case ProductTypes.SOFTGEL:
    case ProductTypes.SERVICE:
      return parseInt(preferences.amountPerUnit);
    case ProductTypes.POWDER:
    case ProductTypes.LIQUID:
      // sum of all recipe amounts
      return recipe.reduce((a, b) => a + +b.amount!, 0);
  }
}

/**
 * Get the average stock price for a specific location
 * @param stock list of batches
 * @param manufacturer (optional) a manufacturer document
 * @returns {number} the average stock price in relation to their corresponding amount
 */
function getAverageStockPrice(
  stock: Array<CommodityBatch | PackagingStockDocument>,
  manufacturer?: ManufacturersDocument
): number {
  const stockForManufacturer = stock.filter(
    st =>
      !st.disabled &&
      st.amount > 0 &&
      (!manufacturer || st.location.toString() === manufacturer._id.toString()) &&
      st.supplier !== CUSTOMER
  );
  if (stockForManufacturer.length === 0) return 0;
  const totalStockAmount = stockForManufacturer.reduce((a, b) => a + +b.amount, 0);
  return stockForManufacturer.reduce((a, b) => a + +b.amount * +b.price, 0) / totalStockAmount;
}

/**
 * Reconstruct the generalUnitPrice with saved prices, if not present, use current prices -> might not be the same as in order
 * @param order a order document
 * @param context the data context
 * @param manufacturerId optional, manufacturer id overwriting the id in order settings
 * @param forceRecalc optional, flag to force recalculation and ignore standard calc
 * @param isFiller optional, flag to indicate whether the manufacturer id is a filler
 * @returns {number} reconstructed generalUnitPrice
 */
const recalculateGeneralUnitPrice = (
  order: CustomOrder,
  context: React.ContextType<typeof DataContext>,
  manufacturerId?: string,
  forceRecalc?: boolean,
  isFiller?: boolean
): { generalUnitPrice: number; prices: StandardCalculationInfo } => {
  const { units, packagings, info } = order.calculations[0];
  const { settings } = order;
  // calculate general unit price
  const type = getTabForType(settings.type);
  const packagingDocs = context.packagings.filter(p =>
    packagings.some(orderP => p._id.toString() === orderP._id.toString())
  );
  const packageTypes = packagingDocs.map(p => {
    return p.packaging_type;
  });
  const selectedManufacturer = !isFiller && manufacturerId ? manufacturerId : settings.manufacturer._id;
  const selectedFiller = isFiller && manufacturerId ? manufacturerId : settings.filler?._id;
  const perUnit = settings.perUnit;
  const prices: StandardCalculationInfo = {};
  const standardCalc = info.standardCalculation;

  const getManufacturingCost = (validPackaging: Array<string>) => {
    if (
      (validPackaging.includes(PackagingTypes.BOTTLE) && packageTypes.includes(PackagingTypes.BOTTLE)) ||
      (validPackaging.includes(PackagingTypes.LIQUIDBOTTLE) && packageTypes.includes(PackagingTypes.LIQUIDBOTTLE))
    ) {
      let priceBottling;
      let unitPriceBottling;
      if (!forceRecalc && standardCalc?.bottling) {
        priceBottling = standardCalc.bottling.price;
        unitPriceBottling = standardCalc.bottling.unitPrice;
      } else {
        priceBottling = +getPriceFromSelectedManufacturer(
          order,
          context,
          selectedFiller || selectedManufacturer,
          BOTTLING
        );
        unitPriceBottling = priceBottling * (calculateTotalAmount(order, context, BOTTLING, true) / units);
      }
      prices.bottling = { price: priceBottling, unitPrice: unitPriceBottling };
    } else if (validPackaging.includes(PackagingTypes.BLISTER) && packageTypes.includes(PackagingTypes.BLISTER)) {
      let priceBlistering;
      let unitPriceBlistering;
      if (!forceRecalc && standardCalc?.blistering) {
        priceBlistering = standardCalc.blistering.price;
        unitPriceBlistering = standardCalc.blistering.unitPrice;
      } else {
        priceBlistering = +getPriceFromSelectedManufacturer(
          order,
          context,
          selectedFiller || selectedManufacturer,
          BLISTERING
        );
        unitPriceBlistering = priceBlistering * (calculateTotalAmount(order, context, BLISTERING) / units);
      }
      prices.blistering = { price: priceBlistering, unitPrice: unitPriceBlistering };
    }
  };

  if (type === ProductTypes.CAPSULES) {
    let priceEncapsulation;
    let unitPriceEncapsulation;
    if (!forceRecalc && standardCalc?.encapsulation) {
      priceEncapsulation = standardCalc.encapsulation.price;
      unitPriceEncapsulation = standardCalc.encapsulation.unitPrice;
    } else {
      priceEncapsulation = +getPriceFromSelectedManufacturer(order, context, selectedManufacturer, ENCAPSULATION);
      unitPriceEncapsulation = (priceEncapsulation * perUnit) / 1000;
    }
    prices.encapsulation = { price: priceEncapsulation, unitPrice: unitPriceEncapsulation };
    getManufacturingCost([PackagingTypes.BOTTLE, PackagingTypes.BLISTER]);
  } else if (type === ProductTypes.TABLETS) {
    let priceTableting;
    let unitPriceTableting;
    if (!forceRecalc && standardCalc?.tableting) {
      priceTableting = standardCalc.tableting.price;
      unitPriceTableting = standardCalc.tableting.unitPrice;
    } else {
      priceTableting = +getPriceFromSelectedManufacturer(order, context, selectedManufacturer, TABLETING);
      unitPriceTableting = (priceTableting / 1000) * perUnit;
    }
    prices.tableting = { price: priceTableting, unitPrice: unitPriceTableting };
    getManufacturingCost([PackagingTypes.BOTTLE]);
  } else if (type === ProductTypes.POWDER || type === ProductTypes.LIQUID) {
    let priceBlending;
    let unitPriceBlending;
    if (!forceRecalc && standardCalc?.blending) {
      priceBlending = standardCalc.blending.price;
      unitPriceBlending = standardCalc.blending.unitPrice;
    } else {
      priceBlending = +getPriceFromSelectedManufacturer(order, context, selectedManufacturer, BLENDING);
      unitPriceBlending = priceBlending * (perUnit / (1000 * 1000));
    }
    prices.blending = { price: priceBlending, unitPrice: unitPriceBlending };
    getManufacturingCost(type === ProductTypes.POWDER ? [PackagingTypes.BOTTLE] : [PackagingTypes.LIQUIDBOTTLE]);
  } else if (type === ProductTypes.SOFTGEL) {
    getManufacturingCost([PackagingTypes.BOTTLE, PackagingTypes.BLISTER]);
  }

  return {
    generalUnitPrice: Object.values(prices).reduce((a, b) => {
      return a + b.unitPrice;
    }, 0),
    prices
  };
};

/**
 * Get the price for the given type provided by the given manufacturer
 * @param order a order document
 * @param context the data context
 * @param manufacturer id of the selected manufacturer or manufacturer document
 * @param type given type
 * @returns {string} price for the type of selected manufacturer
 */
const getPriceFromSelectedManufacturer = (
  order: CustomOrder,
  context: React.ContextType<typeof DataContext>,
  manufacturer: BSON.ObjectId | string | ManufacturersDocument,
  type: PRODUCTION_TYPES
): string => {
  const manufacturerDoc =
    typeof manufacturer === "object" && "_id" in manufacturer
      ? manufacturer
      : getFilteredManufacturersForOrder(order, context).find(m => m._id.toString() === manufacturer.toString());
  const { settings } = order;
  let activeType = getTabForType(settings.type);
  if (activeType === ProductTypes.LIQUID) activeType = LIQUID;
  else if (activeType === ProductTypes.SOFTGEL) activeType = ProductTypes.CAPSULES; // softgels dont exist themselves, so use capsules for it
  let costOfManufacturer: string = "0";
  const selectedCapsule = context.capsules.find((c: CapsulesDocument) => c._id.toString() === settings.id.toString());
  if (manufacturerDoc) {
    if (type === ENCAPSULATION && selectedCapsule) {
      costOfManufacturer = calculationHelper.getPriceFromManufacturer(
        manufacturerDoc,
        ProductTypes.CAPSULES,
        type,
        "size",
        selectedCapsule.capsule_size
      );
    } else {
      const totalAmount = calculateTotalAmount(order, context, type);
      costOfManufacturer = calculationHelper.getPriceFromManufacturer(
        manufacturerDoc,
        activeType,
        type,
        "amount",
        totalAmount.toString()
      );
    }
  }
  return costOfManufacturer;
};

/**
 * Recalculate totalAmount for calculating total costs
 * @deprecated
 * @remarks DO NOT USE THIS FUNCTION IF YOU ARE NOT 100% SURE HOW IT WORKS AND WHAT EXACTLY YOU NEED
 * @param order a order document
 * @param context the data context
 * @param type type of production the price calculation depends on
 * @param forBottlingTotalCost optional, flag to indicate if this function call is for the total cost (true) or to get cost per bottle from a manufacturer (false).
 *                               The bottling cost for powder/liquid depends on the total weight first but for the total cost ofc only the bottle amount is relevant.
 *                               I.e. if flag is false for powder and liquid the total weight in kg is returned for getting a price from a manufacturer. This flag is only relevant for bottling
 * @returns {number} calculated total amount
 */
const calculateTotalAmount = (
  order: CustomOrder,
  context: React.ContextType<typeof DataContext>,
  type: PRODUCTION_TYPES,
  forBottlingTotalCost?: boolean
): number => {
  const { units, packagings } = order.calculations[0];
  const amountPerUnit = order.settings.perUnit;
  let totalAmount: number = 0;
  if (type === BLISTERING) {
    const packagingId = context.packagings.find(p =>
      packagings.some(
        orderP => p._id.toString() === orderP._id.toString() && p.packaging_type === PackagingTypes.BLISTER
      )
    )?._id;
    const amount = packagings.find(p => p._id.toString() === packagingId.toString())?.amount || 1;
    totalAmount = units * amount;
  } else if (type === TABLETING || type === ENCAPSULATION) {
    totalAmount = (units * amountPerUnit) / 1000;
  } else if (type === BOTTLING) {
    const packagingId = context.packagings.find(p =>
      packagings.some(
        orderP =>
          p._id.toString() === orderP._id.toString() &&
          (p.packaging_type === PackagingTypes.BOTTLE || p.packaging_type === PackagingTypes.LIQUIDBOTTLE)
      )
    )?._id;
    const amount = packagings.find(p => p._id.toString() === packagingId.toString())?.amount || 1;
    // Bottling cost per unit for powder/liquid actually depend on the total weight but the total cost ofc depend on the amount of bottles
    if (!forBottlingTotalCost && [T_POWDER, T_LIQUID].includes(order.settings.type))
      totalAmount = (amountPerUnit * units) / (1000 * 1000);
    else totalAmount = units * amount;
  } else if (type === BLENDING) {
    totalAmount = (amountPerUnit * units) / (1000 * 1000);
  }
  return totalAmount;
};

/**
 * Get a list of available manufacturers for the given order
 * @param order a order document
 * @param context the data context
 * @returns {Array<ManufacturersDocument>} list of manufacturers
 */
const getFilteredManufacturersForOrder = (
  order: CustomOrder,
  context: React.ContextType<typeof DataContext>
): Array<ManufacturersDocument> => {
  const { type, id } = order.settings;
  const manufacturers = context.manufacturers;
  let selectedCapsule;
  if (type === T_CAPSULE)
    selectedCapsule = context.capsules.find((c: CapsulesDocument) => c._id.toString() === id.toString());
  const configuratorType = getTabForType(type) || "";
  return getFilteredManufacturers(manufacturers, configuratorType, selectedCapsule);
};

/**
 * Recalculate unitPrice without custom- or generalUnitPrice, either with the custom calculation or by reconstructing
 * with manufacturer prices (might differ from order unitPrice)
 * @param order a order document
 * @param context the data context
 * @returns {number} unitPrice without custom- or generalUnitPrice
 */
const recalculateUnitPrice = (order: CustomOrder, context: React.ContextType<typeof DataContext>): number => {
  const { units, info } = order.calculations[0];
  const settings = order.settings;
  const type = getTabForType(settings.type);
  const customCalc = info.customCalculation;
  const standardCalc = info.standardCalculation;
  const oldUnitPriceNaked = info.unitpricenaked;
  if (customCalc) {
    const customUnitPrice = calculateCustomUnitPriceFromOrder(order);
    return oldUnitPriceNaked - customUnitPrice;
  } else if (standardCalc) {
    return (
      oldUnitPriceNaked -
      (Object.entries(standardCalc).reduce(
        (sum, [key, priceInfo]) => sum + (key === "capsule" ? 0 : priceInfo.unitPrice),
        0
      ) +
        (info.marginBuffer || 0))
    );
  } else {
    const recipeUnitPrice = orderCalculationUtils.calculateCommoditiesUnitPrice(order);
    const packagingUnitPrice = orderCalculationUtils.calculatePackagingUnitPrice(order);
    let capsuleUnitPrice = 0;
    if (type === ProductTypes.CAPSULES) {
      const capsuleId = settings.id;
      const capsule = context.capsules.find(s => s._id.toString() === capsuleId.toString());
      if (capsule) {
        const quantity = (units * settings.perUnit) / 1000;
        const capsulePrice = getCapsuleSupplierForMOQ(capsule, context.suppliers, context.manufacturers, quantity)
          ?.price.price;

        if (capsulePrice) capsuleUnitPrice = (capsulePrice * settings.perUnit) / 1000;
      } else {
        toast.error("Capsule not found for Calculation");
      }
    }
    return recipeUnitPrice + packagingUnitPrice + capsuleUnitPrice;
  }
};

/**
 * Recalculate customUnitPrice from custom calculation in order
 * @param order a order document
 * @returns {number} customUnitPrice from order
 */
const calculateCustomUnitPriceFromOrder = (order: CustomOrder): number => {
  const { units, info } = order.calculations[0];
  const customCalc = info.customCalculation;
  let customUnitPrice = 0;
  if (customCalc) {
    const customCalcList: Array<[key: string, value: number | string | CalculationManufacturerPrice]> =
      Object.entries(customCalc);
    customUnitPrice =
      customCalcList.reduce((sum, [key, value]) => {
        if (key === "optionalCosts" && typeof value === "number") {
          return sum + value / units;
        } else if (typeof value === "object") {
          return sum + value.unitPrice;
        } else {
          return sum;
        }
      }, 0) + (info.marginBuffer || 0);
  }
  return customUnitPrice;
};

// eslint-disable-next-line
export default {
  getCommodityPrice,
  getPackagingPrice,
  convertAndFormatAmount,
  createCustomerCommodityPrice,
  createCustomerPackagingPrice,
  getCommodityStockPriceForManufacturer,
  getPackagingStockPriceForManufacturer,
  formatAmount,
  convertAmount,
  convertNumValueWeight,
  addNumValueWeight,
  getTotalAmountWithBuffer,
  getTotalPrice,
  getRecipeVolume,
  getCapsulePrice,
  getFilteredManufacturers,
  getTabForType,
  getTypeForTab,
  getRecipeCalculationEntry,
  getPackagingCalculationEntry,
  getPackagingByPriceObject,
  getAmountPerUnit,
  getAverageStockPrice,
  updateCapsulePrice,
  updateCommodityPrice,
  updatePackagingPrice,
  updateCapsuleSupplier,
  updatePackagingSupplier,
  updateCommoditySupplier,
  updateCalculationWithPackagingPrice,
  recalculateGeneralUnitPrice,
  getFilteredManufacturersForOrder,
  calculateTotalAmount,
  getPriceFromSelectedManufacturer,
  recalculateUnitPrice,
  calculateCustomUnitPriceFromOrder
};
