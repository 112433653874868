import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { DEFAULTDIMENSIONS, DEFAULTMAXWEIGHT, DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";
import LengthUnitSelect from "../../common/LengthUnitSelect";
import {
  Dimensions,
  PackagingUnitShape,
  WarehouseGeneralValues
} from "../../../../model/configuration/warehouseConfiguration.types";
import { NumValue } from "../../../../model/common.types";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { getNumericValue } from "../../../../utils/baseUtils";
import { getStorageSpaceShapeOptions } from "../../../../utils/warehouseStorageSpaceUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import dbService, { CONFIGURATION, UpdateAction } from "../../../../services/dbService";
import { getConfigTimelineEntry } from "../../../../utils/warehouseConfigUtils";
import toastUtils from "../../../../utils/toastUtils";

interface SetDefaultStorageSpaceModalState {
  dimensions: Dimensions;
  maxWeight: NumValue;
}

const getDefaultStorageSpace = (
  generalValues: WarehouseGeneralValues | undefined
): SetDefaultStorageSpaceModalState => {
  const defaultStorageSpace = generalValues?.defaultStorageSpace;
  return {
    dimensions: defaultStorageSpace?.dimensions ?? DEFAULTDIMENSIONS,
    maxWeight: defaultStorageSpace?.maxWeight ?? DEFAULTMAXWEIGHT
  };
};

const SetDefaultStorageSpaceModal: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const { configuration } = warehouseContext;

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [state, setState] = useState<SetDefaultStorageSpaceModalState>(
    getDefaultStorageSpace(configuration?.values.generalValues)
  );

  const storageSpaceShapeOptions = useMemo(() => getStorageSpaceShapeOptions(), []);

  const errors = useMemo(() => {
    const { dimensions, maxWeight } = state;
    const newErrors: Array<string> = [];
    if (dimensions.length === 0 && dimensions.width === 0 && dimensions.height === 0 && maxWeight.value === 0) {
      newErrors.push(i18n.t("warehouse:storageSpaceDefaultNoValueError"));
    }
    if (
      !_.isEqual(
        { length: dimensions.length, width: dimensions.width, height: dimensions.height },
        { length: DEFAULTDIMENSIONS.length, width: DEFAULTDIMENSIONS.width, height: DEFAULTDIMENSIONS.height }
      ) &&
      (dimensions.length === 0 || dimensions.width === 0 || dimensions.height === 0)
    ) {
      newErrors.push(i18n.t("warehouse:storageSpaceDimensionError"));
    }
    return newErrors;
  }, [state]);

  const getDefaultStorageSpaceValues = useCallback(() => {
    return {
      ...(state.dimensions.height !== 0 &&
        state.dimensions.width !== 0 &&
        state.dimensions.length !== 0 && { dimensions: state.dimensions }),
      ...(state.maxWeight.value !== 0 && { maxWeight: state.maxWeight })
    };
  }, [state]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  const handleChangeDimensions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { dimensions } = state;
      const newDimensions = _.cloneDeep(dimensions);
      const newDimension = getNumericValue(e, false);
      _.set(newDimensions, e.currentTarget.name, Number(newDimension));
      setState(prevState => {
        return { ...prevState, dimensions: newDimensions };
      });
    },
    [state.dimensions]
  );

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const dimensions = _.cloneDeep(state.dimensions);
      const { name, value } = e.currentTarget;
      switch (name) {
        case "unit":
          dimensions.unit = value;
          break;
        case "shape":
          const newShape = value as PackagingUnitShape; // PackagingUnitShape is used for storage space and packaging dimensions
          dimensions.shape = newShape;
          break;
      }
      setState(prevState => {
        return { ...prevState, dimensions };
      });
    },
    [state.dimensions]
  );

  const handleChangeMaxWeight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const maxWeight = _.cloneDeep(state.maxWeight);
      const updatedMaxWeight = getNumericValue(e, false);
      maxWeight.value = Number(updatedMaxWeight);
      setState(prevState => {
        return { ...prevState, maxWeight };
      });
    },
    [state.maxWeight]
  );

  const handleSave = useCallback(async () => {
    setSaving(true);
    const newDefaultStorageSpaceValues = getDefaultStorageSpaceValues();
    try {
      if (configuration) {
        const newConfig = _.cloneDeep(configuration.values);
        newConfig.generalValues.defaultStorageSpace = newDefaultStorageSpaceValues;
        const action: UpdateAction = {
          collection: CONFIGURATION,
          filter: { _id: configuration._id },
          update: { lastUpdate: new Date(), "values.generalValues.defaultStorageSpace": newDefaultStorageSpaceValues },
          push: {
            timeline: getConfigTimelineEntry(configuration.values, newConfig)
          }
        };
        const res = await dbService.transaction([action]);
        await toastUtils.databaseOperationToast(
          res,
          i18n.t("warehouse:storageSpaceDefaultCreateSuccess"),
          i18n.t("warehouse:storageSpaceDefaultCreateError"),
          () => {
            setShow(false);
          }
        );
      }
    } catch (e) {
      toast.error(i18n.t("warehouse:storageSpaceDefaultCreateError") + e);
    } finally {
      setState(getDefaultStorageSpace(configuration?.values.generalValues));
      setSaving(false);
    }
  }, [getDefaultStorageSpaceValues, configuration]);

  return (
    <>
      <i
        className={"fa fa-cog ml-2 pointer"}
        style={{ height: "13px", width: "13px", opacity: 1 }}
        onClick={handleShow}
      />
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t("warehouse:storageSpaceDefaultSettings")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("warehouse:length")}</span>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.length}
                  name="length"
                  type="number"
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect selectedUnit={state.dimensions.unit} onUnitChange={handleChangeSelect} />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:width")}</span>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.width}
                  name="width"
                  type="number"
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect selectedUnit={state.dimensions.unit} onUnitChange={handleChangeSelect} />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:height")}</span>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.dimensions.height}
                  name="height"
                  type="number"
                  onChange={handleChangeDimensions}
                />
                <div className="input-group-append">
                  <LengthUnitSelect selectedUnit={state.dimensions.unit} onUnitChange={handleChangeSelect} />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("packaging:shape")}</span>
            </div>
            <div className="col-8">
              <select
                className="form-control"
                value={state.dimensions.shape}
                name="shape"
                onChange={handleChangeSelect}
              >
                {storageSpaceShapeOptions.map(s => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-4 text-dark align-self-center">
              <span>{i18n.t("warehouse:maxWeight")}</span>
            </div>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  value={state.maxWeight.value}
                  name="maxWeight"
                  type="number"
                  onChange={handleChangeMaxWeight}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{DEFAULTWEIGHTUNIT}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            {i18n.t("common:close")}
          </button>
          <ErrorOverlayButton
            buttonText={i18n.t("common:save")}
            className="btn btn-success"
            onClick={handleSave}
            errors={errors}
            saving={saving}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SetDefaultStorageSpaceModal;
