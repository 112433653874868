import _ from "lodash";
import Select from "react-select";
import React, { useMemo } from "react";
import { CommodityInformation, CommodityReservationInformation } from "../../../../model/warehouse/customTypes.types";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT, getBBDColor } from "../../../../utils/warehouseUtils";
import baseUtils, { formatNumValue, round } from "../../../../utils/baseUtils";
import { Input } from "../../../common/Input";
import { useDataContext } from "../../../../context/dataContext";
import { ContentType } from "../../../../model/warehouse/common.types";
import { BatchStatus, getBatchStatus } from "../../../../utils/batchUtils";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { SelectOption } from "../../../../model/common.types";
import { LocationWarehouseSnapshot } from "../../../../model/warehouse/batch.types";
import i18n from "../../../../translations/i18n";

interface OrderReservationAmountSelectionProps {
  selectedCommodity: CommodityInformation | undefined;
  orderReservations: Array<CommodityReservationInformation>;
  onChangeReservationAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeReservationLocation: (e: any) => void;
}

const OrderReservationAmountSelection: React.FC<OrderReservationAmountSelectionProps> = ({
  selectedCommodity,
  orderReservations,
  onChangeReservationAmount,
  onChangeReservationLocation
}) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { batch } = dataContext;
  const { configuration } = warehouseContext;

  const relevantBatches = useMemo(
    () =>
      selectedCommodity
        ? batch.filter(
            b =>
              b.content.type === ContentType.COMMODITY &&
              b.content.details._id.toString() === selectedCommodity.commodityId &&
              getBatchStatus(b) !== BatchStatus.BLOCKED
          )
        : [],
    [selectedCommodity, batch]
  );

  const earliestBBD = useMemo(() => {
    let earliestBBD: Date | null = null;
    relevantBatches.forEach(b => {
      if (!earliestBBD || earliestBBD > b.expiry) earliestBBD = b.expiry;
    });
    return earliestBBD;
  }, [relevantBatches]);

  const warehouseSelectOptions: Array<SelectOption<LocationWarehouseSnapshot>> | undefined = useMemo(() => {
    return configuration?.values.warehouseStructure.flatMap(wh => {
      return {
        value: wh._id.toString(),
        label: `${wh.shortName} - ${resolveTranslation(wh.warehouseName)}`,
        data: _.omit(wh, "physicalWarehouses") // snapshot
      };
    });
  }, [configuration?.values.warehouseStructure]);

  const selectedAmount = useMemo(() => {
    if (!selectedCommodity) return 0;
    const reservation = orderReservations.find(r => r.commodityId === selectedCommodity.commodityId);
    if (reservation)
      return +calculationUtils.convertAmount(
        reservation.amount.value.toString(),
        reservation.amount.unit,
        DEFAULTWEIGHTUNIT
      );
    return 0;
  }, [orderReservations, selectedCommodity]);

  const selectedLocation = useMemo(() => {
    if (!selectedCommodity) return null;
    const location = orderReservations.find(r => r.commodityId === selectedCommodity.commodityId)?.location;
    if (!location) return null;
    return {
      value: location._id.toString(),
      label: `${location.shortName} - ${resolveTranslation(location.warehouseName)}`,
      data: location
    };
  }, [orderReservations, selectedCommodity]);

  const totalReserved = useMemo(() => {
    if (!selectedCommodity) return selectedAmount;
    return (
      +calculationUtils.convertAmount(
        selectedAmount.toString(),
        DEFAULTWEIGHTUNIT,
        selectedCommodity.reservedAmount.unit
      ) + selectedCommodity.reservedAmount.value
    );
  }, [selectedAmount, selectedCommodity]);

  const amountDiff = useMemo(() => {
    if (!selectedCommodity || totalReserved === 0) return null;
    const diff = totalReserved - selectedCommodity.neededAmount.value;
    const percent = (diff / selectedCommodity.neededAmount.value) * 100;
    return [diff, percent];
  }, [selectedCommodity, totalReserved]);

  return (
    <>
      <h4 className="font-weight-bold text-black">{i18n.t("warehouse:amount")}</h4>
      <div className="row">
        <div className="col-12">
          <div className="kt-portlet__body px-0 pt-0">
            <div className="row">
              <div className="col-6">
                <div className="input-group input-group-solid input-group-md mt-4 mb-4">
                  <Input
                    type="number"
                    className="form-control form-control-solid"
                    placeholder="Amount"
                    value={selectedAmount}
                    min={0}
                    style={{ backgroundColor: "#ECF0F3" }}
                    onBlur={onChangeReservationAmount}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text input-group-solid">{DEFAULTWEIGHTUNIT}</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="input-group input-group-solid input-group-md mt-4 mb-4">
                  <Select
                    className="select-warehouse w-100"
                    classNamePrefix="select-warehouse"
                    placeholder={i18n.t("warehouse:location")}
                    isDisabled={selectedAmount === 0}
                    value={selectedLocation}
                    onChange={onChangeReservationLocation}
                    options={warehouseSelectOptions}
                  />
                </div>
              </div>
            </div>
            <div className="p-2 py-4 bg-light">
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:availableAmount")}:</div>
                <div className="col-6 kt-font-bold text-black ">
                  {selectedCommodity ? `${formatNumValue(selectedCommodity.availableAmount)}` : ""}
                </div>
              </div>
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:neededAmount")}:</div>
                <div className="col-6 kt-font-bold text-black ">
                  {selectedCommodity
                    ? `ca. ${calculationUtils.formatAmount(selectedCommodity.neededAmount.value, 2)}`
                    : "-"}
                </div>
              </div>
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:alreadyReserved")}:</div>
                <div className={"col-6 kt-font-bold text-black"}>
                  {selectedCommodity && selectedCommodity.reservedAmount.value > 0
                    ? `${calculationUtils.formatAmount(selectedCommodity.reservedAmount.value, 2)}`
                    : "-"}
                </div>
              </div>
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:totalReserved")}:</div>
                <div
                  className={"col-6 kt-font-bold " + (amountDiff && amountDiff[0] >= 0 ? "text-success" : "text-black")}
                >
                  {selectedCommodity
                    ? totalReserved > 0
                      ? `${calculationUtils.formatAmount(totalReserved, 2)}`
                      : "0kg"
                    : "-"}
                </div>
              </div>
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:differenceTotal")}:</div>
                <div
                  className={
                    "col-6 kt-font-bold " +
                    (!amountDiff ? "text-black" : amountDiff[0] >= 0 ? "text-success" : "text-warning")
                  }
                >
                  {!amountDiff
                    ? "-"
                    : `${amountDiff[0] > 0 ? "+" : ""}${
                        amountDiff[0] === 0 ? "0kg" : calculationUtils.formatAmount(amountDiff[0], 2)
                      }`}{" "}
                  / {!amountDiff ? "-" : `${amountDiff[1] > 0 ? "+" : ""}${round(amountDiff[1])}%`}
                </div>
              </div>
              <div className="form-group form-group-xs d-flex info-row">
                <div className="col-6 text-black ">{i18n.t("warehouse:bbd")}:</div>
                <div className={"col-6 kt-font-bold " + getBBDColor(earliestBBD)}>
                  {earliestBBD ? `${i18n.t("warehouse:from")} ${baseUtils.formatDate(earliestBBD)}` : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderReservationAmountSelection;
