import { BSON } from "realm-web";
import { Reservation, ReservationState } from "../model/warehouse/reservation.types";
import { CommoditiesDocument } from "../model/commodities.types";
import { ContentType } from "../model/warehouse/common.types";
import { NumValue } from "../model/common.types";

/**
 * Get the total reserved amount for a material
 * @param reservation list of all reservations
 * @param material material document or already a specific id
 * @param ordersToExclude optional, Array of orderIds for which the reservations should not be regarded
 * @return {NumValue | null} total reserved amount
 */
export const getReservedAmountForMaterial = (
  reservation: Array<Reservation>,
  material: CommoditiesDocument | string | BSON.ObjectId,
  ordersToExclude?: Array<string>
): NumValue | null => {
  const materialId = typeof material === "object" && "_id" in material ? material._id.toString() : material.toString();
  const reservations = reservation.filter(r => {
    const reservationOpen = r.state === ReservationState.OPEN;
    const excluded = ordersToExclude ? ordersToExclude.includes(r.order._id.toString()) : false;
    const includesMaterial = r.materials.some(
      m => m.material.type === ContentType.COMMODITY && m.material.details._id.toString() === materialId
    );
    return reservationOpen && !excluded && includesMaterial;
  });
  let unit: string | null = null;
  let amount = 0;
  reservations.forEach(r => {
    r.materials.forEach(m => {
      if (m.material.type === ContentType.COMMODITY && m.material.details._id.toString() === materialId) {
        if (unit && unit !== m.reservedAmount.unit) throw Error("Mismatch in units. Cannot calculate");
        amount += m.reservedAmount.value;
      }
      if (!unit) unit = m.reservedAmount.unit;
    });
  });
  if (!unit) return null;
  return { value: amount, unit };
};
