import React, { useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import i18n from "../../../../translations/i18n";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";

interface DeliveryContextMenuProps {
  deliveryId: string;
}

export const DeliveryContextMenu: React.FC<DeliveryContextMenuProps> = ({ deliveryId }) => {
  const dispatch = useWarehouseDispatch();
  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setShow(prevShow => !prevShow);
  }, []);

  const handleHide = useCallback(() => setShow(false), []);

  const handleTriggerAction = useCallback(
    (number: WarehouseActionNumber) => {
      setShow(false);
      dispatch({ type: WarehouseActionType.TRIGGER_ACTION, payload: { actionNumber: number, deliveryId } });
    },
    [deliveryId]
  );

  const handleAddTrackingNumber = useCallback(() => {
    // TODO AC-673 open tracking modal either via trigger, or directly
  }, []);

  const handleDelivery = useCallback(() => {
    // TODO AC-673 handle delivered deliveries
  }, []);

  return (
    <>
      <span className="kt-user-card-v2__email mt-0" ref={overlayRef}>
        <button className="btn btn-sm btn-danger py-0 my-0" style={{ width: 110 }} onClick={handleShow}>
          {i18n.t("warehouse:arrived")}
        </button>
      </span>
      <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement="bottom-start">
        <Popover id="dropdown-popover">
          <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
            <span className="dropdown-item disabled">{i18n.t("warehouse:trackingNumber")}</span>
            <span className="dropdown-item disabled">{i18n.t("warehouse:delivered")}</span>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};
