import _ from "lodash";
import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";
import { OrdersDocument } from "../../../model/orders.types";
import dashboardUtils from "../../../utils/dashboardUtils";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import dateUtils from "../../../utils/dateUtils";
import { T_ORDERED } from "../../../utils/timelineUtils";

interface DataSet {
  label: string;
  data: Array<number>;
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
  yAxisID: string;
  borderDash?: Array<number>;
}

interface VisualizationData {
  labels: Array<string>;
  datasets: Array<DataSet>;
}

const DEFAULT_DATA: VisualizationData = {
  labels: [] as Array<string>,
  datasets: [
    {
      label: "Offers €",
      data: [] as Array<number>,
      fill: true,
      backgroundColor: "rgba(255,184,34,0.15)",
      borderColor: "rgba(255,184,34,1)",
      yAxisID: "left-y-axis"
    },
    {
      label: "Accepted €",
      data: [] as Array<number>,
      fill: true,
      backgroundColor: "rgba(10,187,135,0.25)",
      borderColor: "rgba(10,187,135,1)",
      yAxisID: "left-y-axis"
    },
    {
      label: "Lost €",
      data: [] as Array<number>,
      fill: false,
      backgroundColor: "rgba(255,98,67,0.15)",
      borderColor: "rgba(255,98,67,1)",
      yAxisID: "left-y-axis"
    },

    {
      label: "Margin %",
      data: [] as Array<number>,
      fill: false,
      backgroundColor: "rgba(255,255,255,0)",
      borderColor: "rgba(0,140,255,1)",
      borderDash: [10, 5],
      yAxisID: "right-y-axis"
    }
  ]
};

const OPTIONS = {
  scales: {
    yAxes: [
      {
        id: "left-y-axis",
        type: "linear",
        position: "left"
      },
      {
        id: "right-y-axis",
        type: "linear",
        position: "right",
        ticks: {
          suggestedMin: 0,
          suggestedMax: 50
        }
      }
    ]
  }
};

interface Period {
  monday: Date;
  sunday: Date;
  offers: number;
  accepted: number;
  lost: number;
  margin: number;
}

interface GeneralVisualizationProps {
  orders: Array<OrdersDocument>;
}

interface GeneralVisualizationState {}

class GeneralVisualization extends PureComponent<GeneralVisualizationProps, GeneralVisualizationState> {
  /**
   * Get initial periods
   * @returns {Array<Period>} list of periods
   */
  getInitialPeriods = (): Array<Period> => {
    let periods: Array<Period> = [];
    // Initialize periods, 12 weeks from monday 00:00:00 to sunday 23:59:59
    for (let i = 0; i < 12; i++) {
      const d = new Date(Date.now() - 12 * 7 * 24 * 60 * 60 * 1000 + (i + 1) * 7 * 24 * 60 * 60 * 1000);
      const [monday, sunday] = dateUtils.getMondaySunday(d);
      periods.push({
        monday: monday,
        sunday: sunday,
        offers: 0,
        accepted: 0,
        lost: 0,
        margin: 0
      });
    }
    return periods;
  };

  /**
   * Get all stats to visualize
   * @returns { VisualizationData } data object with all datasets and labels
   */
  getStats = (): VisualizationData => {
    const { orders } = this.props;
    const data = _.cloneDeep(DEFAULT_DATA);
    let periods: Array<Period> = this.getInitialPeriods();
    // Prepare period data
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (order.state === DECLINED) continue;
      const totalMargin = dashboardUtils.getTotalMargin(order);
      const totalTurnover = dashboardUtils.getTotalTurnover(order);
      const { createdOn, timeline } = order;
      for (let p = 0; p < periods.length; p++) {
        const period = periods[p];
        const mondayTime = period.monday.getTime();
        const sundayTime = period.sunday.getTime();
        const createdOnTime = createdOn.getTime();
        // Check if order was created within the period, can only be within one period
        if (createdOnTime >= mondayTime && createdOnTime <= sundayTime) {
          period.offers += totalTurnover;
          period.margin += totalMargin;
        }
        // TODO Discuss handling for lost orders AC-192
        const fourWeeks = 4 * 7 * 24 * 60 * 60 * 1000;
        const eightWeeks = 8 * 7 * 24 * 60 * 60 * 1000;
        if (orderUtils.isOrder(order)) {
          for (let j = 0; j < timeline.length; j++) {
            const timelineEntry = timeline[j];
            if (
              timelineEntry.type === T_ORDERED &&
              timelineEntry.date.getTime() >= mondayTime &&
              timelineEntry.date.getTime() <= sundayTime
            ) {
              period.accepted += totalTurnover;
            }
          }
        } else if (createdOnTime <= mondayTime - fourWeeks && createdOnTime >= mondayTime - eightWeeks) {
          period.lost += totalTurnover;
        }
      }
    }

    // Set data and labels
    let labels: Array<string> = [];
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      // total offer turnover
      data.datasets[0].data.push(Math.round(period.offers * 100) / 100);
      // total of accepted order turnovers
      data.datasets[1].data.push(Math.round(period.accepted * 100) / 100);
      // total of lost/declined turnover
      data.datasets[2].data.push(Math.round(period.lost * 100) / 100);
      // total margin
      const margin = (period.margin / period.offers) * 100;
      data.datasets[3].data.push(!isNaN(margin) ? Math.round(margin * 100) / 100 : 0);
      labels.push("KW" + dateUtils.getCW(period.monday));
    }
    data.labels = labels;
    return data;
  };

  render() {
    return (
      <div className="kt-portlet" style={{ height: "500px" }}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">General Stats</h3>
          </div>
        </div>
        <div className="kt-portlet__body overflow-auto">
          <Line data={this.getStats()} options={OPTIONS} />
        </div>
      </div>
    );
  }
}

export default GeneralVisualization;
