import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { DataContext } from "../../../context/dataContext";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { Action, ORDERS, PACKAGINGORDERS, PACKAGINGS, PACKAGINGSTOCK } from "../../../services/dbService";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import orderUtils from "../../../utils/orderUtils";
import { T_PACKAGINGDELIVERED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import DateInput from "../../common/DateInput";
import { PackagingsDocument } from "../../../model/packagings.types";
import { PackagingOrderDocument } from "../../../model/packagingOrders.types";
import packagingUtils from "../../../utils/packagingUtils";
import { PackagingStock } from "../../../model/packagingStock.types";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import slackService from "../../../services/slackService";

interface DeliverPackagingOrderModalProps {
  packaging: PackagingsDocument;
  packagingOrder: PackagingOrderDocument;
  context: React.ContextType<typeof DataContext>;
  buttonCSSClasses?: string;
}

interface DeliverPackagingOrderModalState {
  show: boolean;
  expiry: Date | null;
  delivery: Date;
  lot: string;
  note: string;
  saving: boolean;
}

class DeliverPackagingOrderModal extends PureComponent<
  DeliverPackagingOrderModalProps,
  DeliverPackagingOrderModalState
> {
  constructor(props: DeliverPackagingOrderModalProps) {
    super(props);
    this.state = this.getDefaultStateValues(props.packagingOrder);
  }

  componentDidUpdate(
    prevProps: Readonly<DeliverPackagingOrderModalProps>,
    prevState: Readonly<DeliverPackagingOrderModalState>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.packagingOrder, this.props.packagingOrder)) {
      this.setState(this.getDefaultStateValues(this.props.packagingOrder));
    }
  }

  /**
   * Get the default state values.
   * @returns { DeliverPackagingOrderModalState } Default state values
   */
  getDefaultStateValues = (pOrder: PackagingOrderDocument) => {
    return {
      show: false,
      expiry: pOrder.expiry ? pOrder.expiry : null,
      delivery: new Date(),
      lot: pOrder.lot ? pOrder.lot : "",
      note: "",
      saving: false
    };
  };

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState(this.getDefaultStateValues(this.props.packagingOrder));

  /**
   * Handles a change in input fields.
   * @param e: Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    let val: string | number | Date = e.target.value;
    if (name === "delivery") {
      val = new Date(val);
      if (isNaN(val.getTime())) return;
    } else if (name === "expiry" && val) {
      val = new Date(val);
    }
    // @ts-ignore
    this.setState({ [name]: val });
  };

  /**
   * Handles clicking the delivery button. Updates the packaging.
   */
  handleClickDeliver = async () => {
    const { packaging, packagingOrder, context } = this.props;
    if (packaging._id.toString() !== packagingOrder.packaging.toString()) {
      toast.error("Packaging is not the same. Please report.");
      await slackService.sendMessage(
        "#interne-fehlermeldungen",
        `Packaging ${packaging._id.toString()} differs from packaging in packaging order ${packagingOrder.packaging.toString()}. User: ${userService.getUserId()}`
      );
      return;
    }
    const { delivery, expiry, lot, note } = this.state;
    this.setState({ saving: true });
    // Build new batch
    const batch: PackagingStock = {
      packaging: packaging._id,
      supplier: new BSON.ObjectId(packagingOrder.supplier!.toString()),
      amount: packagingOrder.orderQuantity,
      person: userService.getUserId(),
      lot,
      location: new BSON.ObjectId(packagingOrder.destination!),
      note,
      price: packagingOrder.totalPrice / packagingOrder.orderQuantity,
      stocked: delivery,
      expiry: expiry,
      files: [],
      timeline: []
    };
    let success;
    let stockId: BSON.ObjectId | undefined;
    // Packaging order needs to be always updated
    const actions: Array<Action> = [
      {
        collection: PACKAGINGORDERS,
        filter: { _id: packagingOrder._id },
        update: { delivered: delivery, lot }
      }
    ];
    // If there are orders relating to that packaging order we need to update them too
    if (packagingOrder.relatedOrders.length > 0) {
      // Create packaging stock and update packaging order
      const timeline = {
        id: new BSON.ObjectId(),
        type: T_PACKAGINGDELIVERED,
        date: new Date(),
        amount: packagingOrder.orderQuantity,
        packaging: packaging._id,
        supplier: packagingOrder.supplier,
        person: userService.getUserId(),
        price: packagingOrder.totalPrice
      };
      // Update orders
      for (let i = 0; i < packagingOrder.relatedOrders.length; i++) {
        const order: OrdersDocument = baseUtils.getDocFromCollection(
          context.orders,
          packagingOrder.relatedOrders[i].toString()
        );
        if (order) {
          const calcPost = _.cloneDeep(order.calculations[0]);
          const pricePost = calcPost.packagings.find(p => p._id.toString() === packaging._id.toString())!;
          const pricePre = order.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString())!;
          const newUnitPrice = packagingOrder.totalPrice / packagingOrder.orderQuantity;
          pricePost.delivered = delivery;
          pricePost.userDelivered = userService.getUserId();
          pricePost.totalprice = newUnitPrice * pricePre.orderquantity!;
          pricePost.price = newUnitPrice;
          // Recalculate price information
          const newInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(order, calcPost);
          // Check if the state has to be updated
          const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
          actions.push({
            collection: ORDERS,
            filter: { _id: order._id },
            update: {
              "calculations.0.packagings.$[c].delivered": pricePost.delivered,
              "calculations.0.packagings.$[c].userDelivered": pricePost.userDelivered,
              "calculations.0.packagings.$[c].totalprice": pricePost.totalprice,
              "calculations.0.packagings.$[c].price": pricePost.price,
              "calculations.0.info": newInfo,
              state: newState ? newState : order.state
            },
            push: { timeline },
            arrayFilters: [{ "c._id": packaging._id }]
          });
        } else {
          throw Error(`Order ${packagingOrder.relatedOrders[i].toString()} could not be found. Aborting update`);
        }
      }
    }
    success = await dbService.callFunction("createPackagingOrderOrStock", [batch, actions, "stock"]);
    if (!!success) stockId = success as BSON.ObjectId;
    await toastUtils.databaseOperationToast(
      success,
      "Packaging order delivered successfully",
      "Error delivering packaging order",
      () => {
        context.updateDocumentInContext(PACKAGINGS, packaging._id);
        context.updateDocumentInContext(PACKAGINGORDERS, packagingOrder._id);
        if (stockId) context.updateDocumentInContext(PACKAGINGSTOCK, stockId);
        packagingOrder.relatedOrders.forEach(o => context.updateDocumentInContext(ORDERS, o));
      }
    );
    if (success) this.handleHide();
    else this.setState({ saving: false });
  };

  /**
   * Check the input values for errors.
   * @returns { Array<string> } Contains all errors
   */
  checkForErrors = () => {
    const { expiry, lot } = this.state;
    const errors = [];
    if (!lot || lot.length < 3) errors.push("LOT has to be at least 3 characters long");
    if (expiry && expiry.getTime() < new Date().getTime()) errors.push("Expiry has to be in the future");
    return errors;
  };

  render() {
    const { buttonCSSClasses, packaging } = this.props;
    const { delivery, expiry, lot, note, saving, show } = this.state;
    const errors = this.checkForErrors();
    const canDeliver = accessUtils.canPerformAction(ACTIONS.PACKAGINGDELIVER);

    return (
      <>
        <button
          className={(buttonCSSClasses ? buttonCSSClasses : "btn btn-success") + (canDeliver ? "" : " disabled")}
          onClick={canDeliver ? this.handleShow : undefined}
          style={{ minWidth: "72px" }}
          disabled={!canDeliver}
        >
          Deliver
        </button>
        <Modal show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Deliver Order of {packagingUtils.getShortPackagingInfo(packaging)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Delivery date</label>
              <div className="col-9">
                <DateInput value={delivery} max={new Date()} onBlur={this.handleChange} name={"delivery"} />
              </div>
            </div>
            <div>
              <span className="kt-font-bold kt-font-dark">
                Please enter some the following information for the batch.
              </span>
            </div>
            <div className="form-group row mt-5">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">LOT</label>
              <div className="col-9">
                <input
                  className="form-control"
                  type="text"
                  value={lot}
                  name="lot"
                  onChange={this.handleChange}
                  placeholder="LOT"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Expiry (optional)</label>
              <div className="col-9">
                <DateInput
                  value={expiry}
                  onBlur={this.handleChange}
                  name={"expiry"}
                  min={new Date()}
                  allowClear={true}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
              <div className="col-9">
                <textarea className="form-control" rows={3} value={note} name="note" onChange={this.handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            {errors.length > 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"packaging-invalid"}>
                    {errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-success disabled">Deliver</button>
              </OverlayTrigger>
            ) : (
              <button
                className={"btn btn-success" + (!canDeliver || saving ? " disabled" : "")}
                disabled={saving || !canDeliver}
                onClick={canDeliver ? this.handleClickDeliver : undefined}
              >
                Deliver
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeliverPackagingOrderModal;
