import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CustomOrder } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import orderUtils from "../../../utils/orderUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import OrderHelper from "../OrderHelper";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import OrderCommodities from "./materialPanels/OrderCommodities";
import OrderPackaging from "./materialPanels/OrderPackaging";
import OrderCommoditiesOrder from "./materialPanels/OrderCommoditiesOrder";
import OrderPackagingOrder from "./materialPanels/OrderPackagingOrder";
import OrderCommoditiesCheck from "./materialPanels/OrderCommoditiesCheck";
import OrderPackagingCheck from "./materialPanels/OrderPackagingCheck";
import { calculation, OrderState } from "../../../model/orders.types";
import { CommoditiesDocument } from "../../../model/commodities.types";
import { PackagingsDocument } from "../../../model/packagings.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import { T_OFFERPDF, T_OFFERRELEASED, T_REPORTPDF, T_REQUESTAPPROVED } from "../../../utils/timelineUtils";
import ReserveMaterialModal from "../modals/ReserveMaterialModal";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import notificationService, { R_ORDERFILEUPLOAD } from "../../../services/notificationService";
import calculationReportGeneration from "../../../utils/pdf/calculationReportGeneration";
import dateUtils from "../../../utils/dateUtils";
import AddAdditionalPackagingModal from "./materialPanels/AddAdditionalPackagingModal";

export enum OfferSaveEnum {
  APPROVE,
  RELEASE
}

interface OrderMaterialsProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface OrderMaterialsState {
  calculations: Array<calculation>;
  usedCommodities: Array<CommoditiesDocument>;
  usedPackaging: Array<PackagingsDocument>;
  usedSuppliers: Array<SuppliersDocument>;
  showReserveMaterialModal: boolean;
  reserveMaterialModalType: "commodity" | "packaging";
}

class OrderMaterials extends PureComponent<OrderMaterialsProps, OrderMaterialsState> {
  constructor(props: OrderMaterialsProps) {
    super(props);
    this.state = {
      calculations: this.getInitialCalculations(props),
      ...this.getUsedDocuments(props),
      showReserveMaterialModal: false,
      reserveMaterialModalType: "commodity"
    };
  }

  /**
   * Get all relevant documents for commodities, packaging and suppliers that are used in the order
   * @param props the components properties
   * @returns {usedCommodities: Array<CommoditiesDocument>, usedPackaging: Array<PackagingsDocument>, usedSuppliers: Array<SuppliersDocument>}
   *           commodities, packaging and suppliers used in the passed order
   */
  getUsedDocuments = (props: OrderMaterialsProps) => {
    const { order, context } = props;
    const { commodities, packagings, suppliers } = context;
    const calculation = order.calculations[0];
    const usedCommodities = calculation.prices.map(
      c => commodities.find(c2 => c._id.toString() === c2._id.toString())!
    );
    const usedPackaging = calculation.packagings.map(
      p => packagings.find(p2 => p._id.toString() === p2._id.toString())!
    );
    const usedSuppliers = suppliers.filter(
      s =>
        usedCommodities.some(c => c.suppliers.some(cs => cs._id.toString() === s._id.toString())) ||
        usedPackaging.some(p => p.suppliers.some(ps => ps._id.toString() === s._id.toString()))
    );
    return { usedCommodities, usedPackaging, usedSuppliers };
  };

  componentDidUpdate(prevProps: Readonly<OrderMaterialsProps>) {
    if (!_.isEqual(prevProps.order.calculations, this.props.order.calculations)) {
      const oldCalculations = this.getInitialCalculations(prevProps);
      const newCalculations = this.getInitialCalculations(this.props);
      if (!_.isEqual(oldCalculations, newCalculations) && !_.isEqual(this.state.calculations, newCalculations)) {
        if (prevProps.order._id.toString() === this.props.order._id.toString()) toast.info("Calculations were updated");
        this.setState({ calculations: newCalculations, ...this.getUsedDocuments(this.props) });
      }
    } else {
      const { usedCommodities, usedPackaging, usedSuppliers } = this.getUsedDocuments(this.props);
      const materialsDiffer =
        !_.isEqual(usedCommodities, this.state.usedCommodities) || !_.isEqual(usedPackaging, this.state.usedPackaging);
      if (materialsDiffer || !_.isEqual(usedSuppliers, this.state.usedSuppliers)) {
        if (materialsDiffer)
          toast.info(
            "Prices may have been updated in the background. Actual prices may differ from your current selection."
          );
        this.setState({ usedCommodities, usedSuppliers, usedPackaging });
      }
    }
  }

  /**
   * Get initial calculations with order quantity set
   * @param props order material props
   * @returns {Array<calculation>} list of calculations
   */
  getInitialCalculations = (props: OrderMaterialsProps) => {
    const { order } = props;
    const type = order.settings.type;
    const calculations = _.cloneDeep(order.calculations);
    calculations.forEach(c => {
      c.prices.forEach(price => {
        if (!price.orderquantity) {
          const totalAmount = orderUtils.getTotalAmountWithBuffer(
            +order.settings.perUnit,
            +c.units,
            price.amount,
            price.buffer,
            type
          );
          price.orderquantity = OrderHelper.getOrderQuantityForType(totalAmount, type);
        }
      });
      c.packagings.forEach(price => {
        if (!price.orderquantity) price.orderquantity = price.amount * +c.units;
      });
    });
    return calculations;
  };

  /**
   * Save calculations
   * @param type enum to indicate if order should be moved to request approved state or released
   * @param onSuccess success callback
   * @param finalCallback final callback
   */
  handleSaveCalculations = async (type?: OfferSaveEnum, onSuccess?: () => void, finalCallback?: () => void) => {
    const { order, context } = this.props;
    const { calculations } = this.state;
    let pdfOfferResult: any, pdfCalcReportResult: any;
    try {
      if (type === OfferSaveEnum.APPROVE) {
        pdfOfferResult = await pdfUtils.createOfferPDF(order, context, false, order.createdFrom);
        if (!pdfOfferResult.result || !pdfOfferResult.path) {
          toast.error("Offer PDF creation failed: " + pdfOfferResult.message);
          return;
        }
        const settings: any = _.clone(order.settings);
        settings.manufacturer = settings.manufacturer._id;
        settings.filler = order.settings.filler?._id.toString();
        const data = JSON.stringify({
          html: calculationReportGeneration.createCalculationProtocol(order.calculations, settings, context),
          fileName: "Report_AN-" + order.identifier + "_V1_" + dateUtils.timeStampDate() + ".pdf"
        });
        pdfCalcReportResult = await pdfUtils.uploadAndReturnPath(data);
        if (!pdfCalcReportResult) {
          toast.error("Calculation report creation failed");
          return;
        }
      }
      const timelineEntry: any[] = [
        {
          _id: new BSON.ObjectId(),
          type: type === OfferSaveEnum.RELEASE ? T_OFFERRELEASED : T_REQUESTAPPROVED,
          date: new Date(),
          person: userService.getUserId()
        }
      ];
      let result;
      if (type === OfferSaveEnum.APPROVE) {
        timelineEntry.push({
          _id: new BSON.ObjectId(),
          type: T_OFFERPDF,
          date: new Date(),
          path: pdfOfferResult.path,
          person: userService.getUserId(),
          version: orderUtils.getPDFVersion(order, T_OFFERPDF),
          offernumber: order.identifier
        });
        timelineEntry.push({
          _id: new BSON.ObjectId(),
          type: T_REPORTPDF,
          date: new Date(),
          path: pdfCalcReportResult,
          person: userService.getUserId(),
          version: orderUtils.getPDFVersion(order, T_REPORTPDF),
          offernumber: order.identifier
        });
        result = await dbOrderService.switchState(order._id, OrderState.OFFER_APPROVED, timelineEntry, {
          $set: { calculations },
          $inc: { version: 1 }
        });
      } else if (type === OfferSaveEnum.RELEASE) {
        result = await dbOrderService.switchState(order._id, OrderState.OFFER_RELEASED, timelineEntry, {
          $set: { calculations },
          $inc: { version: 1 }
        });
      } else result = await dbOrderService.updateCalculations(order._id, calculations);
      if (result && result.modifiedCount) {
        if (type === OfferSaveEnum.APPROVE) {
          toast.success("Offer and associated PDF successfully created");
          notificationService.notify(R_ORDERFILEUPLOAD, order._id, {
            de: ": Angebotsdokument",
            en: ": Offer document"
          });
        } else {
          toast.success("Offer successfully updated");
        }
        if (onSuccess) onSuccess();
      } else toast.error("Offer could not be updated");
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      if (finalCallback) finalCallback();
    }
  };

  /**
   * Exchange updated calculation and recalculate info stats
   * @param calculation the calculation to update
   * @param type either commodity or packaging to know what price needs to be recalculated for info stats
   * @param updatedPrice id of the updacted price
   */
  handleCalculationChange = (
    calculation: calculation,
    type: "commodity" | "packaging",
    updatedPrice: string | BSON.ObjectId
  ) => {
    const { order } = this.props;
    const calculations = _.cloneDeep(this.state.calculations);
    const newCalculation = calculations.find(c => c.id === calculation.id);
    if (!newCalculation) return;
    // Update price and calculation info
    let newCalculationInfo;
    if (type === "commodity") {
      const newPrice = calculation.prices.find(p => p._id.toString() === updatedPrice.toString());
      const priceIndex = newCalculation.prices.findIndex(p => p._id.toString() === updatedPrice.toString());
      if (newPrice && priceIndex >= 0) newCalculation.prices.splice(priceIndex, 1, newPrice);
      newCalculationInfo = orderCalculationUtils.recalculateInfoOnCommodityChanges(order, newCalculation);
    } else if (type === "packaging") {
      const newPrice = calculation.packagings.find(p => p._id.toString() === updatedPrice.toString());
      const priceIndex = newCalculation.packagings.findIndex(p => p._id.toString() === updatedPrice.toString());
      if (newPrice && priceIndex >= 0) newCalculation.packagings.splice(priceIndex, 1, newPrice);
      newCalculationInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(order, newCalculation);
    }
    if (!newCalculationInfo) {
      toast.error("Calculation info could not be recalculated, therefore calculations could not be updated");
      return;
    }
    newCalculation.info = newCalculationInfo;
    const index = calculations.findIndex(c => c.id.toString() === newCalculation.id.toString());
    calculations.splice(index, 1, newCalculation);
    this.setState({ calculations });
  };

  /**
   * Set the requested or updated flags of all associated prices
   * @param type indicator for requested or updated
   * @param id the id of the commodity/packaging price to update
   * @param calculationType commodity or packaging
   */
  handleFlagChange = (
    type: "requested" | "updated",
    id: BSON.ObjectId | string,
    calculationType: "commodity" | "packaging"
  ) => {
    const calculations = _.cloneDeep(this.state.calculations);
    let val = true;
    calculations.forEach(c => {
      if (calculationType === "commodity") val = val && !!c.prices.find(p => p._id.toString() === id.toString())![type];
      else if (calculationType === "packaging")
        val = val && !!c.packagings.find(p => p._id.toString() === id.toString())![type];
    });
    calculations.forEach(c => {
      let price;
      if (calculationType === "commodity") price = c.prices.find(p => p._id.toString() === id.toString());
      else if (calculationType === "packaging") price = c.packagings.find(p => p._id.toString() === id.toString());
      if (price) {
        _.set(price, type, !val);
      }
    });
    this.setState({ calculations });
  };

  handleShowReserveMaterialModal = (reserveMaterialModalType: "commodity" | "packaging") => {
    this.setState({ showReserveMaterialModal: true, reserveMaterialModalType });
  };

  handleHideReserveMaterialModal = () => {
    this.setState({ showReserveMaterialModal: false });
  };

  render() {
    const { context, order } = this.props;
    const {
      calculations,
      usedSuppliers,
      usedPackaging,
      usedCommodities,
      showReserveMaterialModal,
      reserveMaterialModalType
    } = this.state;
    const showBasicPanels = [
      OrderState.OFFER,
      OrderState.OFFER_APPROVED,
      OrderState.OFFER_RELEASED,
      OrderState.ARCHIVE,
      OrderState.DECLINED,
      OrderState.PRODUCTION_QUEUE,
      OrderState.PRODUCTION,
      OrderState.FULFILLMENT,
      OrderState.CREATE_INVOICE,
      OrderState.CONTRACT
    ].includes(order.state);
    const showOrderPanels = [OrderState.ORDER_COMMODITIES, OrderState.WAITING].includes(order.state);
    const showMaterialCheckPanels = order.state === OrderState.OFFER_PENDING;
    const allowReserve = [
      OrderState.ORDER_COMMODITIES,
      OrderState.WAITING,
      OrderState.PRODUCTION_QUEUE,
      OrderState.PRODUCTION
    ].includes(order.state);
    return (
      <>
        {allowReserve && (
          <ReserveMaterialModal
            order={order}
            type={reserveMaterialModalType}
            onHide={this.handleHideReserveMaterialModal}
            show={showReserveMaterialModal}
            context={context}
          />
        )}
        <div className="d-flex">
          <h4 className="kt-font-dark">Commodities</h4>
          {allowReserve && (
            <button
              className="btn btn-sm btn-secondary ml-auto"
              onClick={() => this.handleShowReserveMaterialModal("commodity")}
            >
              Allocate Commodities
            </button>
          )}
        </div>
        <hr />
        {showBasicPanels && <OrderCommodities order={order} commodities={usedCommodities} suppliers={usedSuppliers} />}
        {showOrderPanels && (
          <OrderCommoditiesOrder order={order} commodities={usedCommodities} suppliers={usedSuppliers} />
        )}
        {showMaterialCheckPanels && (
          <OrderCommoditiesCheck
            order={order}
            commodities={usedCommodities}
            suppliers={usedSuppliers}
            calculations={calculations}
            onFlagChange={(type, id) => this.handleFlagChange(type, id, "commodity")}
            onCalculationChange={(calculation, priceId) =>
              this.handleCalculationChange(calculation, "commodity", priceId)
            }
            context={context}
          />
        )}
        <div className="d-flex">
          <h4 className="kt-font-dark">Packaging</h4>
          <span className="ml-auto">
            {[
              OrderState.CONTRACT,
              OrderState.ORDER_COMMODITIES,
              OrderState.WAITING,
              OrderState.PRODUCTION_QUEUE
            ].includes(order.state) && <AddAdditionalPackagingModal order={order} context={context} />}
            {allowReserve && (
              <button
                className="btn btn-sm btn-secondary ml-2"
                onClick={() => this.handleShowReserveMaterialModal("packaging")}
              >
                Allocate Packaging
              </button>
            )}
          </span>
        </div>
        <hr />
        {showBasicPanels && <OrderPackaging order={order} packaging={usedPackaging} suppliers={usedSuppliers} />}
        {showOrderPanels && <OrderPackagingOrder order={order} packaging={usedPackaging} suppliers={usedSuppliers} />}
        {showMaterialCheckPanels && (
          <OrderPackagingCheck
            order={order}
            packaging={usedPackaging}
            suppliers={usedSuppliers}
            calculations={calculations}
            onFlagChange={(type, id) => this.handleFlagChange(type, id, "packaging")}
            onCalculationChange={(calculation, priceId) =>
              this.handleCalculationChange(calculation, "packaging", priceId)
            }
            context={context}
          />
        )}
      </>
    );
  }
}

export default OrderMaterials;
