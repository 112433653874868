import React, { useEffect, useMemo } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "../../../translations/i18n";
import { resolveTranslation } from "../../../utils/translationUtils";
import { BatchInput } from "../modals/CreateStockModal";
import {
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition,
  WarehouseTypes
} from "../../../model/configuration/warehouseConfiguration.types";
import { CommoditiesDocument } from "../../../model/commodities.types";
import baseUtils from "../../../utils/baseUtils";
import Tooltip from "../../common/Tooltip";

interface AddStorageSpaceOnBookInProps {
  batches: Array<BatchInput>;
  content: CommoditiesDocument;
  warehouse: WarehouseDefinition;
  warehouseArea: PhysicalWarehouse;
  onChangeStorageSpace: (batchId: string, storageSpace: any) => void;
}

const AddStorageSpaceOnBookIn: React.FunctionComponent<AddStorageSpaceOnBookInProps> = ({
  batches,
  content,
  warehouse,
  warehouseArea,
  onChangeStorageSpace
}) => {
  useEffect(() => {
    for (let i = 0; i < batches.length; i++)
      onChangeStorageSpace(batches[i]._id.toString(), {
        value: "",
        label: i18n.t("warehouse:incomingTab"),
        storageSpace: undefined
      });
  }, []);

  const storageSpaces = useMemo(() => {
    const stSpArray: Array<{ value: string; label: string; storageSpace: StorageSpace | undefined }> = [
      { value: "", label: i18n.t("warehouse:incomingTab"), storageSpace: undefined }
    ];
    warehouseArea.storageSpaces?.map(stSp => {
      stSpArray.push({
        value: stSp._id.toString(),
        label: stSp.storageSpaceNo,
        storageSpace: stSp
      });
    });
    return stSpArray;
  }, [warehouseArea]);

  return (
    <>
      <div className="row">
        <div className="col-12 kt-font-bold kt-font-dark font-size-lg mt-2">
          <Tooltip
            tooltipText={
              <p>
                <b>{resolveTranslation(content.title)}</b> {resolveTranslation(content.subtitle)}
              </p>
            }
          >
            <span>
              {baseUtils.truncateString(resolveTranslation(content.title), 25)}&nbsp;
              <small>{baseUtils.truncateString(resolveTranslation(content.subtitle), 25)}</small>
            </span>
          </Tooltip>
        </div>
      </div>
      {batches.map((batchEntry, index) => {
        return (
          <div className="col-12 px-1 mt-2" key={batchEntry._id.toString()}>
            {index !== 0 && <hr className="w-100" />}
            {batchEntry.existingBatch && (
              <div className="alert alert-warning mt-2" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-warning" />
                </div>
                <div className="alert-text"> {i18n.t("warehouse:onlyNewToStSp")}</div>
              </div>
            )}
            <small>
              {i18n.t("warehouse:lot")}: {batchEntry.lot}
            </small>
            <div className="input-group input-group-solid">
              <div className="input-group-prepend" style={{ width: "30%" }}>
                <input
                  type="number"
                  className="form-control form-control-solid border-0 disabled"
                  value={batchEntry.packagingInputs.reduce((sum, a) => +a.quantityPUs + sum, 0)}
                  disabled
                />
                <span className="input-group-text input-group-solid-dark border-0">
                  {i18n.t("warehouse:packagingUnitAbbreviation")}
                </span>
              </div>
              <span style={{ width: "60%" }}>
                <Typeahead
                  id="storageSpace"
                  inputProps={{ className: "form-control form-control-solid text-black w-100" }}
                  labelKey="label"
                  onChange={selected => onChangeStorageSpace(batchEntry._id.toString(), selected)}
                  options={
                    warehouseArea.storageSpaces
                      ? storageSpaces
                      : [{ value: "", label: i18n.t("warehouse:incomingTab"), storageSpace: undefined }]
                  }
                  disabled={warehouseArea.type === WarehouseTypes.REMOTE}
                  placeholder={i18n.t("warehouse:storageSpace")}
                />
              </span>
              <div className="input-group-append justify-content-center flex-grow-1" style={{ width: "10%" }}>
                <span className="input-group-text border-0 flex-grow-1 input-group-solid-dark">
                  <i className="fas fa-exclamation-triangle triangle-muted" />
                </span>
              </div>
            </div>
          </div>
        );
      })}
      <div className="col-12">
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 mb-2">
          <span className="text-black">{i18n.t("warehouse:atTheMoment")}:</span>&nbsp;&nbsp;&nbsp;
          <li className="breadcrumb-item text-black">{resolveTranslation(warehouse.warehouseName)}</li>
          <li className="breadcrumb-item text-black">{resolveTranslation(warehouseArea.warehouseName)}</li>
          <li className="breadcrumb-item text-danger font-weight-bolder arrow">{i18n.t("warehouse:incomingTab")}</li>
        </ul>
      </div>
    </>
  );
};

export default AddStorageSpaceOnBookIn;
