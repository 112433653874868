import React, { useMemo } from "react";
import Select from "react-select";
import i18n from "../../../../translations/i18n";
import { Input } from "../../../common/Input";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { Batch } from "../../../../model/warehouse/batch.types";
import { BasicSelectOption } from "../../../../model/common.types";

interface SendModalConfirmationProps {
  selectedBatch: Batch;
  amount: string;
  selectedDestination?: BasicSelectOption;
}

const SendModalConfirmation: React.FC<SendModalConfirmationProps> = ({
  selectedBatch,
  amount,
  selectedDestination
}) => {
  const { product, productType } = useMemo(() => {
    return {
      product: {
        value: selectedBatch.content.details._id.toString(),
        label: resolveTranslation(selectedBatch.content.details.title)
      },
      productType: { value: selectedBatch.content.type, label: i18n.t(`warehouse:${selectedBatch.content.type}`) }
    };
  }, [selectedBatch]);

  const batchSelect = { value: selectedBatch._id.toString(), label: selectedBatch.lot };

  return (
    <div className="px-2">
      <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
        {i18n.t("common:summary")}
      </span>
      <hr className="w-100" />
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:productType")}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              value={productType}
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t(`warehouse:${productType.value}`)}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select className="select-warehouse" classNamePrefix="select-warehouse" value={product} isDisabled={true} />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:batch")}
          </span>
        </div>
        <div className="col-8">
          <div className="not-allowed-cursor">
            <Select
              className="select-warehouse"
              classNamePrefix="select-warehouse"
              isDisabled={true}
              value={batchSelect}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:amount")}
          </span>
        </div>
        <div className="col-8">
          <div className="input-group">
            <Input
              type="number"
              className="form-control form-control-solid hide-arrows disabled not-allowed"
              style={{ opacity: 0.65 }}
              value={amount}
              disabled={true}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid" style={{ opacity: 0.65 }}>
                {selectedBatch.totalAmount.unit || i18n.t("warehouse:units")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <span className="  text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t(`warehouse:destination`)}
          </span>
        </div>
        <div className="col-8">
          <Select
            className="select-warehouse"
            classNamePrefix="select-warehouse"
            value={selectedDestination}
            isDisabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SendModalConfirmation;
