import { Document, Model } from "mongoose";
import { BSON } from "realm-web";

export enum RequestState {
  REQUEST = "request",
  CACHED_REQUEST = "cached_request"
}

export interface Requests {
  state: RequestState;
  createdOn: Date;
  createdFor: BSON.ObjectId;
  createdFrom: BSON.ObjectId | null;
  title: string;
  subtitle: string;
  priority: string;
  identifier: string;
  recipe: Array<pricing>;
  settings: {
    type: string;
    perUnit: number;
    id: BSON.ObjectId;
    manufacturer: BSON.ObjectId | null;
  };
  calculations: [
    {
      id: string;
      units: number;
      prices: Array<pricing>;
      packagings: Array<pricing>;
    }
  ];
  contract: Array<ContractData> | null;
  timeline: [any];
}

interface pricing {
  _id: BSON.ObjectId;
  amount: number;
  buffer: number;
}

export interface ContractData {
  id: BSON.ObjectId;
  value: number;
  date: Date;
}

export interface RequestsDocument extends Requests, Document {}

export interface RequestsModel extends Model<RequestsDocument> {}
