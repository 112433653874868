import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import ChangeBBDView from "../common/ChangeBBDView";
import { ActionTrigger, BaseActionModalProps } from "../../../model/warehouse/common.types";
import dbService, { BATCH } from "../../../services/dbService";
import { getBatchFileEntry } from "../../../utils/batchUtils";
import { BatchFile } from "../../../model/warehouse/batch.types";
import toastUtils from "../../../utils/toastUtils";
import { SelectedEntryType, useWarehouseContext } from "../../../context/warehouseContext";
import { DataContextType, useDataContext } from "../../../context/dataContext";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";
import fileUtils from "../../../utils/fileUtils";
import baseUtils from "../../../utils/baseUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { getBatchIdFromSelectedEntries } from "../../../utils/warehouseActionUtils";

interface ChangeBbdModalState {
  bbd: Date;
  bbdComment: string;
  files: Array<BatchFile>;
}

const getDefaultState = (
  show: boolean,
  dataContext: DataContextType,
  selectedEntries: Array<SelectedEntryType>,
  actionTrigger: ActionTrigger | undefined
): ChangeBbdModalState => {
  // parent batch from selected location
  const batchEntry =
    show && (selectedEntries.length > 0 || actionTrigger?.batchId)
      ? dataContext.batch.find(
          b => b._id.toString() === (actionTrigger?.batchId || getBatchIdFromSelectedEntries(selectedEntries))
        )
      : undefined;
  if (batchEntry) {
    return {
      bbd: batchEntry.expiry,
      bbdComment: "",
      files: batchEntry.files
    };
  } else {
    return {
      bbd: new Date(),
      bbdComment: "",
      files: []
    };
  }
};

const ChangeBbdModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { batch: batches, updateDocumentInContext } = dataContext;
  const { selectedEntries } = warehouseContext;

  const [state, setState] = useState(getDefaultState(show, dataContext, selectedEntries, actionTrigger));
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    setState(getDefaultState(show, dataContext, selectedEntries, actionTrigger));
  }, [show, batches, selectedEntries, actionTrigger]);

  const errors = useMemo(() => {
    const newErrors = [];
    const batch =
      selectedEntries.length > 0
        ? batches.find(b => b._id.toString() === getBatchIdFromSelectedEntries(selectedEntries))
        : undefined;
    if (batch && state.bbd === batch.expiry) newErrors.push(i18n.t("warehouse:bbdNotChanged"));
    if (state.bbdComment === "") newErrors.push(i18n.t("warehouse:addCommentPlaceholder"));
    return newErrors;
  }, [state, batches, selectedEntries]);

  const handleHide = () => {
    if (saving) return;
    onHide();
  };

  const handleChangeDates = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, bbd: new Date(value) };
    });
  }, []);

  const handleChangeBBDComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value;
    setState(prevState => {
      return { ...prevState, bbdComment: newValue };
    });
  }, []);

  const handleFileUpload = useCallback(
    (file: File, title: string, type: FileType) => {
      const changedFiles = _.cloneDeep(state.files);
      const fileType = fileUtils.getFileExtension(file.name);
      const fileName = baseUtils.encodeString(title) + "." + fileType;

      const path = fileUtils.uploadFile(file, fileName);
      if (path) {
        const batchFile = getBatchFileEntry(path, file, type);
        changedFiles.push(batchFile);

        setState(prevState => {
          return { ...prevState, files: changedFiles };
        });
      } else {
        toast.error(i18n.t("common:errorFileUpload"));
      }
    },
    [state.files]
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => {
      const newFiles = state.files.filter(f => f._id.toString() !== fileId);
      setState(prevState => {
        return { ...prevState, files: newFiles };
      });
    },
    [state.files]
  );

  const handleSave = useCallback(async () => {
    const batchId =
      actionTrigger?.batchId ||
      (selectedEntries.length > 0 ? getBatchIdFromSelectedEntries(selectedEntries) : undefined);
    if (!batchId) {
      toast.error(i18n.t("warehouse:adjustBBDFail"));
      return;
    }
    setSaving(true);
    try {
      const res = await dbService.callFunction<boolean>(
        "changeBBD",
        [batchId, state.bbd, state.bbdComment, state.files],
        true
      );
      await toastUtils.databaseOperationToast(
        res,
        i18n.t("warehouse:adjustBBDSuccess"),
        i18n.t("warehouse:adjustBBDFail"),
        () => {
          updateDocumentInContext(BATCH, batchId);
          onHide();
        }
      );
    } finally {
      setSaving(false);
    }
  }, [state, selectedEntries, actionTrigger]);

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:addNewBbd")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-5 font-weight-bold text-black">{i18n.t("warehouse:addNewBbd")}</h4>
          <ChangeBBDView
            bbd={state.bbd}
            comment={state.bbdComment}
            files={state.files}
            onChangeBBD={handleChangeDates}
            onChangeComment={handleChangeBBDComment}
            onUploadFile={handleFileUpload}
            onRemoveFile={handleRemoveFile}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={saving}
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={handleHide}
        >
          {i18n.t("common:close")}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className="btn btn-success"
          errors={errors}
          saving={saving}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeBbdModal;
