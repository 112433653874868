import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { Delivery, OutgoingSnapshot } from "../../../../model/warehouse/delivery.types";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import {
  getFormattedPackagingUnitAmount,
  getFormattedPackagingUnitTotalAmount,
  SelectedDeliveryEntryType
} from "../../../../utils/warehouseUtils";
import baseUtils, { formatNumValueLocale } from "../../../../utils/baseUtils";
import { NumValue } from "../../../../model/common.types";
import { PackagingUnit } from "../../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { DeliveryContextMenu } from "../common/DeliveryContextMenu";
import { FileContextMenu } from "../../common/FileContextMenu";

interface DefaultDeliveryRowProps {
  delivery: Delivery;
}

const DefaultDeliveryRow: React.FC<DefaultDeliveryRowProps> = ({ delivery }) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const { selectedEntries } = warehouseContext;

  const [showDetails, setShowDetails] = useState(false);

  const uniqueCommoditiesText = useMemo(() => {
    const uniqueCommodities = Array.from(
      new Set(delivery.deliveries.map(o => o.batch.content.details._id.toString()))
    ).length;
    return `${uniqueCommodities} ${
      uniqueCommodities > 1 ? i18n.t("warehouse:rawMaterials") : i18n.t("warehouse:rawMaterial")
    }`;
  }, [delivery.deliveries]);

  const totalAmounts = useMemo(() => {
    const valueUnitMap = delivery.deliveries.reduce((map, o) => {
      const mapCopy = { ...map };
      if (o.movedAmount.unit in map) mapCopy[o.movedAmount.unit] += o.movedAmount.value;
      else mapCopy[o.movedAmount.unit] = o.movedAmount.value;
      return mapCopy;
    }, {} as { [unit: string]: number });

    const valueList: Array<NumValue> = Object.entries(valueUnitMap).map(([unit, value]) => ({
      value,
      unit
    }));
    return valueList.reduce(
      (str, val, idx) =>
        str +
        formatNumValueLocale(val) +
        (idx === valueList.length - 1 ? "" : idx === valueList.length - 2 ? " & " : ", "),
      ""
    );
  }, [delivery.deliveries]);

  const uniqueDestinations = useMemo(() => {
    return Array.from(new Set(delivery.deliveries.map(d => d.destination.address.name)));
  }, [delivery.deliveries]);

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry => entry.type === SelectedDeliveryEntryType.DELIVERY && entry.deliveryId === delivery._id.toString()
      ),
    [delivery, selectedEntries]
  );

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  const handleCheckbox = useCallback(() => {
    dispatch({
      type: WarehouseActionType.SELECT_DELIVERY_ENTRY,
      payload: {
        type: SelectedDeliveryEntryType.DELIVERY,
        deliveryId: delivery._id.toString()
      }
    });
  }, [delivery]);

  const handleShowDeliveryNoteSettingsModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // TODO AC-671 open delivery modal for editing existing delivery note
  }, []);

  const handleDeleteDocument = useCallback(() => {
    // TODO AC-671 delete delivery note
  }, []);

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding table-hover" onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <input
                type="checkbox"
                className="ml-0 kt-checkbox--solid"
                checked={checked}
                onClick={e => e.stopPropagation()}
                onChange={handleCheckbox}
              />
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black">TA-{delivery.identifier}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black">{uniqueCommoditiesText}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{totalAmounts}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black">
                {uniqueDestinations.length === 1 ? baseUtils.truncateString(uniqueDestinations[0], 20) : "VAR"}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell" onClick={e => e.stopPropagation()} style={{ width: 75 }}>
          <div className="kt-user-card-v2">
            <button className="btn btn-sm btn-text p-0 my-0" onClick={handleShowDeliveryNoteSettingsModal}>
              <i className="flaticon2-settings opacity-50 py-1 px-1 pr-2"></i>
            </button>
            <FileContextMenu file={delivery.deliveryNote} onDeleteDocument={handleDeleteDocument} />
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-danger">
                {delivery.deliveryNote ? baseUtils.formatDate(delivery.deliveryNote.date) : "-"}
              </span>
            </div>
          </div>
        </td>
        {delivery.delivery ? (
          <td className="kt-datatable__cell d-table-cell">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name text-danger">{baseUtils.formatDate(delivery.delivery)}</span>
              </div>
            </div>
          </td>
        ) : (
          <td className="kt-datatable__cell d-table-cell" onClick={e => e.stopPropagation()}>
            <DeliveryContextMenu deliveryId={delivery._id.toString()} />
          </td>
        )}
      </tr>
      {showDetails && (
        <tr className={"kt-datatable__row d-table-row "}>
          <td colSpan={10} className="px-0">
            {delivery.deliveries.length > 0 && <DefaultDeliveryTable delivery={delivery} />}
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultDeliveryTableProps {
  delivery: Delivery;
}

const DefaultDeliveryTable: React.FC<DefaultDeliveryTableProps> = ({ delivery }) => {
  const headerDefinition = useMemo(
    () => [
      { title: i18n.t("warehouse:rawMaterial"), size: 40 },
      { title: i18n.t("warehouse:amount"), size: 15 },
      { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 15 },
      { title: i18n.t("warehouse:destination"), size: 30 }
    ],
    []
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
      <table className="kt-datatable__table d-table p-5 bg-light">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map((def, idx) => (
              <th key={idx} className="kt-datatable__cell d-table-cell" style={{ width: `${def.size}%` }}>
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {delivery.deliveries.map(o => (
            <DefaultDeliveryDetailRows key={o._id.toString()} outgoing={o} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DefaultDeliveryDetailRowsProps {
  outgoing: OutgoingSnapshot;
}

const DefaultDeliveryDetailRows: React.FC<DefaultDeliveryDetailRowsProps> = ({ outgoing }) => {
  return (
    <>
      {outgoing.movedPackagingUnits.map(pU => (
        <DefaultDeliveryDetailRow key={pU._id.toString()} outgoing={outgoing} packagingUnit={pU} />
      ))}
    </>
  );
};

interface DefaultDeliveryDetailRowProps {
  outgoing: OutgoingSnapshot;
  packagingUnit: PackagingUnit;
}

const DefaultDeliveryDetailRow: React.FC<DefaultDeliveryDetailRowProps> = ({ outgoing, packagingUnit }) => {
  const formattedPU = useMemo(() => {
    return getFormattedPackagingUnitAmount(packagingUnit, true);
  }, [packagingUnit]);

  const totalAmount = useMemo(() => {
    return getFormattedPackagingUnitTotalAmount(packagingUnit);
  }, [packagingUnit]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {baseUtils.truncateString(resolveTranslation(outgoing.batch.content.details.title), 30)}&nbsp;
              <small className="kt-user-card-v2__email mt-0">
                {baseUtils.truncateString(resolveTranslation(outgoing.batch.content.details.subtitle), 30)}
              </small>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">{totalAmount}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">{formattedPU}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black">{outgoing.destination.address.name}</span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default DefaultDeliveryRow;
