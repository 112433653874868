import { Document, Model } from "mongoose";
import { BSON } from "realm-web";
import { CommodityBatchFile } from "./commodities.types";
import { PackagingBatchFile } from "./packagingStock.types";
import { CapsuleSupplierType } from "./capsules.types";

export enum OrderState {
  OFFER = "offer",
  OFFER_PENDING = "request_pending",
  OFFER_APPROVED = "request_approved",
  OFFER_RELEASED = "offer_released",
  ORDER_COMMODITIES = "order_order-commodities",
  WAITING = "waiting",
  PRODUCTION_QUEUE = "productionqueue",
  PRODUCTION = "production",
  FULFILLMENT = "fulfillment",
  CREATE_INVOICE = "create_invoice",
  ARCHIVE = "archive",
  DECLINED = "declined",
  CONTRACT = "contract"
}

export interface Orders {
  state: OrderState;
  createdOn: Date;
  createdFrom: BSON.ObjectId;
  createdFor: BSON.ObjectId;
  title: string;
  subtitle: string;
  priority: string;
  identifier: number | string;
  offerIdentifier?: string; // for new orders required!
  version: number;
  note: string;
  recipe: Array<{
    id: BSON.ObjectId;
    amount: number;
    buffer: number;
  }>;
  settings: Setting;
  calculations: [calculation];
  timeline: Array<any>;
  delivery: Date | null;
  usedBatches: [UsedBatch] | null;
  usedPackagingBatches?: [UsedPackagingBatch] | null;
  fulfillment: Fulfillment | null;
  contract: Array<ContractCall> | null;
  contractInformation: { contractId: BSON.ObjectId | string; callId: BSON.ObjectId | string } | null;
  targetDate: Date | null;
  product: BSON.ObjectId | null;
  reorder: boolean;
  productionWeek?: string | null;
  invoices?: Array<Invoice>;
  customerNotes?: Array<CustomerNote>;
  files?: Array<OrderFile>;
}

export interface CustomerNote {
  _id: BSON.ObjectId;
  date: Date;
  person: BSON.ObjectId;
  note: string;
  readDate?: Date;
  referencedNote?: BSON.ObjectId;
}

export interface Invoice {
  // String for backwards compatibility. Should always be OID
  _id: string | BSON.ObjectId;
  invoiceNumber: string | number;
  // If set a cancelation was created
  cancelation?: Cancelation;
  // If set this is a partial invoice
  partial: boolean;
  state: "open" | "partlyPaid" | "paid" | "canceled";
  deliveryDate: Date;
  invoiceDate: Date;
  person: BSON.ObjectId;
  path: string;
  pdfData: {
    customer: string;
    address: {
      street: string;
      additionalAddress: string;
      zip: string;
      city: string;
      country: string;
    };
    content: PDFContent;
  };
  reverseCharge: boolean;
  vatID: string;
  positions: Array<Position>;
  total: number;
  totalGross: number;
  payments: Array<{
    // String for backwards compatibility. Should always be OID
    _id: string | BSON.ObjectId;
    amount: number;
    date: Date;
    note: string;
    // After partial payments a new invoice with the remaining amount is generated
    path?: string;
  }>;
  // Reminder can be sent without interests, with increasing level interests are coming!
  reminder: Array<Reminder>;
  // Payment in advance can be detected by "dueIn: -1"
  dueIn: number;
  bioNumber: string;
}

export interface Position {
  // String for backwards compatibility. Should always be OID
  _id: string | BSON.ObjectId;
  // Services and reminder should be separate positions
  type: "position" | "service" | "freeposition" | "reminder";
  description: string;
  amount: number;
  unit: string;
  price: number;
  total: number;
  vat: number;
  discount: number;
}

export interface PDFContent {
  title: string;
  subtitle?: string;
  header: string;
  paymentInfo: string;
  footer: string;
  language?: string;
}

export interface Cancelation {
  cancelationNumber: number;
  date: Date;
  content: PDFContent;
  path: string;
  person: BSON.ObjectId;
}

export interface Reminder {
  _id: BSON.ObjectId;
  date: Date;
  level: number;
  interest: number;
  content: PDFContent;
  path: string;
  person: BSON.ObjectId;
  position?: {
    _id: BSON.ObjectId;
    description: string;
    price: number;
    total: number;
  };
}

export interface ContractCall {
  _id: BSON.ObjectId;
  called: Date | null;
  date: Date | null;
  value: number;
  finished?: Date | null;
  output?: number;
}
export interface Setting {
  type: string;
  perUnit: number;
  id: BSON.ObjectId | "";
  manufacturer: BSON.ObjectId;
  filler?: string;
  productionMachine?: BSON.ObjectId | null;
  bulk?: boolean;
}

export interface Fulfillment {
  lot: string;
  exp: Date;
  shelfLife?: number; // shelf life of the finished product in months
  shippingNote: string;
  shippingGroups: Array<{
    number: number;
    id: string;
    boxes: number;
    items: number;
    weight: number;
  }>;
  productionReportApproval?: {
    required: boolean;
    approvedBy?: string;
    approvalDate?: Date;
  };
  priceInfo?: FulfillmentPriceInfo;
  totalUnits?: number;
}

export interface FulfillmentPriceInfo {
  unitPrice: number;
  unitPriceNaked: number;
  unitMargin: number;
  totalPrice: number;
  totalMargin: number;
  percentMargin: number;
  marginBuffer?: number;
}

export interface pricing {
  _id: BSON.ObjectId;
  amount: number;
  estimatedprice: number;
  supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock";
  orderquantity: number | null;
  price: number;
  totalprice: number | null;
  requested: boolean | null;
  updated: boolean | null;
  ordered: Date | null;
  userOrdered?: BSON.ObjectId | null;
  delivered: Date | null;
  userDelivered?: BSON.ObjectId | null;
  viaWarehouse?: boolean;
  arrivedAtWarehouse?: Date | null;
  eta: Date | null;
  auto: boolean | "approved";
  deliverytime: number;
  buffer: number;
  priceAdjustment?: number | string;
  delivery: string;
}

export interface calculation {
  id: string;
  units: number;
  prices: Array<pricingCommodities>;
  margin: number;
  info: calculationInfo;
  packagings: Array<pricing>;
}

export interface calculationInfo {
  unitprice: number;
  unitpricenaked: number;
  unitmargin: number;
  totalprice: number;
  percentmargin: number;
  marginBuffer?: number;
  totalmargin: number;
  customCalculation?: CustomCalculationInfo;
  standardCalculation?: StandardCalculationInfo;
}

export interface StandardCalculationInfo {
  bottling?: CalculationManufacturerPrice;
  encapsulation?: CalculationManufacturerPrice;
  tableting?: CalculationManufacturerPrice;
  blistering?: CalculationManufacturerPrice;
  blending?: CalculationManufacturerPrice;
  capsule?: CapsuleCalculationPrice;
}

export interface CustomCalculationInfo {
  bottling?: CustomCalculationManufacturerPrice;
  encapsulation?: CustomCalculationManufacturerPrice;
  tableting?: CustomCalculationManufacturerPrice;
  blistering?: CustomCalculationManufacturerPrice;
  blending?: CustomCalculationManufacturerPrice;
  optionalCosts?: number;
  note: string;
}

export interface CalculationManufacturerPrice {
  price: number;
  unitPrice: number;
}
export interface CustomCalculationManufacturerPrice extends CalculationManufacturerPrice {
  manufacturerId: BSON.ObjectId;
}

export interface CapsuleCalculationPrice extends CalculationManufacturerPrice {
  auto?: boolean;
  buffer: number;
  totalAmount: number;
  estimatedPrice: number;
  supplier: string;
  supplierType?: CapsuleSupplierType;
  moq: number;
  deliveryTime?: number;
  totalPrice: number;
}

export interface pricingCommodities extends pricing {
  incoterm: string;
  purchasePrice: number | null;
  purchaseCurrency: string;
}

export interface UsedBatch {
  id: string; // _id of related commodity stock
  commodityId: BSON.ObjectId;
  location: BSON.ObjectId;
  lot: string;
  price: number;
  supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock";
  used: number;
  files: Array<CommodityBatchFile>;
}

export interface UsedPackagingBatch {
  id: BSON.ObjectId;
  packagingId: BSON.ObjectId;
  location: BSON.ObjectId;
  lot: string;
  price: number;
  supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock";
  used: number;
  files: Array<PackagingBatchFile>;
}

export enum OrderFileTypes {
  PRODUCTION_REPORT = "productionReport",
  QR_CODE = "qrCode",
  OTHER = "other"
}

export interface OrderFile {
  _id: BSON.ObjectId;
  name: string;
  path: string; // full path, no relative path
  person: string;
  date: Date;
  type: OrderFileTypes;
  customType?: string;
  size?: number;
  // Any type specific additional information
  additionalInformation?: object;
}

export interface OrdersDocument extends Orders, Document {}
export interface OrdersModel extends Model<OrdersDocument> {}
