/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import store, { persistor } from "./app/store/store";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "./index.scss"; // Standard version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./_metronic/_assets/sass/pages/wizard/wizard-2.scss";
import "./_metronic/_assets/sass/pages/inbox/inbox.scss";
import "./_metronic/_assets/sass/pages/error/error-5.scss";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL, REACT_APP_SENTRY } = process.env;

if (REACT_APP_SENTRY)
  Sentry.init({
    dsn: REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });

ReactDOM.render(<App store={store} persistor={persistor} basename={PUBLIC_URL} />, document.getElementById("root"));
