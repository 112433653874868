import React, { PureComponent, useState } from "react";
import { BSON } from "realm-web";
import { ManufacturersDocument, ProductionLine } from "../../model/manufacturers.types";
import { OrdersDocument } from "../../model/orders.types";
import ProductionPlanHelper, { ExtendedProductionWeek } from "./ProductionPlanHelper";
import { DataContext } from "../../context/dataContext";
import orderUtils, { ARCHIVE, CREATEINVOICE, FULFILLMENT } from "../../utils/orderUtils";

interface ProductionOrderMachinePlanningProps {
  order: OrdersDocument;
  ordersAtLocation: Array<OrdersDocument>;
  manufacturer: ManufacturersDocument;
  week: ExtendedProductionWeek;
  context: React.ContextType<typeof DataContext>;
  reschedule?: boolean;
  onSelectMachine: (machine: ProductionLine) => void;
}

interface ProductionOrderMachinePlanningState {}

class ProductionOrderMachinePlanning extends PureComponent<
  ProductionOrderMachinePlanningProps,
  ProductionOrderMachinePlanningState
> {
  render() {
    const { order, ordersAtLocation, manufacturer, week, context, onSelectMachine, reschedule } = this.props;
    const ordersForWeek = week.plannedOrders.map(o => ordersAtLocation.find(o2 => o2._id.toString() === o.toString()));
    return (
      <div className="container">
        <h5 className="kt-font-bolder kt-font-dark">
          Select the machine on which the order is expected to be produced
        </h5>
        <hr />
        {manufacturer.productionLines.length === 0 ? (
          <h5>No machines available</h5>
        ) : (
          <div className="row">
            {manufacturer.productionLines.map(machine => {
              const ordersForMachine = ordersForWeek.filter(
                o =>
                  o &&
                  o.settings.productionMachine &&
                  o.settings.productionMachine.toString() === machine._id.toString()
              ) as Array<OrdersDocument>;
              // Calculate already produced units from done orders
              const producedUnits = context.orders.reduce((a, o) => {
                if (
                  o.productionWeek &&
                  o.settings.productionMachine &&
                  [FULFILLMENT, CREATEINVOICE, ARCHIVE].includes(o.state) &&
                  o.productionWeek === week.week &&
                  o.settings.productionMachine.toString() === machine._id.toString()
                ) {
                  const hasFfInfo = orderUtils.hasFulfillmentPriceInfo(o);
                  return a + (hasFfInfo ? +o.fulfillment?.totalUnits! : +o.calculations[0].units);
                }
                return a;
              }, 0);
              return (
                <ProductionPlanningMachine
                  key={machine._id.toString()}
                  order={order}
                  week={week}
                  machine={machine}
                  ordersForMachine={ordersForMachine}
                  currentOrderUnits={order.calculations[0].units}
                  producedUnits={producedUnits}
                  context={context}
                  plannedOrders={week.plannedOrders}
                  onSelectMachine={onSelectMachine}
                  isCurrentOrderMachine={
                    reschedule &&
                    !!order.settings.productionMachine &&
                    machine._id.toString() === order.settings.productionMachine.toString()
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

interface ProductionPlanningMachineProps {
  order: OrdersDocument;
  week: ExtendedProductionWeek;
  currentOrderUnits: number;
  producedUnits: number;
  plannedOrders: Array<BSON.ObjectId>;
  ordersForMachine: Array<OrdersDocument>;
  machine: ProductionLine;
  context: React.ContextType<typeof DataContext>;
  isCurrentOrderMachine?: boolean;
  onSelectMachine: (machine: ProductionLine) => void;
}

const ProductionPlanningMachine: React.FunctionComponent<ProductionPlanningMachineProps> = ({
  currentOrderUnits,
  ordersForMachine,
  machine,
  producedUnits,
  context,
  plannedOrders,
  isCurrentOrderMachine,
  week,
  order,
  onSelectMachine
}) => {
  const [hover, setHover] = useState(false);
  const [reservedUnits, typeString] = ProductionPlanHelper.getPlannedOrdersInformation(
    plannedOrders,
    ordersForMachine,
    context,
    machine._id
  );
  const totalReserved = producedUnits + reservedUnits;
  const machineCapacity = machine.capacity;
  let units =
    hover &&
    (order.productionWeek !== week.week ||
      !order.settings.productionMachine ||
      order.settings.productionMachine.toString() !== machine._id.toString() ||
      !week.plannedOrders.some(o => o.toString() === order._id.toString()))
      ? totalReserved + currentOrderUnits
      : totalReserved;
  let percentage = (units / machineCapacity) * 100;
  let color = "success";
  if (percentage >= 100) color = "danger";
  else if (percentage >= 80) color = "warning";
  return (
    <div
      className="col-12 col-md-6 col-xl-4 mt-4"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onSelectMachine(machine)}
    >
      <div className={"p-3 week pointer"} style={{ minHeight: "50px", borderRadius: "10px" }}>
        <h5 className=" kt-font-bold">
          <span className="kt-font-dark mr-2">{machine.name}</span>
          {isCurrentOrderMachine && (
            <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill p-1 px-2 float-right">
              <b>Planned Machine</b>
            </span>
          )}
          <br />
          <small style={{ color: "#74788d" }}>
            {`${ordersForMachine.length} ${ordersForMachine.length === 1 ? "order" : "orders"} currently planned`}
          </small>
        </h5>
        <hr />
        <div style={{ width: "100%", textAlign: "center" }}>
          <h6 className="kt-font-dark kt-font-bold pb-3 pt-1">{typeString}</h6>
          <div className="progress mb-3" style={{ height: "5px" }}>
            <div className={"progress-bar bg-" + color} role="progressbar" style={{ width: percentage + "%" }} />
          </div>
          <span className="kt-font-dark">{`${units} of ${machineCapacity} units`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductionOrderMachinePlanning;
