import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { utils, writeFile } from "xlsx";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { CommodityBatch, CommoditiesDocument } from "../../../model/commodities.types";
import fileUtils, { purgeStringForCSV } from "../../../utils/fileUtils";
import baseUtils from "../../../utils/baseUtils";
import { SuppliersDocument } from "../../../model/suppliers.types";
import { OrdersDocument } from "../../../model/orders.types";
import DateInput from "../../common/DateInput";
import { T_FULFILLMENT } from "../../../utils/timelineUtils";
import { DataContext } from "../../../context/dataContext";
import commodityUtils from "../../../utils/commodityUtils";
import { LocalStock } from "../../commodities/CustomTypes";
import PohodaImport from "./pohoda/PohodaImport";

interface StockDataProps extends RouteComponentProps<{}, {}, {}> {
  manufacturers: Array<ManufacturersDocument>;
  commodities: Array<CommoditiesDocument>;
  suppliers: Array<SuppliersDocument>;
  orders: Array<OrdersDocument>;
}

interface StockDataState {
  manufacturerSelectedStockData?: { value: string; label: string };
  manufacturerSelectedInventoryList?: { value: string; label: string };
  generating: boolean;
  date: Date | null;
}

class StockData extends PureComponent<StockDataProps, StockDataState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: StockDataProps) {
    super(props);
    this.state = { generating: false, date: null };
  }

  handleChangeManufacturer = (
    manufacturer: { value: string; label: string } | "",
    type: "stockData" | "inventoryList"
  ) => {
    if (type === "stockData")
      this.setState({ manufacturerSelectedStockData: manufacturer !== "" ? manufacturer : undefined });
    else this.setState({ manufacturerSelectedInventoryList: manufacturer !== "" ? manufacturer : undefined });
  };

  handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ date: e.target.value ? new Date(e.target.value) : null });

  handleClickGenerateStockDataFile = () => {
    const { commodities, manufacturers, orders } = this.props;
    const { manufacturerSelectedStockData, date } = this.state;
    const targetDate = date ? new Date(new Date(date).setHours(23, 59, 59, 999)) : null;

    this.setState({ generating: true });

    // Only orders are relevant that were fulfilled after the given date
    const relevantOrders = targetDate
      ? orders.filter(order => {
          const fulfillmentEntry = order.timeline.find(entry => entry.type === T_FULFILLMENT);
          return fulfillmentEntry && order.usedBatches && fulfillmentEntry.date >= targetDate;
        })
      : [];

    const commodityUsedBatchesMap = relevantOrders.reduce((acc, order) => {
      if (order.usedBatches) {
        order.usedBatches.forEach(usedBatch => {
          const commodityId = usedBatch.commodityId?.toString();
          const batchId = usedBatch.id.toString();
          if (!commodityId) return;
          if (!acc[commodityId]) acc[commodityId] = {};
          acc[commodityId][batchId] = (acc[commodityId][batchId] || 0) + usedBatch.used;
        });
      }
      return acc;
    }, {} as { [commodityId: string]: { [batchId: string]: number } });

    const stocks = commodities.flatMap(commodity =>
      commodity.stock
        .filter(stock => {
          if (stock.disabled || stock.amount === 0 || (targetDate && stock.stocked > targetDate)) return false;
          return !manufacturerSelectedStockData || manufacturerSelectedStockData.value === stock.location.toString();
        })
        .map(stock => ({ commodity, batch: stock }))
    );

    const content = stocks.map(({ commodity, batch }) => {
      const commodityId = commodity._id.toString();
      const batchId = batch._id.toString();
      const usedAmount = commodityUsedBatchesMap[commodityId]?.[batchId] || 0;
      const totalAmount = Math.round((batch.amount + usedAmount) * 100) / 100;

      return {
        Lager: manufacturers.find(m => m._id.toString() === batch.location.toString())?.name || "unknown",
        Artikelnummer: commodity.identifier,
        Titel: commodity.title.de,
        Untertitel: commodity.subtitle.de,
        LOT: batch.lot,
        [`Bestand am ${new Date().toLocaleDateString()}`]: batch.amount.toFixed(2),
        [`Verbrauch seit ${targetDate?.toLocaleDateString() || new Date().toLocaleDateString()}`]:
          (-usedAmount).toFixed(2),
        [`Bestand am ${targetDate?.toLocaleDateString() || new Date().toLocaleDateString()}`]: totalAmount.toFixed(2),
        "Preis €/kg": batch.price.toFixed(2),
        "Gesamtpreis in €": (batch.price * totalAmount).toFixed(2),
        Eingebucht: baseUtils.formatDate(batch.stocked),
        MHD: baseUtils.formatDate(batch.expiry),
        Supplier: this.resolveSupplier(batch.supplier),
        Validierung: this.getValidationMessage(batch, commodity, totalAmount)
      };
    });

    try {
      const workSheet = utils.json_to_sheet(content);
      const workBook = utils.book_new();
      utils.book_append_sheet(workBook, workSheet, "Data");
      const now = new Date();
      const fileName = `Stock_Export_${now.toISOString().slice(0, 10)}_${now
        .toTimeString()
        .slice(0, 8)
        .replace(/:/g, "-")}.xlsx`;
      writeFile(workBook, fileName);
    } catch (error) {
      toast.error("Error generating File");
      console.error(error);
    } finally {
      this.setState({ generating: false });
    }
  };

  handleClickGenerateInventoryListCSV = () => {
    const { commodities } = this.props;
    const { manufacturerSelectedInventoryList } = this.state;
    this.setState({ generating: true });

    const stocks = [];
    try {
      for (let i = 0; i < commodities.length; i++) {
        const batches: Array<CommodityBatch> = [];
        const c = commodities[i];
        for (let j = 0; j < c.stock.length; j++) {
          const s = c.stock[j];
          if (s.disabled || s.amount === 0) continue;
          if (!manufacturerSelectedInventoryList || manufacturerSelectedInventoryList.value === s.location.toString()) {
            batches.push(s);
          }
        }
        stocks.push({ commodity: c, batches });
      }
      const stockSorted = _.sortBy(stocks, s => s.commodity.title.de);
      const headers = ["Rohstoff", "LOT", "Menge", "Lieferant", "Preis", "MHD", "EXP"];
      const content = [];
      const in12Months = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
      const sep = ";";
      for (let i = 0; i < stockSorted.length; i++) {
        const s = stockSorted[i];
        const unit = s.commodity.type ? "tsd." : "kg";
        if (s.batches.length === 0) continue;
        content.push([
          purgeStringForCSV(
            (s.commodity.title.de || s.commodity.title.en) +
              " - " +
              (s.commodity.subtitle.de || s.commodity.subtitle.en),
            sep
          ),
          "",
          "",
          "",
          "",
          "",
          ""
        ]);
        for (let j = 0; j < s.batches.length; j++) {
          const b = s.batches[j];
          const sup = this.resolveSupplier(b.supplier);
          content.push([
            "",
            "Batch: " + b.lot,
            b.amount.toFixed(2) + unit,
            purgeStringForCSV(sup, sep),
            baseUtils.formatEuro(b.price) + "/" + unit,
            baseUtils.formatDate(b.expiry),
            b.expiry.getTime() < in12Months.getTime() ? "EXP SOON" : "OK"
          ]);
        }
        const totalStock = s.batches.reduce((sum, b) => (b.amount > 0 ? sum + b.amount : sum), 0);
        const totalValue = s.batches.reduce((sum, b) => (b.amount > 0 ? sum + b.amount * b.price : sum), 0);
        const { manufacturers } = this.context;
        const stocks: Array<LocalStock> = [];
        const manFiltered = manufacturerSelectedInventoryList
          ? manufacturers.filter(m => m._id.toString() === manufacturerSelectedInventoryList.value)
          : manufacturers;
        for (let j = 0; j < manFiltered.length; j++) {
          const m = manFiltered[j];
          const stock = [];
          const disabledStock = [];
          for (let k = 0; k < s.commodity.stock.length; k++) {
            const b = s.commodity.stock[k];
            if (m._id.toString() === b.location.toString() && !b.disabled) {
              stock.push(b);
            } else if (m._id.toString() === b.location.toString() && b.disabled) {
              disabledStock.push(b);
            }
          }
          stocks.push({ manufacturer: m, localStock: stock });
        }
        let available = 0;
        for (let j = 0; j < stocks.length; j++) {
          available += commodityUtils.calculateStockValues(s.commodity, this.context, stocks[j]).available;
        }

        content.push([
          "",
          "Available:",
          available.toFixed(2) + unit,
          "Total Value",
          baseUtils.formatEuro((totalValue / totalStock) * available),
          "",
          ""
        ]);
        content.push(["", "", "", "", "", "", ""]);
      }
      const csv = fileUtils.exportAsCSV(headers, content, sep);
      fileUtils.downloadFile(csv, "InventoryList_" + baseUtils.formatDate(new Date()) + ".csv", "text/plain");
    } catch (e) {
      toast.error("Error generating CSV");
      console.error(e);
    } finally {
      this.setState({ generating: false });
    }
  };

  /**
   * Get information about potential issues of a batch
   * @param batch a batch document
   * @param commodity a commodity document
   * @param totalAmount the total amount of the batch
   * @returns {string} a note about potential issues or an empty string if everything is fine
   */
  getValidationMessage = (batch: CommodityBatch, commodity: CommoditiesDocument, totalAmount: number): string => {
    const now = new Date();
    const twoYearsAgo = new Date(now.setFullYear(now.getFullYear() - 2));
    const highValue = batch.price * totalAmount > 100000;
    const negativeValue = batch.price * totalAmount < 0;
    const zeroValue = totalAmount === 0;
    const expired = batch.expiry < new Date();
    const longStorage = batch.stocked < twoYearsAgo;
    const customProduct = !!commodity.type && (commodity.category === "custom" || commodity.form === "custom");

    if (highValue) return "Auffallend hoher Warenwert";
    if (negativeValue) return "Negativer Warenwert";
    if (zeroValue) return "Warenwert von 0";
    if (expired) return "MHD überschritten";
    if (longStorage) return "Seit über 2 Jahren lagernd";
    if (customProduct) return "Custom Product / Unklare Beschaffenheit";
    return "";
  };

  /**
   * Resolves the supplier referenced by the given ID or key.
   * @param supplier ID of the supplier or key for other things like own stock
   * @returns { string } Name of the supplier
   */
  resolveSupplier = (supplier: BSON.ObjectId | "accumulatedstock" | "custom" | "customer" | "ownstock"): string => {
    const { suppliers } = this.props;
    if (supplier === "accumulatedstock") return "Accumulated Stock";
    if (supplier === "custom") return "Custom";
    if (supplier === "customer") return "Customer";
    if (supplier === "ownstock") return "Stock";
    const sup = suppliers.find(s => s._id.toString() === supplier.toString());
    if (sup) return sup.name;
    return "Unknown";
  };

  render() {
    const { manufacturers, history } = this.props;
    const { manufacturerSelectedStockData, manufacturerSelectedInventoryList, generating, date } = this.state;

    return (
      <>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Stock Data</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-clean kt-margin-r-10"
                >
                  <i className="la la-arrow-left" />
                  <span className="kt-hidden-mobile">Back</span>
                </button>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
            {date && (
              <span className="kt-badge kt-badge--warning kt-badge--inline kt-font-lg">
                Note: When a specific date is set we need to compute data based on booked out batches. Data may not be
                complete.
              </span>
            )}
            <div className="row mt-2">
              <div className="col text-dark font-weight-bolder mb-5">
                Exports the stock data for a given manufacturer (or all) as CSV.
              </div>
            </div>
            <div className="row">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Manufacturer</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <Select
                  className="select-default"
                  options={manufacturers.map(m => {
                    return { value: m._id.toString(), label: m.name };
                  })}
                  onChange={(value: any) => this.handleChangeManufacturer(value || "", "stockData")}
                  placeholder={"All Locations"}
                  isClearable={true}
                  value={manufacturerSelectedStockData}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Date</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <DateInput
                  value={date}
                  onBlur={this.handleChangeDate}
                  name="target"
                  max={new Date(new Date().setDate(new Date().getDate() - 1))}
                  allowClear={true}
                />
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="float-right">
              <button
                className="btn btn-success"
                disabled={generating}
                onClick={generating ? undefined : this.handleClickGenerateStockDataFile}
              >
                {generating ? "Generating..." : "Generate Excel File"}
              </button>
            </div>
          </div>
        </div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">Inventory List</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row mt-2">
              <div className="col text-dark font-weight-bolder mb-5">
                Exports the inventory list for a given manufacturer (or all) which contains extended data about all
                batches as CSV.
              </div>
            </div>
            <div className="row">
              <div className="col col-6 col-md-4 col-lg-3 align-self-center">Manufacturer</div>
              <div className="col col-6 col-md-8 col-lg-3">
                <Select
                  className="select-default"
                  options={manufacturers.map(m => {
                    return { value: m._id.toString(), label: m.name };
                  })}
                  onChange={(value: any) => this.handleChangeManufacturer(value || "", "inventoryList")}
                  placeholder={"All Locations"}
                  isClearable={true}
                  value={manufacturerSelectedInventoryList}
                />
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="float-right">
              <button
                className="btn btn-success"
                disabled={generating}
                onClick={generating ? undefined : this.handleClickGenerateInventoryListCSV}
              >
                {generating ? "Generating..." : "Generate CSV"}
              </button>
            </div>
          </div>
        </div>
        <PohodaImport context={this.context} />
      </>
    );
  }
}

export default withRouter(StockData);
