import { BSON } from "realm-web";
import { Batch, LocationWarehouseSnapshot, PackagingUnit } from "./batch.types";
import { PersonSnapshot } from "./common.types";
import { NumValue } from "../common.types";

export enum DestinationType {
  CUSTOMER = "customer",
  SUPPLIER = "supplier",
  WAREHOUSE = "warehouse",
  PRODUCTION = "production"
}

export interface Outgoing {
  _id: BSON.ObjectId;
  created: Date;
  person: PersonSnapshot;
  batch: BatchSnapshot;
  universalBarcode?: BSON.ObjectId; // universalBarcode id, reference
  movedAmount: NumValue; // amount in kg, how much of the batch should be moved
  movedPackagingUnits: Array<PackagingUnit>; // which packagingUnits should be moved
  movedReservation?: Array<MovedReservation>;
  destination: Destination;
}

export interface BatchSnapshot extends Pick<Batch, "_id" | "sender" | "content" | "lot" | "expiry"> {}

export interface MovedReservation {
  _id: BSON.ObjectId; // reservation id, reference
  destinationWarehouseSnapshot: LocationWarehouseSnapshot; // where the reservation should be moved to
  amount: NumValue; // how much of the reservation should be moved to the new location
}

export interface Destination {
  _id: BSON.ObjectId; // customer, supplier, production site or physical warehouse id, reference
  type: DestinationType;
  address: DestinationAddress;
}

export interface DestinationAddress {
  name: string; // name/companyName
  street: string;
  houseNo: string;
  postalCode: string;
  city: string;
  country: string;
}
