import React from "react";
import i18n from "../../../../translations/i18n";
import { BatchLocation, PackagingUnit } from "../../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { getFormattedPackagingUnitAmount, getPackagingUnitAmount } from "../../../../utils/warehouseUtils";
import { Input } from "../../../common/Input";

interface SendModalPuReassignmentProps {
  selectedLocation: BatchLocation;
  amount: string;
  newPuAmounts: { [key: string]: string };
  onSetNewPuAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SendModalPuReassignment: React.FC<SendModalPuReassignmentProps> = ({
  selectedLocation,
  amount,
  newPuAmounts,
  onSetNewPuAmount
}) => {
  return (
    <div className="px-2">
      <span className="font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
        {i18n.t("warehouse:packagingUnits")}
      </span>
      <hr className="w-100" />
      <div className="row mb-3">
        <div className="col-4">
          <span className="text-black d-block mt-2" style={{ fontWeight: 500 }}>
            {i18n.t("warehouse:amount")}
          </span>
        </div>
        <div className="col-8">
          <div className="input-group">
            <Input
              type="number"
              className="form-control form-control-solid hide-arrows disabled"
              style={{ opacity: 0.65 }}
              value={amount}
              disabled={true}
            />
            <div className="input-group-append">
              <span className="input-group-text input-group-solid">
                {selectedLocation.amountAtLocation.unit || i18n.t("warehouse:units")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <table className="kt-datatable__table d-table p-5">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "40%" }}>
              <span className="font-weight-300 text-black">{i18n.t("warehouse:packagingUnitAbbreviation")}</span>
            </th>
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "30%" }}>
              <span className="font-weight-300 text-black">{i18n.t("warehouse:pUQuantity")}</span>
            </th>
            <th className="kt-datatable__cell d-table-cell pb-2" style={{ width: "30%" }}>
              <span className="font-weight-300 text-black">{i18n.t("warehouse:usedAmount")}</span>
            </th>
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {selectedLocation.packagingUnits.map((pU, idx) => (
            <PuListing
              key={pU._id.toString()}
              packagingUnit={pU}
              newPuAmount={newPuAmounts[pU._id.toString()]}
              onSetNewPuAmount={onSetNewPuAmount}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface PuListingProps {
  packagingUnit: PackagingUnit;
  newPuAmount: string;
  onSetNewPuAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PuListing: React.FC<PuListingProps> = ({ packagingUnit, newPuAmount, onSetNewPuAmount }) => {
  const { _id, amountPerPu, puSnapshot } = packagingUnit;

  return (
    <tr className="kt-datatable__row d-table-row border-0">
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              {getFormattedPackagingUnitAmount(packagingUnit, true, true)}
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0">
        <div className="input-group input-group-sm">
          <Input
            type="number"
            name={_id.toString()}
            className="form-control form-control-sm form-control-solid"
            value={newPuAmount}
            integerOnly={true}
            max={packagingUnit.quantity || undefined}
            onChange={onSetNewPuAmount}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid">{resolveTranslation(puSnapshot.label)}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell pb-0 ">
        <div className="input-group input-group-sm">
          <Input
            type="number"
            disabled={true}
            className="form-control form-control-sm form-control-solid hide-arrows disabled"
            style={{ opacity: 0.65 }}
            value={Number(newPuAmount) * amountPerPu.value}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-solid" style={{ opacity: 0.65 }}>
              {amountPerPu.unit}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SendModalPuReassignment;
