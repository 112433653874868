import React, { useCallback } from "react";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import { OrdersDocument, pricingCommodities } from "../../../../model/orders.types";

interface OrderCommodityReservationStateProps {
  color: string;
  statusText: string;
  order: OrdersDocument;
  commodity: pricingCommodities;
}

const OrderCommodityReservationState: React.FC<OrderCommodityReservationStateProps> = ({
  color,
  statusText,
  order,
  commodity
}) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.CREATE_RESERVATION,
        orderId: order._id.toString(),
        materialId: commodity._id.toString()
      }
    });
  }, [order._id, commodity._id]);

  return (
    <div className="kt-user-card-v2 pointer justify-content-end" onClick={handleTriggerAction}>
      <span className={"kt-user-card-v2__email mt-0 kt-font-bold " + color}>{statusText}</span>
    </div>
  );
};

export default OrderCommodityReservationState;
