import _ from "lodash";
import { BSON } from "realm-web";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CalculationCustomPriceDetails,
  CalculationPriceDetails,
  CalculationType,
  CommodityCalculation,
  CustomCalculationForModal,
  CustomCommoditiesDocument,
  CustomPackagingsDocument,
  CustomPriceDetails,
  ExtendedCapsule,
  ExtendedCustomer,
  ExtendedOrder,
  Preferences,
  SelectedCommoditiesDocument,
  SelectedPackagingsDocument
} from "./CustomTypes";
import { DataContext } from "../../context/dataContext";
import ConfiguratorHelper from "./ConfiguratorHelper";
import ConfiguratorTabs from "./ConfiguratorTabs";
import RecipeSelection from "./RecipeSelection";
import PackagingSelection from "./PackagingSelection";
import CustomerSelection from "./CustomerSelection";
import Calculation from "./Calculation";
import SplashScreen from "../common/SplashScreen";
import calculationUtils from "../../utils/calculationUtils";
import calculationHelper from "./calculationDetails/calculationHelper";
import ConfigurationConfirmationModal from "./ConfigurationConfirmationModal";
import dbService from "../../services/dbService";
import CreateSampleRecipeModal from "./CreateSampleRecipeModal";
import OfferPdfPreview from "./OfferPDFPreview";
import CalculationReportPreview from "./CalculationReportPreview";
import offerPDFGeneration from "../../utils/pdf/offerPDFGeneration";
import pdfUtils from "../../utils/pdf/pdfUtils";
import calculationReportGeneration from "../../utils/pdf/calculationReportGeneration";
import { OrdersDocument, OrderState } from "../../model/orders.types";
import LoadRecipeModal from "./LoadRecipeModal";
import { RequestsDocument } from "../../model/requests.types";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import orderUtils from "../../utils/orderUtils";
import dateUtils from "../../utils/dateUtils";
import { ProductTypes } from "./configuratorConstants";
import notificationService, {
  R_OFFERUPDATED,
  R_ORDERCREATED,
  R_ORDERUPDATED
} from "../../services/notificationService";
import slackService from "../../services/slackService";
import userService from "../../services/userService";
import { ROLES } from "../../utils/userdataUtils";
import manufacturerUtils from "../../utils/manufacturerUtils";
import baseUtils from "../../utils/baseUtils";

interface ConfiguratorParams {
  collection: string;
  id: string;
}

interface ConfiguratorProps extends RouteComponentProps<ConfiguratorParams, {}, {}> {}

interface ConfiguratorState {
  step: number;
  fullscreenMode: boolean;
  productType: string;
  commodities: Array<CustomCommoditiesDocument>;
  recipe: Array<SelectedCommoditiesDocument>;
  recipeVolume: { value: number; noDefault: boolean };
  packaging: Array<CustomPackagingsDocument>;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  selectedCapsule: ExtendedCapsule | null;
  preferences: Preferences;
  customCalculations: Array<CalculationCustomPriceDetails>;
  calculations: Array<CalculationType>;
  customer: ExtendedCustomer | null;
}

const steps: { [step: string]: { headline: string } } = {
  1: { headline: "Select Recipe" },
  2: { headline: "Select Packaging" },
  3: { headline: "Select Customer" },
  4: { headline: "Calculation" }
};

class Configurator extends Component<ConfiguratorProps, ConfiguratorState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  _isMounted = false;
  _requestedId: string | undefined;
  _requestedCollection: string | undefined;
  _fromExisting = false;
  _originOrder: OrdersDocument | undefined;
  _originRequest: RequestsDocument | undefined;
  constructor(props: ConfiguratorProps) {
    super(props);
    this._requestedId = props.match.params.id;
    this._requestedCollection = props.match.params.collection;
    this.state = {
      step: 1,
      fullscreenMode: false,
      productType: ProductTypes.CAPSULES,
      commodities: [],
      packaging: [],
      preferences: ConfiguratorHelper.getDefaultPreferences(ProductTypes.CAPSULES),
      recipe: [],
      recipeVolume: { noDefault: true, value: 0 },
      selectedPackaging: [],
      selectedCapsule: null,
      customCalculations: [],
      customer: null,
      calculations: [ConfiguratorHelper.getDefaultCalculation()]
    };
  }

  shouldComponentUpdate(
    nextProps: Readonly<ConfiguratorProps>,
    nextState: Readonly<ConfiguratorState>,
    nextContext: React.ContextType<typeof DataContext>
  ): boolean {
    return this.context !== nextContext || this.state !== nextState;
  }

  async componentDidMount() {
    this._isMounted = true;

    const [stateObject, fromExisting, order] = await ConfiguratorHelper.loadConfiguratorState(
      this._requestedCollection,
      this._requestedId,
      this.state.productType,
      this.state.calculations,
      this.context
    );
    const query = new URLSearchParams(this.props.location.search);
    // Set flag whether data was loaded from existing source or not, set false if it is copied
    this._fromExisting = query.has("copy") ? false : fromExisting;
    if (this._requestedCollection === "order" && this._fromExisting && order) {
      this._originOrder = order as OrdersDocument;
    } else if (this._requestedCollection === "request" && this._fromExisting && order) {
      this._originRequest = order as RequestsDocument;
    }
    if (this._isMounted) this.setState({ ...stateObject });
  }

  componentDidUpdate(prevProps: Readonly<ConfiguratorProps>, prevState: Readonly<ConfiguratorState>) {
    if (prevState.preferences.bulk !== this.state.preferences.bulk) this.handleRecalculate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Set step
   * @param step new step number
   */
  changeStep = (step: number) => {
    this.setState({ step: step });
  };

  /**
   * Go to next step
   */
  nextStep = () => {
    const { step, preferences } = this.state;
    if (step === 4) return;
    if (preferences.bulk && step === 1) this.setState({ step: step + 2 });
    else this.setState({ step: step + 1 });
  };

  /**
   * Go to previous step
   */
  previousStep = () => {
    const { step, preferences } = this.state;
    if (step === 1) {
      this.props.history.goBack();
      return;
    }
    if (preferences.bulk && step === 3) this.setState({ step: step - 2 });
    else this.setState({ step: step - 1 });
  };

  /**
   * Toggle fullscreen mode
   */
  toggleFullscreen = () => {
    const { fullscreenMode } = this.state;
    this.setState({ fullscreenMode: !fullscreenMode });
  };

  /**
   * Reset all data
   */
  resetConfigurator = () => {
    const state = this.getDefaultState(ProductTypes.CAPSULES, true);
    // Set base url
    this.props.history.replace("/calculation");
    // Reset instance variables and references to orders
    this._fromExisting = false;
    this._requestedId = undefined;
    this._requestedCollection = undefined;
    this._originRequest = undefined;
    this._originOrder = undefined;
    // Set state back to default
    this.setState({ productType: ProductTypes.CAPSULES, ...state });
  };

  /**
   * Handle product type change
   * @param entry value that the state gets set to
   */
  handleProductTypeChange = (entry: string) => {
    const state = this.getDefaultState(entry);
    // Use possible new calculations from new default state otherwise the calculations from old state are copied
    const defaultCalc = this.getDefaultCalculations(state.calculations);
    this.setState({
      productType: entry,
      ...state,
      calculations: defaultCalc.calculations,
      customCalculations: defaultCalc.customCalculations
    });
  };

  /**
   * Handle changes to preferences
   * @param path name of the value inside the preferences object
   * @param item new value or object to set
   */
  handlePreferencesChange = (path: string, item: any) => {
    const preferences = _.cloneDeep(this.state.preferences);
    _.set(preferences, path, item);
    // on bulk change adjust amountPerUnit as well
    let newState: any = { preferences };
    if (path === "bulk") {
      // reset selected packaging
      newState.selectedPackaging = [];
      preferences.selectedFiller = undefined;
      if (item) _.set(preferences, "amountPerUnit", "1000");
      if (!item) _.set(preferences, "amountPerUnit", ConfiguratorHelper.getAmountPerUnit(this.state.productType));
    }
    this.setState({ ...newState });
  };

  /**
   * Change the selected capsule
   * @param capsuleId id of the new capsule
   */
  handleCapsuleChange = (capsuleId: BSON.ObjectId | string) => {
    const { capsules } = this.context;
    const { productType, calculations } = this.state;
    const capsule = baseUtils.getDocFromCollection(capsules, capsuleId);
    if (!capsule || productType !== ProductTypes.CAPSULES) return;

    const preferences = _.cloneDeep(this.state.preferences);
    preferences.selectedCapsule = capsule;
    let newState: Partial<ConfiguratorState> = { preferences };
    // Adjust selected manufacturer if manufacturer cant produce new capsule
    const { selectedManufacturer } = preferences;
    const canEncapsule =
      selectedManufacturer &&
      selectedManufacturer.capsules?.encapsulation &&
      selectedManufacturer.capsules.encapsulation.some(obj => obj.size === capsule.capsule_size);
    if (!canEncapsule) {
      // find new manufacturer
      const newManufacturer = this.context.manufacturers.find(
        man =>
          man.capsules &&
          man.capsules.encapsulation &&
          man.capsules.encapsulation.some(obj => obj.size === capsule.capsule_size)
      );
      if (!newManufacturer) {
        toast.error(
          "No manufacturer can produce the selected capsule. Please choose another capsule or update one or multiple manufacturers"
        );
        return;
      }
      newState.preferences!.selectedManufacturer = newManufacturer;
    }
    // Update selected capsule calculations
    newState.selectedCapsule = ConfiguratorHelper.getDefaultSelectedCapsule(
      capsule,
      preferences.selectedManufacturer,
      +preferences.amountPerUnit,
      calculations,
      this.context
    );

    this.setState({ ...newState } as ConfiguratorState);
  };

  /**
   * Add commodity to recipe
   * @param id the objectId of the selected commodity
   * @param index the index where to insert the commodity
   * @param amount the amount of the commodity in mg
   */
  handleRecipeAdd = (id: BSON.ObjectId, index?: number, amount?: number) => {
    // Early abort if someone somehow finds a way to smuggle invalid numbers to the function
    if (amount && amount <= 0) return;
    const { productType, commodities, calculations } = this.state;
    const preferences = _.cloneDeep(this.state.preferences);
    const recipe = _.cloneDeep(this.state.recipe);
    const commodity = commodities.find(com => com._id.toString() === id.toString());
    if (!commodity) return;
    let tempCom = _.cloneDeep(commodity) as SelectedCommoditiesDocument;
    tempCom.amount = amount ? amount : 0;
    let commodityCalculations: Array<CommodityCalculation> = [];
    for (let i = 0; i < calculations.length; i++) {
      let calculation = calculations[i];
      const commodityCalculation = calculationUtils.getCommodityPrice(
        commodity!,
        this.context.suppliers,
        +preferences.amountPerUnit,
        +tempCom.amount,
        productType,
        calculation
      );
      commodityCalculations.push(commodityCalculation);
    }
    tempCom!.calculations = commodityCalculations;
    if ([ProductTypes.CUSTOM, ProductTypes.SOFTGEL, ProductTypes.SERVICE].includes(productType)) {
      this.setState({ recipe: [tempCom] });
    } else {
      if (index) recipe.splice(index, 0, tempCom);
      else recipe.push(tempCom);
      const recipeVolume = calculationUtils.getRecipeVolume(recipe);
      let state: any = { recipe, recipeVolume };
      if ([ProductTypes.POWDER, ProductTypes.LIQUID].includes(productType)) {
        preferences.amountPerUnit = (+preferences.amountPerUnit + tempCom!.amount!).toString();
        state.preferences = preferences;
      }
      this.setState(state);
    }
  };

  /**
   * Handle edit on a recipe item
   * @param id the id of the commodity
   * @param amount the new amount of the commodity
   */
  handleRecipeSave = (id: BSON.ObjectId, amount: number) => {
    // Early abort if someone somehow finds a way to smuggle invalid numbers to the function
    if (amount <= 0) return;
    const { productType, calculations } = this.state;
    // Copy recipe
    const recipe = _.cloneDeep(this.state.recipe);
    const preferences = _.cloneDeep(this.state.preferences);
    const commodity = recipe.find(com => com._id.toString() === id.toString());
    if (!commodity) console.error("Commodity with ID", id.toString(), "not found");
    commodity!.amount = amount;
    let commodityCalculations: Array<CommodityCalculation> = [];
    for (let i = 0; i < calculations.length; i++) {
      let calculation = calculations[i];
      const commodityCalculation = calculationUtils.getCommodityPrice(
        commodity!,
        this.context.suppliers,
        +preferences.amountPerUnit,
        amount,
        productType,
        calculation
      );
      commodityCalculations.push(commodityCalculation);
    }
    commodity!.calculations = commodityCalculations;
    let recipeVolume = calculationUtils.getRecipeVolume(recipe);
    let state: any = { recipe, recipeVolume };
    if ([ProductTypes.POWDER, ProductTypes.LIQUID].includes(productType)) {
      // compute new amount per unit
      preferences.amountPerUnit = recipe.reduce((a, b) => a + +b.amount!, 0).toString();
      state.preferences = preferences;
    }
    this.setState(state);
  };

  /**
   * Remove an item from recipe
   * @param id the id of the commodity object
   */
  handleRecipeDelete = (id: BSON.ObjectId) => {
    const { productType, recipe } = this.state;
    const preferences = _.cloneDeep(this.state.preferences);
    const newRecipe = recipe.filter(com => com._id.toString() !== id.toString());
    const newState: any = { recipe: newRecipe };
    if (![ProductTypes.CUSTOM, ProductTypes.SOFTGEL, ProductTypes.SERVICE].includes(productType)) {
      newState.recipeVolume = calculationUtils.getRecipeVolume(newRecipe);
    }
    if ([ProductTypes.POWDER, ProductTypes.LIQUID].includes(productType)) {
      const commodity = recipe.find(com => com._id.toString() === id.toString());
      preferences.amountPerUnit = (+preferences.amountPerUnit - commodity!.amount!).toString();
      newState.preferences = preferences;
    }
    this.setState(newState);
  };

  /**
   * Select the package
   * @param packaging the packaging object
   */
  handlePackagingSelect = (packaging: CustomPackagingsDocument) => {
    const selectedPackaging = [...this.state.selectedPackaging];
    const calculations = this.state.calculations;
    // Copy/Change type
    const selPackaging = _.cloneDeep(packaging) as SelectedPackagingsDocument;
    // Set amount and add to selectedPackaging
    selPackaging.amount = 1;
    let packagingCalculations = [];
    for (let i = 0; i < calculations.length; i++) {
      let calculation = calculations[i];
      const packagingCalculation = calculationUtils.getPackagingPrice(
        packaging,
        this.context.suppliers,
        1,
        calculation
      );
      packagingCalculations.push(packagingCalculation);
    }
    selPackaging.calculations = packagingCalculations;
    selectedPackaging.push(selPackaging);
    const defaultCalc = this.getDefaultCalculations();
    this.setState({
      selectedPackaging: selectedPackaging,
      calculations: defaultCalc.calculations,
      customCalculations: defaultCalc.customCalculations
    });
  };

  /**
   * Increase or decrease the amount of a packaging
   * @param pack the selected packaging document
   * @param add flag if increase or decrease
   */
  handlePackagingAmount = (pack: SelectedPackagingsDocument, add: boolean) => {
    const calculations = this.state.calculations;
    // Don't go above 99
    if (add && pack.amount === 99) return;
    // Copy packaging parameter and selectedPackaging array
    const selPackaging = { ...pack } as SelectedPackagingsDocument;
    let selectedPackaging: Array<SelectedPackagingsDocument> = [...this.state.selectedPackaging];

    const index = selectedPackaging.indexOf(pack);
    if (add) selPackaging.amount = selPackaging.amount! + 1;
    else selPackaging.amount = selPackaging.amount! - 1;
    if (selPackaging.amount === 0) {
      // Remove amount, delete from selected and add to packaging
      selectedPackaging.splice(index, 1);
    } else {
      let packagingCalculations = [];
      for (let i = 0; i < calculations.length; i++) {
        let calculation = calculations[i];
        const packagingCalculation = calculationUtils.getPackagingPrice(
          pack,
          this.context.suppliers,
          selPackaging.amount,
          calculation
        );
        packagingCalculations.push(packagingCalculation);
      }
      selPackaging.calculations = packagingCalculations;
      selectedPackaging[index] = selPackaging;
    }
    this.setState({ selectedPackaging });
  };

  /**
   * Handle moving a draggable item inside a list e.g. from index 1 to 2
   * @param oldIndex old index
   * @param newIndex new index
   */
  handleDraggableItemMove = (oldIndex: number, newIndex: number) => {
    const recipe = _.cloneDeep(this.state.recipe);
    if (oldIndex >= recipe.length || newIndex >= recipe.length) return;
    recipe.splice(newIndex, 0, recipe.splice(oldIndex, 1)[0]);
    this.setState({ recipe });
  };

  /**
   * Handle selection of a customer
   * @param customer extended customer object
   */
  handleCustomerSelect = (customer: ExtendedCustomer) => {
    const { customer: currentCustomer } = this.state;
    if (currentCustomer && currentCustomer._id.toString() === customer._id.toString())
      this.setState({ customer: null });
    else {
      const selectedCustomer = _.cloneDeep(customer);
      selectedCustomer.selected = true;
      this.setState({ customer: selectedCustomer });
    }
  };

  /**
   * Handle recalculation of recipe and packaging entries
   */
  handleRecalculate = () => {
    const { preferences, productType } = this.state;
    const recipe = _.cloneDeep(this.state.recipe);
    const selectedPackaging = _.cloneDeep(this.state.selectedPackaging);
    const selectedCapsule = _.cloneDeep(this.state.selectedCapsule);
    const calculations = _.cloneDeep(this.state.calculations);

    const getMatchingCalculation = (id: BSON.ObjectId) =>
      calculations.find(calc => calc.id.toString() === id.toString())!;
    // Recalculate commodities
    for (let i = 0; i < recipe.length; i++) {
      const commodity = recipe[i];
      let newCommodityCalculations = [];
      for (let j = 0; j < commodity.calculations.length; j++) {
        let currentCalc = commodity.calculations[j];
        let newCalculation = calculationUtils.getCommodityPrice(
          commodity!,
          this.context.suppliers,
          +preferences.amountPerUnit,
          commodity.amount!,
          productType,
          getMatchingCalculation(currentCalc.id)
        );
        newCommodityCalculations.push(newCalculation);
      }
      commodity.calculations = newCommodityCalculations;
    }
    // Recalculate packaging
    for (let i = 0; i < selectedPackaging.length; i++) {
      const packaging = selectedPackaging[i];
      let newPackagingCalculations = [];
      if (!packaging.calculations) continue;
      for (let j = 0; j < packaging.calculations.length; j++) {
        let currentCalc = packaging.calculations[j];
        let newCalculation = calculationUtils.getPackagingPrice(
          packaging!,
          this.context.suppliers,
          packaging.amount!,
          getMatchingCalculation(currentCalc.id)
        );
        newPackagingCalculations.push(newCalculation);
      }
      packaging.calculations = newPackagingCalculations;
    }

    // Recalculate capsules
    if (selectedCapsule) {
      let newCapsuleCalculations = [];
      for (let j = 0; j < selectedCapsule.calculations.length; j++) {
        let currentCalc = selectedCapsule.calculations[j];
        const amountPerUnit = +preferences.amountPerUnit;
        const quantity = (+calculations[j].units * amountPerUnit) / 1000;
        let newCalculation = calculationUtils.getCapsulePrice(
          selectedCapsule,
          this.context.suppliers,
          this.context.manufacturers,
          quantity,
          getMatchingCalculation(currentCalc.id),
          preferences.selectedManufacturer
        );
        newCapsuleCalculations.push(newCalculation);
      }
      selectedCapsule.calculations = newCapsuleCalculations;
    }
    this.setState({ recipe, selectedPackaging, selectedCapsule });
  };

  /**
   * Handle recalculation of specific calculations
   * @param calc calculation object
   */
  handleRecalculateSpecific = (calc: CalculationType) => {
    const { preferences, productType } = this.state;
    const calculations = _.cloneDeep(this.state.calculations);
    const calculation = calculations.find(cal => cal.id.toString() === calc.id.toString())!;
    const recipe = _.cloneDeep(this.state.recipe);
    const selectedPackaging = _.cloneDeep(this.state.selectedPackaging);
    const selectedCapsule = _.cloneDeep(this.state.selectedCapsule);

    // Recalculate commodities
    for (let i = 0; i < recipe.length; i++) {
      const commodity = recipe[i];
      let oldCalculation = commodity.calculations.find(calc => calc.id.toString() === calculation.id.toString());
      if (!oldCalculation) {
        console.error(`No calculation found for commodity ${commodity} and calculation ${calculation}`);
        continue;
      }
      calculationUtils.updateCommodityPrice(
        commodity,
        oldCalculation,
        this.context.suppliers,
        +calculation.units,
        +preferences.amountPerUnit,
        productType
      );
    }
    // Recalculate packaging
    for (let i = 0; i < selectedPackaging.length; i++) {
      const packaging = selectedPackaging[i];
      if (!packaging.calculations) continue;
      let oldCalculation = packaging.calculations.find(calc => calc.id.toString() === calculation.id.toString());
      if (!oldCalculation) {
        console.error(`No calculation found for packaging ${packaging} and calculation ${calculation}`);
        continue;
      }
      calculationUtils.updatePackagingPrice(packaging, oldCalculation, this.context.suppliers, +calculation.units);
    }
    // Recalculate capsule
    if (selectedCapsule && selectedCapsule.calculations) {
      let oldCalculation = selectedCapsule.calculations.find(calc => calc.id.toString() === calculation.id.toString());
      if (!oldCalculation) {
        console.error(`No calculation found for capsule ${selectedCapsule} and calculation ${calculation}`);
      } else {
        calculationUtils.updateCapsulePrice(
          selectedCapsule,
          oldCalculation,
          this.context.suppliers,
          this.context.manufacturers,
          +calculation.units,
          +preferences.amountPerUnit,
          preferences.selectedManufacturer
        );
      }
    }
    // Update prices
    const prices: CalculationPriceDetails = calculationHelper.calculateUnitPrice(
      productType,
      calculation,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      this.state.customCalculations.find(cC => cC.calculationId.toString() === calculation.id.toString())
    );
    calculationHelper.updateCalculationWithPrices(calculation, prices);
    this.setState({ recipe, selectedPackaging, selectedCapsule, calculations });
  };

  /**
   * Add a new calculation
   */
  handleCalculationAdd = () => {
    const { preferences, productType } = this.state;
    const calculations = _.cloneDeep(this.state.calculations);
    const recipe = _.cloneDeep(this.state.recipe);
    const selectedPackaging = _.cloneDeep(this.state.selectedPackaging);
    const selectedCapsule = _.cloneDeep(this.state.selectedCapsule);
    const calculationId = new BSON.ObjectId();
    const calculationUnits = calculations[0] && calculations[0].units ? calculations[0].units : "1000";
    const newCalculation = ConfiguratorHelper.getDefaultCalculation(calculationId, calculationUnits);
    calculations.push(newCalculation);
    // Add new calculation to commodities
    for (let i = 0; i < recipe.length; i++) {
      const commodity = recipe[i];
      commodity.calculations.push(
        calculationUtils.getCommodityPrice(
          commodity!,
          this.context.suppliers,
          +preferences.amountPerUnit,
          commodity.amount!,
          productType,
          newCalculation
        )
      );
    }
    // Add new calculation to packaging
    for (let i = 0; i < selectedPackaging.length; i++) {
      const packaging = selectedPackaging[i];
      if (!packaging.calculations) continue;
      packaging.calculations.push(
        calculationUtils.getPackagingPrice(packaging!, this.context.suppliers, packaging.amount!, newCalculation)
      );
    }
    // Add new calculation to capsule
    if (selectedCapsule && selectedCapsule.calculations) {
      const amountPerUnit = +preferences.amountPerUnit;
      const quantity = (+newCalculation.units * amountPerUnit) / 1000;
      selectedCapsule.calculations.push(
        calculationUtils.getCapsulePrice(
          selectedCapsule,
          this.context.suppliers,
          this.context.manufacturers,
          quantity,
          newCalculation,
          preferences.selectedManufacturer
        )
      );
    }

    this.setState({ calculations, recipe, selectedPackaging, selectedCapsule });
  };

  /**
   * Delete a calculation
   * @param calculation the calculation to delete
   */
  handleCalculationDelete = (calculation: CalculationType) => {
    if (this.state.calculations.length === 1) return;
    const calculations = _.cloneDeep(this.state.calculations);
    const newCalculations = calculations.filter(calc => calc.id.toString() !== calculation.id.toString());
    // check if a custom calculation exists for calculation and delete it as well
    const customCalculations = _.cloneDeep(this.state.customCalculations);
    const newCustomCalculations = customCalculations.filter(
      calc => calc.calculationId.toString() !== calculation.id.toString()
    );
    this.setState({ calculations: newCalculations, customCalculations: newCustomCalculations });
  };

  /**
   * Update properties of a calculation
   * @param id the id of the calculation entry
   * @param path the path of the property
   * @param value the value
   * @param updatePrices flag if prices should be updated
   */
  handleCalculationPropertyChange = (id: BSON.ObjectId, path: string, value: any, updatePrices?: boolean) => {
    const { productType, preferences, recipe, selectedPackaging, selectedCapsule } = this.state;
    const calculations = _.cloneDeep(this.state.calculations);
    const updatedCalculation = calculations.find(calc => calc.id.toString() === id.toString());
    if (!updatedCalculation) return;
    _.set(updatedCalculation, path, value);
    if (path === "marginType") {
      updatePrices = false;
      if (value === "percent") updatedCalculation.margin = updatedCalculation.percentMargin.toString();
      else if (value === "euro") updatedCalculation.margin = updatedCalculation.unitPrice.toString();
    }

    if (updatePrices) {
      // Update calculation and prices
      const prices: CalculationPriceDetails = calculationHelper.calculateUnitPrice(
        productType,
        updatedCalculation,
        preferences,
        recipe,
        selectedPackaging,
        selectedCapsule,
        this.state.customCalculations.find(cC => cC.calculationId.toString() === id.toString())
      );
      calculationHelper.updateCalculationWithPrices(updatedCalculation, prices);
    }

    this.setState({ calculations });
  };

  /**
   * Update a specific calculation
   * @param id the object id of the calculation
   */
  handleUpdateCalculation = (id: BSON.ObjectId | string) => {
    const { productType, preferences, recipe, selectedPackaging, selectedCapsule } = this.state;
    const calculations = _.cloneDeep(this.state.calculations);
    const index = calculations.findIndex(calc => calc.id.toString() === id.toString());
    const calculation = calculations[index]!;
    const prices: CalculationPriceDetails = calculationHelper.calculateUnitPrice(
      productType,
      calculation,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      this.state.customCalculations.find(cC => cC.calculationId.toString() === id.toString())
    );
    calculationHelper.updateCalculationWithPrices(calculation, prices);
    // Directly work with the latest state
    this.setState(prevState => {
      let prevCalculations = [...prevState.calculations];
      prevCalculations[index] = calculation;
      return { calculations: prevCalculations };
    });
  };

  /**
   * Update capsule calculation
   * @param calculationId calculation id
   * @param path key
   * @param value value to set
   */
  handleCapsuleCalculationUpdate = (calculationId: BSON.ObjectId, path: string, value: any) => {
    const { preferences, productType, recipe, selectedPackaging } = this.state;
    const selectedCapsule = _.cloneDeep(this.state.selectedCapsule);
    const calculations = _.cloneDeep(this.state.calculations);
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!selectedCapsule || !calculation) return;
    const capsuleCalculation = selectedCapsule.calculations.find(
      calc => calc.id.toString() === calculationId.toString()
    );
    if (!capsuleCalculation) return;
    _.set(capsuleCalculation, path, value);
    if (path === "auto" && value) {
      calculationUtils.updateCapsulePrice(
        selectedCapsule,
        capsuleCalculation,
        this.context.suppliers,
        this.context.manufacturers,
        +calculation.units,
        +preferences.amountPerUnit,
        preferences.selectedManufacturer
      );
      const prices = calculationHelper.recalculateUnitPrices(
        productType,
        calculation,
        preferences,
        recipe,
        selectedPackaging,
        selectedCapsule,
        "capsules",
        this.state.customCalculations.find(cC => cC.calculationId.toString() === calculationId.toString())
      );
      if (!prices) return;
      calculationHelper.updateCalculationWithPrices(calculation, prices);
    }
    this.setState({ calculations, selectedCapsule });
  };

  /**
   * Handle supplier change for capsules
   * @param calculationId the calculation id
   * @param newSupplier the new supplier
   */
  handleCapsuleSupplierChange = (
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => {
    const { preferences, productType, recipe, selectedPackaging } = this.state;
    const selectedCapsule = _.cloneDeep(this.state.selectedCapsule);
    const calculations = _.cloneDeep(this.state.calculations);
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!selectedCapsule || !calculation) return;
    calculationUtils.updateCapsuleSupplier(
      selectedCapsule,
      calculation,
      preferences,
      this.context.suppliers,
      this.context.manufacturers,
      newSupplier
    );
    const prices = calculationHelper.recalculateUnitPrices(
      productType,
      calculation,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      "capsules",
      this.state.customCalculations.find(cC => cC.calculationId.toString() === calculationId.toString())
    );
    if (!prices) return;
    calculationHelper.updateCalculationWithPrices(calculation, prices);
    this.setState({ calculations, selectedCapsule });
  };

  /**
   * Update recipe calculations
   * @param commodityId commodity id
   * @param calculationId calculation id
   * @param path key
   * @param value value to set
   * @param recalculate flag if a recalculation should be done or not
   */
  handleRecipeCalculationUpdate = (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any,
    recalculate: boolean
  ) => {
    const { preferences, productType, selectedPackaging, selectedCapsule } = this.state;
    const recipe = _.cloneDeep(this.state.recipe);
    const calculations = _.cloneDeep(this.state.calculations);
    const commodity = recipe.find(com => com._id.toString() === commodityId.toString());
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!commodity || !calculation) return;
    const commodityCalculation = commodity.calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!commodityCalculation) return;
    _.set(commodityCalculation, path, value);
    if (recalculate || (path === "auto" && value)) {
      calculationUtils.updateCommodityPrice(
        commodity,
        commodityCalculation,
        this.context.suppliers,
        +calculation.units,
        +preferences.amountPerUnit,
        productType
      );
      const prices = calculationHelper.recalculateUnitPrices(
        productType,
        calculation,
        preferences,
        recipe,
        selectedPackaging,
        selectedCapsule,
        "recipe",
        this.state.customCalculations.find(cC => cC.calculationId.toString() === calculation.id.toString())
      );
      if (!prices) return;
      calculationHelper.updateCalculationWithPrices(calculation, prices);
    }
    this.setState({ calculations, recipe });
  };

  /**
   * Handle supplier change for commodities
   * @param commodityId id of the commodity
   * @param calculationId id of the calculation
   * @param newSupplier the new supplier value
   */
  handleRecipeSupplierChange = (
    commodityId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => {
    const { preferences, productType, selectedPackaging, selectedCapsule } = this.state;
    const recipe = _.cloneDeep(this.state.recipe);
    const calculations = _.cloneDeep(this.state.calculations);
    const commodity = recipe.find(com => com._id.toString() === commodityId.toString());
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!commodity || !calculation) return;
    calculationUtils.updateCommoditySupplier(
      commodity,
      calculation,
      preferences,
      this.context.suppliers,
      newSupplier,
      productType
    );
    const prices = calculationHelper.recalculateUnitPrices(
      productType,
      calculation,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      "recipe",
      this.state.customCalculations.find(cC => cC.calculationId.toString() === calculation.id.toString())
    );
    if (!prices) return;
    calculationHelper.updateCalculationWithPrices(calculation, prices);
    this.setState({ recipe, calculations });
  };

  /**
   * Handle packaging calculation update
   * @param packagingId the packaging id
   * @param calculationId the calculation id
   * @param path path
   * @param value value to set
   */
  handlePackagingCalculationUpdate = (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    path: string,
    value: any
  ) => {
    const { productType, preferences, recipe, selectedCapsule } = this.state;
    const selectedPackaging = _.cloneDeep(this.state.selectedPackaging);
    const calculations = _.cloneDeep(this.state.calculations);
    const packaging = selectedPackaging.find(pack => pack._id.toString() === packagingId.toString());
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!packaging || !calculation) return;
    const packagingCalculation = packaging.calculations!.find(calc => calc.id.toString() === calculationId.toString());
    if (!packagingCalculation) return;
    _.set(packagingCalculation, path, value);
    if (path === "auto" && value) {
      calculationUtils.updatePackagingPrice(
        packaging,
        packagingCalculation,
        this.context.suppliers,
        +calculation.units
      );
      const prices = calculationHelper.recalculateUnitPrices(
        productType,
        calculation,
        preferences,
        recipe,
        selectedPackaging,
        selectedCapsule,
        "packaging",
        this.state.customCalculations.find(cC => cC.calculationId.toString() === calculationId.toString())
      );
      if (!prices) return;
      calculationHelper.updateCalculationWithPrices(calculation, prices);
    }
    this.setState({ selectedPackaging, calculations });
  };

  /**
   * Handle supplier change for packaging
   * @param packagingId the packaging id
   * @param calculationId the calculation id
   * @param newSupplier the new supplier
   */
  handlePackagingSupplierChange = (
    packagingId: BSON.ObjectId,
    calculationId: BSON.ObjectId,
    newSupplier: BSON.ObjectId | "ownstock" | "customer"
  ) => {
    const { productType, preferences, recipe, selectedCapsule } = this.state;
    const selectedPackaging = _.cloneDeep(this.state.selectedPackaging);
    const calculations = _.cloneDeep(this.state.calculations);
    const packaging = selectedPackaging.find(pack => pack._id.toString() === packagingId.toString());
    const calculation = calculations.find(calc => calc.id.toString() === calculationId.toString());
    if (!packaging || !calculation) return;
    calculationUtils.updatePackagingSupplier(
      packaging,
      calculation,
      preferences,
      this.context.suppliers,
      newSupplier,
      this.context
    );
    const prices = calculationHelper.recalculateUnitPrices(
      productType,
      calculation,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      "packaging",
      this.state.customCalculations.find(cC => cC.calculationId.toString() === calculationId.toString())
    );
    if (!prices) return;
    calculationHelper.updateCalculationWithPrices(calculation, prices);
    this.setState({ selectedPackaging, calculations });
  };

  /**
   * Load recipe into state
   * @param order the order to load the recipe from
   */
  handleLoadRecipe = (order: ExtendedOrder) => {
    const productType = calculationUtils.getTabForType(order.settings.type);
    const preferences = ConfiguratorHelper.getDefaultPreferences(productType);
    Object.assign(preferences, ConfiguratorHelper.getSelectedPreferenceForTab(order, this.context, productType));
    const { commodities, calculations } = this.state;
    let recipeCommodities = order.recipe
      .map(rec => {
        const commodity = commodities.find(
          com => com._id.toString() === rec.id.toString()
        ) as SelectedCommoditiesDocument;
        if (commodity) {
          let tmpCom = _.cloneDeep(commodity);
          tmpCom.amount = rec.amount;
          let commodityCalculations: Array<CommodityCalculation> = [];
          for (let i = 0; i < calculations.length; i++) {
            let calculation = calculations[i];
            const commodityCalculation = calculationUtils.getCommodityPrice(
              commodity!,
              this.context.suppliers,
              +preferences.amountPerUnit,
              +tmpCom.amount,
              productType,
              calculation
            );
            commodityCalculations.push(commodityCalculation);
          }
          tmpCom.calculations = commodityCalculations;
          return tmpCom;
        } else {
          console.error("Commodity " + rec.id.toString() + " not found while trying to load recipe.");
        }
      })
      .filter(entry => !!entry) as Array<SelectedCommoditiesDocument>;
    const recipeVolume = calculationUtils.getRecipeVolume(recipeCommodities);
    if ([ProductTypes.POWDER, ProductTypes.LIQUID].includes(productType)) {
      preferences.amountPerUnit = recipeCommodities.reduce((a, b) => a + b.amount!, 0).toString();
    }
    // Reset instance variables just in case
    this._requestedId = undefined;
    this._requestedCollection = undefined;
    this._originOrder = undefined;
    this.setState({ recipe: recipeCommodities, recipeVolume, preferences, productType });
  };

  /**
   * Save custom calculation information from CustomCalculationModal
   * @param customCalculationInfo the custom calculation values the user gave
   * @param calcId id of the calculation
   * @param buffer value of the margin buffer
   * @param optionalCost further optional costs
   * @param note some note for further information on custom calculation
   */
  handleSaveCustomCalculation = (
    customCalculationInfo: Array<CustomCalculationForModal>,
    calcId: BSON.ObjectId | string,
    buffer?: string | undefined,
    optionalCost?: string,
    note?: string
  ) => {
    const calculations = _.cloneDeep(this.state.calculations);
    const calculation = calculations.find(c => calcId.toString() === c.id.toString());
    const generalPrices = calculation?.priceDetails?.generalPrices;
    const defaultManufacturer = this.state.preferences.selectedManufacturer?._id;
    let customCalcState = _.cloneDeep(this.state.customCalculations);
    let customPrices: CustomPriceDetails = { note: "" };

    if (calculation?.priceDetails) {
      if (generalPrices?.blending) {
        const blendingInfoCustom = customCalculationInfo.find(cci => cci.type === "blending");
        const blendingInfoGeneral = generalPrices.blending;
        customPrices.blending = {
          price: Number(blendingInfoCustom?.cost) / blendingInfoGeneral.totalAmount,
          unitPrice: Number(blendingInfoCustom?.cost) / Number(calculation.units),
          manufacturerId: blendingInfoCustom?.manufacturerId || defaultManufacturer
        };
      }
      if (generalPrices?.blistering) {
        const blisteringInfoCustom = customCalculationInfo.find(cci => cci.type === "blistering");
        const blisteringInfoGeneral = generalPrices.blistering;
        customPrices.blistering = {
          price: Number(blisteringInfoCustom?.cost) / blisteringInfoGeneral.totalAmount,
          unitPrice: Number(blisteringInfoCustom?.cost) / Number(calculation.units),
          manufacturerId: blisteringInfoCustom?.manufacturerId || defaultManufacturer
        };
      }
      if (generalPrices?.bottling) {
        const bottlingInfoCustom = customCalculationInfo.find(cci => cci.type === "bottling");
        const bottlingInfoGeneral = generalPrices.bottling;
        customPrices.bottling = {
          price: Number(bottlingInfoCustom?.cost) / bottlingInfoGeneral.totalAmount,
          unitPrice: Number(bottlingInfoCustom?.cost) / Number(calculation.units),
          manufacturerId: bottlingInfoCustom?.manufacturerId || defaultManufacturer
        };
      }
      if (generalPrices?.encapsulation) {
        const encapsulationInfoCustom = customCalculationInfo.find(cci => cci.type === "encapsulation");
        const encapsulationInfoGeneral = generalPrices.encapsulation;
        customPrices.encapsulation = {
          price: Number(encapsulationInfoCustom?.cost) / encapsulationInfoGeneral.totalAmount,
          unitPrice: Number(encapsulationInfoCustom?.cost) / Number(calculation.units),
          manufacturerId: encapsulationInfoCustom?.manufacturerId || defaultManufacturer
        };
      }
      if (generalPrices?.tableting) {
        const tabletingInfoCustom = customCalculationInfo.find(cci => cci.type === "tableting");
        const tabletingInfoGeneral = generalPrices.tableting;
        customPrices.tableting = {
          price: Number(tabletingInfoCustom?.cost) / tabletingInfoGeneral.totalAmount,
          unitPrice: Number(tabletingInfoCustom?.cost) / Number(calculation.units),
          manufacturerId: tabletingInfoCustom?.manufacturerId || defaultManufacturer
        };
      }
      if (!!buffer) customPrices.marginBuffer = Number(buffer);

      if (!!optionalCost) {
        customPrices.optionalCosts = Number(optionalCost);
        if (+optionalCost === 0) delete customPrices.optionalCosts;
      }
      if (note) customPrices.note = note;

      calculation.priceDetails.customPrices = customPrices;
      if (customCalcState.some(ccS => ccS.calculationId.toString() === calcId.toString()))
        customCalcState = customCalcState.filter(ccS => ccS.calculationId.toString() !== calcId.toString());

      customCalcState.push({ ...customPrices, calculationId: calcId });
    }
    this.setState({ calculations: calculations, customCalculations: customCalcState }, () =>
      this.handleUpdateCalculation(calcId)
    );
  };

  /**
   * Reset custom calculation information for given calculation
   * @param calcId id of the calculation
   */
  handleResetCustomCalculation = (calcId: BSON.ObjectId) => {
    const calculations = _.cloneDeep(this.state.calculations);
    const calculation = calculations.find(c => calcId.toString() === c.id.toString());
    let customCalcs = _.cloneDeep(this.state.customCalculations);
    if (calculation?.priceDetails && customCalcs.some(cC => cC.calculationId.toString() === calcId.toString())) {
      delete calculation.priceDetails.customPrices;
      delete calculation.priceDetails.customUnitPrice;
      customCalcs = customCalcs.filter(cC => cC.calculationId.toString() !== calcId.toString());
    }
    this.setState({ calculations: calculations, customCalculations: customCalcs }, () =>
      this.handleUpdateCalculation(calcId)
    );
  };

  /**
   * Reset all custom calculations
   * @param calculations list of calculations to use
   * @returns {{ calculations: Array<CalculationType>, customCalculations: Array<CalculationCustomPriceDetails> }} object with all reset calculations and empty customCalculations array
   */
  getDefaultCalculations = (
    calculations?: Array<CalculationType>
  ): {
    calculations: Array<CalculationType>;
    customCalculations: Array<CalculationCustomPriceDetails>;
  } => {
    const calculationsCopy = _.cloneDeep(calculations ?? this.state.calculations);
    for (const c of calculationsCopy) {
      if (c.priceDetails?.customPrices) {
        delete c.priceDetails?.customPrices;
        delete c.priceDetails?.customUnitPrice;
      }
    }
    return { calculations: calculationsCopy, customCalculations: [] };
  };

  /**
   * Get the default state for a specific type
   * @param type product type to get the default state for
   * @param forceReset flag if data should be reset
   * @returns object with default data
   */
  getDefaultState = (type: string, forceReset?: boolean) => {
    const { preferences, productType, recipe } = this.state;
    const { capsules, tablets, manufacturers } = this.context;
    const prefs = ConfiguratorHelper.getDefaultPreferences(type);
    if (capsules.length > 0) {
      const defaultCapsule = capsules.find(cap => cap._id.toString() === "5e39b767491379a137dd5ffd");
      if (defaultCapsule) prefs.selectedCapsule = defaultCapsule;
      else prefs.selectedCapsule = capsules[0];
    }
    if (tablets.length > 0) prefs.selectedTablet = tablets[0];
    if (manufacturers.length > 0) {
      const filteredManufacturer = calculationUtils.getFilteredManufacturers(
        manufacturers,
        type,
        preferences.selectedCapsule
      );
      const ownManufacturer = userService.hasRole(ROLES.PRODUCTION, true)
        ? filteredManufacturer.find(man => manufacturerUtils.isEmployeeOfManufacturer(userService.getUserId(), man))
        : null;
      const revi = filteredManufacturer.find(man => man._id.toString() === "5ef4fd72a4cf4fbc3202f609");
      prefs.selectedManufacturer = ownManufacturer ? ownManufacturer : revi ? revi : filteredManufacturer[0];
    }
    let state: any = {
      step: 1,
      fullscreenMode: false,
      preferences: prefs
    };
    const isCustom = (type: string) => [ProductTypes.SOFTGEL, ProductTypes.CUSTOM, ProductTypes.SERVICE].includes(type);
    let reset: boolean = false;
    if (forceReset || isCustom(type) || isCustom(productType)) {
      reset = true;
      if (forceReset) state.customer = null;
      state.recipe = [];
      state.selectedPackaging = [];
      state.selectedCapsule = null;
      state.recipeVolume = { noDefault: true, value: 0 };
      state.calculations = [ConfiguratorHelper.getDefaultCalculation()];
    }
    if ([ProductTypes.LIQUID, ProductTypes.POWDER].includes(type) && !reset) {
      // Recalculate amount per unit
      state.preferences.amountPerUnit = recipe.reduce((a, b) => a + +b.amount!, 0);
    }
    // Set new capsule calculation
    if (prefs.selectedCapsule)
      state.selectedCapsule = ConfiguratorHelper.getDefaultSelectedCapsule(
        prefs.selectedCapsule,
        preferences.selectedManufacturer,
        +preferences.amountPerUnit,
        state.calculations || this.state.calculations,
        this.context
      );
    return state;
  };

  /**
   * Check if request is complete
   * @returns true if all required values are set, else false
   */
  isRequestComplete = () => {
    const { preferences, recipe, productType, calculations, customer, selectedPackaging } = this.state;
    let preferencesComplete = !!preferences.selectedManufacturer;
    switch (productType) {
      case ProductTypes.CAPSULES:
        preferencesComplete = preferencesComplete && !!preferences.selectedCapsule && +preferences.amountPerUnit > 0;
        break;
      case ProductTypes.TABLETS:
        preferencesComplete = preferencesComplete && !!preferences.selectedTablet && +preferences.amountPerUnit > 0;
        break;
      case ProductTypes.CUSTOM:
      case ProductTypes.SOFTGEL:
        preferencesComplete = preferencesComplete && +preferences.amountPerUnit > 0;
        break;
    }
    let calculationsComplete = calculations.length > 0 && calculations.every(calc => calc.percentMargin > 0);
    let noDisabledMaterial = !recipe.some(r => r.disabled) && !selectedPackaging.some(sp => sp.disabled);
    return noDisabledMaterial && preferencesComplete && calculationsComplete && recipe.length > 0 && !!customer;
  };

  /**
   * Create a new offer or update an offer/order
   * @param action enum indicating what to do: update, create or update and move back to offer state
   */
  handleCreateOffer = async (action: "update" | "create" | "updateToOffer") => {
    const { productType, preferences, recipe, selectedPackaging, calculations, customer, selectedCapsule } = this.state;
    if (!customer) return;
    let functionName = action === "create" ? "createOfferWithSpecSheet" : "updateOffer";
    const identifier =
      action === "update" ||
      (action === "updateToOffer" && this._originOrder && !orderUtils.isOrder(this._originOrder!))
        ? this._originOrder!.identifier
        : await dbService.callFunction("getOfferIdentifier", []);
    const order = ConfiguratorHelper.createOrderObject(
      productType,
      preferences,
      recipe,
      selectedPackaging,
      selectedCapsule,
      calculations,
      customer!,
      identifier,
      action === "create",
      action !== "create" && this._originOrder ? this._originOrder.contractInformation : undefined
    );
    if (!order) {
      toast.error("Order could not be created.");
      return;
    }
    // prevent submitting custom calculation which is undefined
    if (order.calculations.some(c => !c.info.customCalculation)) {
      order.calculations.map(c => {
        if (c.info.customCalculation === undefined) {
          delete c.info.customCalculation;
        }
      });
    }
    const offerPDF = await this.createOfferPDF(order, action);
    const calculationReport = await this.createCalculationReport(order);
    if (!offerPDF.result || !calculationReport.result) {
      toast.error("PDF creation failed: " + (!offerPDF.result ? offerPDF.message : calculationReport.message));
      return;
    }
    if (!offerPDF.path || !calculationReport.path) {
      toast.error("No PDF Filename found for " + (!offerPDF.path ? "offer pdf" : "calculation report"));
      return;
    }

    try {
      let functionArgs: Array<any> = [order!, calculationReport.path, offerPDF.path];
      if (action === "create" && this._requestedCollection === "request" && this._fromExisting && this._originRequest)
        functionArgs.push(this._originRequest);
      if (action === "update") functionArgs.push(this._originOrder);
      if (action === "updateToOffer") functionArgs = functionArgs.concat([this._originOrder, true]);
      let res = await dbService.callFunction(functionName, functionArgs);
      if (!res) toast.error("An unexpected error occurred");
      if (res && res.insertedId) {
        let message = "Offer successfully created";
        if (this._fromExisting && this._requestedCollection === "request" && this._originRequest) {
          message += " and request removed";
        }
        message += ". Forwarding to order page...";
        toast.success(message);
        await notificationService.notify(R_ORDERCREATED, res.insertedId);
        await slackService.sendMessage(
          "#notifications-offers",
          ConfiguratorHelper.getOfferSlackMessage(order, res.insertedId)
        );
        setTimeout(() => {
          window.location.href = "/order/" + res.insertedId.toString();
        }, 1000);
      } else if (res && res.modifiedCount) {
        toast.success("Update successful. Forwarding to order page...");
        if (this._originOrder)
          notificationService.notify(
            orderUtils.isOrder(this._originOrder) ? R_ORDERUPDATED : R_OFFERUPDATED,
            this._originOrder._id
          );
        setTimeout(() => {
          window.location.href = "/order/" + this._requestedId;
        }, 1000);
      }
    } catch (e) {
      toast.error("An unexpected error occurred: " + e.message);
    }
  };

  /**
   * Create the offer pdf
   * @param order the offer/order document
   * @param action action of the Order/offer edit
   * @returns result object with path or error message
   */
  createOfferPDF = async (order: OrdersDocument, action: "update" | "create" | "updateToOffer") => {
    const { preferences, customer } = this.state;

    const productSpecification =
      ["updateToOffer", "create"].includes(action) ||
      [OrderState.OFFER, OrderState.OFFER_PENDING].includes(
        this._originOrder ? this._originOrder.state : OrderState.OFFER
      );
    const data = JSON.stringify({
      html: offerPDFGeneration.createOffer(
        order,
        order.settings,
        customer as any,
        preferences.bulk || order.calculations[0].packagings.length === 0,
        false,
        this.context,
        productSpecification
      ),
      fileName: "AN-" + order.identifier + "_V1_" + dateUtils.timeStampDate() + ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  /**
   * Create a calculation report
   * @param order the order document
   * @returns result object with path or error message
   */
  createCalculationReport = async (order: OrdersDocument) => {
    const data = JSON.stringify({
      html: calculationReportGeneration.createCalculationProtocol(order.calculations, order.settings, this.context),
      fileName: "Report_AN-" + order.identifier + "_V1_" + dateUtils.timeStampDate() + ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  render() {
    const {
      step,
      fullscreenMode,
      productType,
      recipe,
      commodities,
      packaging,
      selectedPackaging,
      selectedCapsule,
      preferences,
      calculations,
      recipeVolume,
      customer,
      customCalculations
    } = this.state;
    const requestComplete = this.isRequestComplete();

    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet">
          <div className="kt-portlet__body kt-portlet__body--fit" style={{ minHeight: "75vh" }}>
            {packaging.length === 0 || commodities.length === 0 ? (
              <div className="align-middle my-auto">
                <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
              </div>
            ) : (
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2">
                <ConfiguratorTabs
                  productType={productType}
                  fullscreenMode={fullscreenMode}
                  currentStep={step}
                  changeStep={this.changeStep}
                  recipe={recipe}
                  selectedPackaging={selectedPackaging}
                  preferences={preferences}
                  customer={customer}
                  requestComplete={requestComplete}
                />
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <div className="kt-form" style={{ width: "100%" }}>
                    <div
                      className="kt-wizard-v2__content border-0"
                      data-ktwizard-type="step-content"
                      data-ktwizard-state="current"
                    >
                      <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                          <span className="kt-portlet__head-icon">
                            <i className="kt-font-brand fa fa-calculator" />
                          </span>
                          <h3 className="kt-portlet__head-title">{steps[step.toString()].headline}</h3>
                          <div className={step === 1 ? "d-block" : "d-none"}>
                            <LoadRecipeModal onLoadRecipe={this.handleLoadRecipe} />
                          </div>
                          <button
                            type="button"
                            className={
                              "btn btn-sm ml-3 pt-1 pb-1 pl-2 pr-2 " +
                              (!fullscreenMode ? "btn-secondary" : "btn-success")
                            }
                            onClick={this.toggleFullscreen}
                          >
                            Fullscreen
                          </button>
                          {step === 1 && (
                            <SimpleConfirmationModal.SimpleConfirmationModalButton
                              buttonClasses="btn btn-sm btn-secondary ml-3 pt-1 pb-1 pl-2 pr-2"
                              buttonText={"Reset All"}
                              modalTitle={"Reset Configuration"}
                              modalDescription={
                                "A new empty configuration will be created. Unsaved work and any reference to a loaded request or order will be lost."
                              }
                              confirmButtonText={"Ok"}
                              cancelButtonText={"Cancel"}
                              onConfirm={this.resetConfigurator}
                              buttonId="restConfiguration"
                            />
                          )}
                        </div>
                        <div className="kt-portlet__head-toolbar">
                          <a onClick={this.previousStep} className={"btn btn-clean kt-margin-r-10"}>
                            <i className="la la-arrow-left" />
                            <span className="kt-hidden-mobile">Back</span>
                          </a>
                          <a onClick={this.nextStep} className={"btn btn-clean " + (step === 4 && "d-none")}>
                            <span className="kt-hidden-mobile">Next</span>
                            <i className="la la-arrow-right" />
                          </a>
                        </div>
                      </div>
                      <div id="content">
                        {step === 1 && (
                          <RecipeSelection
                            activeType={productType}
                            commodities={commodities}
                            recipe={recipe}
                            fullscreen={fullscreenMode}
                            calculations={calculations}
                            preferences={preferences}
                            recipeVolume={recipeVolume}
                            onPreferenceChange={this.handlePreferencesChange}
                            onCapsuleChange={this.handleCapsuleChange}
                            onProductTypeChange={this.handleProductTypeChange}
                            onRecipeAdd={this.handleRecipeAdd}
                            onRecipeSave={this.handleRecipeSave}
                            onRecipeDelete={this.handleRecipeDelete}
                            onDraggableItemMove={this.handleDraggableItemMove}
                            onRecalculate={this.handleRecalculate}
                            onRecalculateSpecific={this.handleRecalculateSpecific}
                            onCalculationPropertyChange={this.handleCalculationPropertyChange}
                          />
                        )}
                        {step === 2 && !preferences.bulk && (
                          <PackagingSelection
                            activeType={productType}
                            fullscreen={fullscreenMode}
                            preferences={preferences}
                            recipeVolume={recipeVolume}
                            packaging={packaging}
                            calculations={calculations}
                            selectedPackaging={selectedPackaging}
                            onPackagingSelect={this.handlePackagingSelect}
                            onPackagingAmount={this.handlePackagingAmount}
                          />
                        )}
                        {step === 3 && (
                          <CustomerSelection
                            fullscreen={fullscreenMode}
                            selectedCustomer={customer}
                            userdata={this.context.userdata}
                            companies={this.context.companies}
                            onCustomerSelect={this.handleCustomerSelect}
                          />
                        )}
                        {step === 4 && (
                          <Calculation
                            activeType={productType}
                            preferences={preferences}
                            calculations={calculations}
                            recipe={recipe}
                            selectedPackaging={selectedPackaging}
                            selectedCapsule={selectedCapsule}
                            customCalculations={customCalculations}
                            onCalculationPropertyChange={this.handleCalculationPropertyChange}
                            onCalculationAdd={this.handleCalculationAdd}
                            onCalculationDelete={this.handleCalculationDelete}
                            onRecalculateSpecific={this.handleRecalculateSpecific}
                            onUpdateCalculation={this.handleUpdateCalculation}
                            onCapsuleCalculationUpdate={this.handleCapsuleCalculationUpdate}
                            onRecipeCalculationUpdate={this.handleRecipeCalculationUpdate}
                            onPackagingCalculationUpdate={this.handlePackagingCalculationUpdate}
                            onCapsuleSupplierChange={this.handleCapsuleSupplierChange}
                            onPackagingSupplierChange={this.handlePackagingSupplierChange}
                            onRecipeSupplierChange={this.handleRecipeSupplierChange}
                            onSaveCustomCalculation={this.handleSaveCustomCalculation}
                            onResetCustomCalculation={this.handleResetCustomCalculation}
                          />
                        )}
                      </div>
                      {step === 4 && (
                        <div
                          className={
                            "kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded"
                          }
                          style={{ width: "100%" }}
                        >
                          <div className="float-right">
                            {recipe.length > 0 && !userService.hasRole(ROLES.MIXMASTERS, true) && (
                              <CreateSampleRecipeModal
                                activeType={productType}
                                preferences={preferences}
                                calculations={calculations}
                                recipe={recipe}
                                selectedPackaging={selectedPackaging}
                              />
                            )}
                            {requestComplete && (
                              <>
                                <CalculationReportPreview
                                  activeType={productType}
                                  preferences={preferences}
                                  calculations={calculations}
                                  recipe={recipe}
                                  selectedPackaging={selectedPackaging}
                                  selectedCapsule={selectedCapsule}
                                  customer={customer}
                                />
                                <OfferPdfPreview
                                  activeType={productType}
                                  preferences={preferences}
                                  calculations={calculations}
                                  recipe={recipe}
                                  selectedPackaging={selectedPackaging}
                                  selectedCapsule={selectedCapsule}
                                  customer={customer}
                                />
                                <ConfigurationConfirmationModal
                                  activeType={productType}
                                  preferences={preferences}
                                  calculations={calculations}
                                  recipe={recipe}
                                  selectedPackaging={selectedPackaging}
                                  selectedCapsule={selectedCapsule}
                                  onPreferenceChange={this.handlePreferencesChange}
                                  onCreateOffer={this.handleCreateOffer}
                                  originOrder={this._originOrder}
                                  customer={customer}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Configurator;
