import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BSON } from "realm-web";
import { toAbsoluteUrl } from "../../../_metronic";
import { DataContext } from "../../../context/dataContext";
import {
  CommodityBatch,
  CommodityBatchFile,
  CommoditiesDocument,
  CommodityOrder
} from "../../../model/commodities.types";
import { OrdersDocument } from "../../../model/orders.types";
import dbService, { UpdateAction, COMMODITIES, EMORDERS, ORDERS } from "../../../services/dbService";
import dbCommodityService from "../../../services/dbServices/dbCommodityService";
import userService from "../../../services/userService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import baseUtils from "../../../utils/baseUtils";
import commodityUtils from "../../../utils/commodityUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import orderUtils from "../../../utils/orderUtils";
import { T_COMMODITYATWAREHOUSE, T_COMMODITYDELIVERED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import DateInput from "../../common/DateInput";
import { EM_DELIVERED } from "../../externalManufacturers/ExternalManufacturerHelper";
import UploadMaterialFileModal from "../../common/UploadMaterialFileModal";
import fileUtils from "../../../utils/fileUtils";
import dateUtils from "../../../utils/dateUtils";
import { CustomOrder } from "../../order/CustomTypes";
import { ROLES } from "../../../utils/userdataUtils";
import manufacturerUtils from "../../../utils/manufacturerUtils";

interface DeliverCommodityOrderModalProps {
  commodity: CommoditiesDocument;
  commodityOrder: CommodityOrder;
  context: React.ContextType<typeof DataContext>;
  order?: CustomOrder;
  buttonCSSClasses?: string;
}

interface DeliverCommodityOrderModalState {
  show: boolean;
  density: string;
  expiry: Date;
  delivery: Date;
  lot: string;
  note: string;
  saving: boolean;
  showUploadFileModal: boolean;
  files: Array<CommodityBatchFile>;
  deliveredAmount: string;
}

class DeliverCommodityOrderModal extends PureComponent<
  DeliverCommodityOrderModalProps,
  DeliverCommodityOrderModalState
> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: DeliverCommodityOrderModalProps) {
    super(props);
    this.state = this.getDefaultStateValues(props.commodity, props.commodityOrder, props.order);
  }

  componentDidUpdate(prevProps: Readonly<DeliverCommodityOrderModalProps>) {
    if (!_.isEqual(prevProps.commodityOrder, this.props.commodityOrder)) {
      this.setState(this.getDefaultStateValues(this.props.commodity, this.props.commodityOrder, this.props.order));
    }
  }

  /**
   * Get the default state values.
   * @param commodity: Commodity whose values should be taken from
   * @param commodityOrder: Commodity order, lot and expiry are taken from it if they were set there
   * @param order: Order, if set we know that we are inside the order and not the commodity
   * @returns { DeliverCommodityOrderModalState } Default state values
   */
  getDefaultStateValues = (commodity: CommoditiesDocument, commodityOrder: CommodityOrder, order?: CustomOrder) => {
    const expiry = new Date();
    // Default expiry date is 2 years into the future
    expiry.setDate(expiry.getDate() + 365 * 2);
    let stockTransferOrder;
    if (order) {
      stockTransferOrder = commodityOrder.stockTransferOrders?.find(s =>
        s.orderIds.map(oid => oid.toString()).includes(order._id.toString())
      );
    }
    let warehouseBatch;
    if (commodityOrder.warehouseBatch) {
      warehouseBatch = commodity.stock.find(b => b._id === commodityOrder.warehouseBatch);
    }
    return {
      show: false,
      density: commodity.density ? commodity.density!.toString() : "0.5",
      expiry: warehouseBatch ? warehouseBatch.expiry : commodityOrder.expiry ? commodityOrder.expiry : expiry,
      delivery: new Date(),
      lot: commodityOrder.lot ? commodityOrder.lot : "",
      note: "",
      saving: false,
      showUploadFileModal: false,
      showUploadButton: true,
      files: warehouseBatch ? warehouseBatch.files : [],
      deliveredAmount: stockTransferOrder
        ? stockTransferOrder.amount.toString()
        : commodityOrder.orderquantity.toString()
    };
  };

  handleShow = () => this.setState({ show: true });
  handleHide = () =>
    this.setState(this.getDefaultStateValues(this.props.commodity, this.props.commodityOrder, this.props.order));

  /**
   * Handles a change in input fields.
   * @param e: Event that triggered the change
   */
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    let val: string | number | Date = e.target.value;
    if (name === "density") {
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if (!val || +val < 0) return;
    } else if (["expiry", "delivery"].includes(name)) {
      val = new Date(val);
      if (isNaN(val.getTime())) return;
    }
    // @ts-ignore
    this.setState({ [name]: val });
  };

  handleClickDeliverSTO = async () => {
    const { commodity, context, order } = this.props;
    const { density, delivery, expiry, lot, note, files, deliveredAmount } = this.state;
    const commodityOrder = _.cloneDeep(this.props.commodityOrder);
    const sto = commodityOrder.stockTransferOrders?.find(
      sto => sto.destination.toString() === order?.settings.manufacturer._id.toString()
    );
    if (!order || !commodityOrder.stockTransferOrders || !sto) return;
    this.setState({ saving: true });
    const batch: CommodityBatch = {
      supplier: new BSON.ObjectId(commodityOrder.supplier!.toString()),
      _id: new BSON.ObjectId().toString(),
      amount: Number(deliveredAmount),
      lot,
      location: new BSON.ObjectId(sto.destination),
      note,
      price: commodityOrder.totalPrice / commodityOrder.orderquantity,
      stocked: delivery,
      expiry,
      orders: sto.orderIds,
      files
    };
    let success;
    const lastSTO = commodityOrder.stockTransferOrders.filter(sto => !sto.delivered).length === 1;
    if (lastSTO) commodityOrder.delivered = delivery;
    sto.lot = lot;
    sto.delivered = delivery;
    sto.personDelivered = userService.getUserId();
    const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(this.props.commodityOrder, commodityOrder);
    const actions: Array<UpdateAction> = [
      {
        collection: COMMODITIES,
        filter: { _id: commodity._id, "orders._id": commodityOrder._id },
        update: { "orders.$": commodityOrder, density },
        push: { stock: batch, timeline: timelineUpdate }
      },
      {
        collection: COMMODITIES,
        filter: { _id: commodity._id },
        inc: { "stock.$[batch].amount": -Number(deliveredAmount) },
        arrayFilters: [{ "batch._id": commodityOrder.warehouseBatch }]
      }
    ];
    const timeline = {
      _id: new BSON.ObjectId(),
      type: T_COMMODITYDELIVERED,
      date: new Date(),
      amount: commodityOrder.orderquantity,
      commodity: commodity._id,
      supplier: commodityOrder.supplier,
      person: userService.getUserId(),
      price: commodityOrder.totalPrice
    };
    // Update orders
    for (let i = 0; i < sto.orderIds.length; i++) {
      const order: OrdersDocument = baseUtils.getDocFromCollection(context.orders, sto.orderIds[i].toString());
      if (order) {
        const pricePre = order.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
        const pricePost = _.cloneDeep(pricePre);
        const newUnitPrice = commodityOrder.totalPrice / commodityOrder.orderquantity;
        const newPurchasePrice = commodityOrder.purchasePrice / commodityOrder.orderquantity;
        pricePost.delivered = delivery;
        pricePost.userDelivered = userService.getUserId();
        pricePost.totalprice = newUnitPrice * pricePre.orderquantity!;
        pricePost.price = newUnitPrice;
        pricePost.purchasePrice = newPurchasePrice;
        pricePost.purchaseCurrency = commodityOrder.currency;
        // Recalculate price information
        const newInfo = orderCalculationUtils.getCalculationInfoAfterPriceChange(order, pricePre, pricePost);
        // Check if the state has to be updated
        const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
        actions.push({
          collection: ORDERS,
          filter: { _id: order._id },
          update: {
            "calculations.0.prices.$[c].delivered": pricePost.delivered,
            "calculations.0.prices.$[c].userDelivered": pricePost.userDelivered,
            "calculations.0.prices.$[c].totalprice": pricePost.totalprice,
            "calculations.0.prices.$[c].price": pricePost.price,
            "calculations.0.prices.$[c].purchasePrice": pricePost.purchasePrice,
            "calculations.0.prices.$[c].purchaseCurrency": pricePost.purchaseCurrency,
            "calculations.0.info": newInfo,
            state: newState ? newState : order.state
          },
          push: { timeline },
          arrayFilters: [{ "c._id": commodity._id }]
        });
      } else {
        throw Error(`Order ${sto.orderIds[i].toString()} could not be found. Aborting update`);
      }
    }
    success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      success,
      "Commodity order delivered successfully",
      "Error delivering commodity order",
      () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
        sto.orderIds.forEach(o => context.updateDocumentInContext(ORDERS, o));
      }
    );
    if (success) this.handleHide();
    else this.setState({ saving: false });
  };

  /**
   * Handles clicking the delivery button. Updates the commodity.
   */
  handleClickDeliver = async () => {
    const { commodity, context } = this.props;
    const { density, delivery, expiry, lot, note, files, deliveredAmount } = this.state;
    const commodityOrder = _.cloneDeep(this.props.commodityOrder);
    const toWarehouse = commodityOrder.warehouseDestination && !commodityOrder.arrivedAtWarehouse;
    this.setState({ saving: true });
    // Build new batch
    const batch: CommodityBatch = {
      supplier: new BSON.ObjectId(commodityOrder.supplier!.toString()),
      _id: new BSON.ObjectId().toString(),
      amount: toWarehouse ? Number(deliveredAmount) : commodityOrder.orderquantity,
      lot,
      location: toWarehouse
        ? new BSON.ObjectId(commodityOrder.warehouseDestination!)
        : new BSON.ObjectId(commodityOrder.destination!),
      note,
      price: commodityOrder.totalPrice / commodityOrder.orderquantity,
      stocked: delivery,
      expiry,
      orders: commodityOrder.orders,
      files: files
    };
    let success;
    const cOOld = _.cloneDeep(commodityOrder);
    if (toWarehouse) {
      commodityOrder.arrivedAtWarehouse = delivery;
      commodityOrder.warehouseBatch = batch._id;
    }
    commodityOrder.delivered = delivery;
    commodityOrder.lot = lot;
    commodityOrder.expiry = expiry;
    const timelineUpdate = commodityUtils.prepareCommodityOrderTimeline(cOOld, commodityOrder);
    // If there are orders relating to that commodity order we need to update them too
    if (
      commodityOrder.orders.length > 0 ||
      (commodityOrder.relatedEMOrders && commodityOrder.relatedEMOrders.length > 0)
    ) {
      // Update commodity
      const actions: Array<UpdateAction> = [
        {
          collection: COMMODITIES,
          filter: { _id: commodity._id, "orders._id": commodityOrder._id },
          update: { "orders.$": commodityOrder, density },
          push: { stock: batch, timeline: timelineUpdate }
        }
      ];
      if (!toWarehouse) {
        const timeline = {
          id: new BSON.ObjectId(),
          type: T_COMMODITYDELIVERED,
          date: new Date(),
          amount: commodityOrder.orderquantity,
          commodity: commodity._id,
          supplier: commodityOrder.supplier,
          person: userService.getUserId(),
          price: commodityOrder.totalPrice
        };
        // Update orders
        for (let i = 0; i < commodityOrder.orders.length; i++) {
          const order: OrdersDocument = baseUtils.getDocFromCollection(
            context.orders,
            commodityOrder.orders[i].toString()
          );
          if (order) {
            const pricePre = order.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!;
            const pricePost = _.cloneDeep(pricePre);
            const newUnitPrice = commodityOrder.totalPrice / commodityOrder.orderquantity;
            const newPurchasePrice = commodityOrder.purchasePrice / commodityOrder.orderquantity;
            pricePost.delivered = delivery;
            pricePost.userDelivered = userService.getUserId();
            pricePost.totalprice = newUnitPrice * pricePre.orderquantity!;
            pricePost.price = newUnitPrice;
            pricePost.purchasePrice = newPurchasePrice;
            pricePost.purchaseCurrency = commodityOrder.currency;
            // Recalculate price information
            const newInfo = orderCalculationUtils.getCalculationInfoAfterPriceChange(order, pricePre, pricePost);
            // Check if the state has to be updated
            const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
            actions.push({
              collection: ORDERS,
              filter: { _id: order._id },
              update: {
                "calculations.0.prices.$[c].delivered": pricePost.delivered,
                "calculations.0.prices.$[c].userDelivered": pricePost.userDelivered,
                "calculations.0.prices.$[c].totalprice": pricePost.totalprice,
                "calculations.0.prices.$[c].price": pricePost.price,
                "calculations.0.prices.$[c].purchasePrice": pricePost.purchasePrice,
                "calculations.0.prices.$[c].purchaseCurrency": pricePost.purchaseCurrency,
                "calculations.0.info": newInfo,
                state: newState ? newState : order.state
              },
              push: { timeline },
              arrayFilters: [{ "c._id": commodity._id }]
            });
          } else {
            throw Error(`Order ${commodityOrder.orders[i].toString()} could not be found. Aborting update`);
          }
        }
      } else {
        const timeline = {
          id: new BSON.ObjectId(),
          type: T_COMMODITYATWAREHOUSE,
          commodity: commodity._id,
          date: new Date(),
          person: userService.getUserId()
        };
        for (let i = 0; i < commodityOrder.orders.length; i++) {
          const o = commodityOrder.orders[i];
          actions.push({
            collection: ORDERS,
            filter: { _id: o },
            update: { "calculations.0.prices.$[c].arrivedAtWarehouse": new Date() },
            push: { timeline },
            arrayFilters: [{ "c._id": commodity._id }]
          });
        }
      }
      if (commodityOrder.relatedEMOrders) {
        for (let i = 0; i < commodityOrder.relatedEMOrders.length; i++) {
          const order = commodityOrder.relatedEMOrders[i];
          actions.push({
            collection: EMORDERS,
            filter: { _id: order },
            update: {
              state: EM_DELIVERED
            },
            push: {
              timeline: {
                _id: new BSON.ObjectId(),
                type: EM_DELIVERED,
                date: new Date(),
                person: userService.getUserId()
              }
            }
          });
        }
      }

      success = await dbService.updatesAsTransaction(actions);
    } else {
      // Update commodity order, stock and density
      const res = await dbCommodityService.deliverCommodityOrder(
        commodity._id,
        commodityOrder,
        batch,
        timelineUpdate!,
        +density
      );
      success = !!res && res.modifiedCount > 0;
    }
    await toastUtils.databaseOperationToast(
      success,
      "Commodity order delivered successfully",
      "Error delivering commodity order",
      () => {
        context.updateDocumentInContext(COMMODITIES, commodity._id);
        commodityOrder.orders.forEach(o => context.updateDocumentInContext(ORDERS, o));
        if (commodityOrder.relatedEMOrders)
          commodityOrder.relatedEMOrders.forEach(o => context.updateDocumentInContext(EMORDERS, o));
      }
    );
    if (success) this.handleHide();
    else this.setState({ saving: false });
  };

  /**
   * Handles adding a new file for the batch
   * @param id: Not needed, kept for comparability
   * @param path: Path to the file
   * @param type: Not needed, kept for comparability
   * @param title: Title of the file
   */
  handleAddUpload = (id: BSON.ObjectId, path: string, type: string, title?: string) => {
    const fileType = fileUtils.getFileExtension(path);
    const batchFile = [];
    batchFile.push({
      type: "file",
      date: new Date(),
      person: userService.getUserId(),
      path,
      title: title ? title : "COA",
      category: "file",
      fileType: fileType === null ? "" : fileType[1],
      fileSize: 0
    });
    this.setState({ files: batchFile, showUploadFileModal: false });
  };

  /**
   * function to delete uploaded files
   * @param path of the file to delete
   */
  handleDeleteUpload = (path: string) => {
    const files = this.state.files.filter(f => f.path !== path);
    this.setState({ files });
  };

  /**
   * Check the input values for errors.
   * @returns { Array<string> } Contains all errors
   */
  checkForErrors = () => {
    const { density, expiry, lot } = this.state;
    const errors = [];
    if (!density || +density <= 0) errors.push("Density has to be above 0");
    if (!lot || lot.length < 3) errors.push("LOT has to be at least 3 characters long");
    if (!expiry || expiry.getTime() < new Date().getTime()) errors.push("Expiry has to be in the future");
    return errors;
  };

  render() {
    const { buttonCSSClasses, commodity, commodityOrder, order } = this.props;
    const { delivery, density, expiry, lot, note, saving, show, files, showUploadFileModal, deliveredAmount } =
      this.state;
    const errors = this.checkForErrors();
    const man = baseUtils.getDocFromCollection(this.context.manufacturers, commodityOrder.destination!);
    const sto = commodityOrder.stockTransferOrders?.find(
      sto => order && sto.orderIds.some(o => o.toString() === order._id.toString())
    );
    const manSTO = sto ? baseUtils.getDocFromCollection(this.context.manufacturers, sto.destination) : undefined;
    // Following roles can deliver: Production, Procurement, Admin - but Production should only be able to deliver the
    // orders that are send to their manufacturer. Thus, we need the destination check only if the user is Production.
    const canDeliver =
      accessUtils.canPerformAction(ACTIONS.COMMODITYDELIVER) &&
      (!userService.hasRole(ROLES.PRODUCTION, true) ||
        manufacturerUtils.isEmployeeOfManufacturer(userService.getUserId(), man) ||
        (manSTO && manufacturerUtils.isEmployeeOfManufacturer(userService.getUserId(), manSTO)));
    const toWarehouse = !!(commodityOrder.warehouseDestination && !commodityOrder.arrivedAtWarehouse);

    return (
      <>
        <button
          className={
            (buttonCSSClasses ? buttonCSSClasses : "btn btn-success") +
            (canDeliver && !(order && toWarehouse) ? "" : " disabled")
          }
          onClick={canDeliver && !(order && toWarehouse) ? this.handleShow : undefined}
          style={{ minWidth: "72px" }}
          disabled={!canDeliver || (order && toWarehouse)}
        >
          Deliver
        </button>
        <Modal
          show={show}
          onHide={this.handleHide}
          style={showUploadFileModal ? { zIndex: "99" } : {}}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Mark Order of {commodity.title.en} as delivered{toWarehouse && " to warehouse"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!commodity.type && (
              <>
                {commodity.density ? (
                  <span className="kt-font-bold kt-font-dark">
                    It is recommended to refine the bulk density from time to time. The average bulk density value is{" "}
                    <span className="text-success">
                      <u>
                        {commodity.density} g / cm<sup>3</sup>
                      </u>
                    </span>
                    . If there are deviations, you may refine the bulk density value slightly.
                  </span>
                ) : (
                  <span className="kt-font-bold text-danger">
                    This commodity has an unknown bulk density. Please check and update the bulk density.
                  </span>
                )}
                <div className="form-group row my-5">
                  <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Bulk Density</label>
                  <div className="col-9">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        value={density}
                        name="density"
                        onChange={this.handleChange}
                        min={0}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          g / cm<sup>3</sup>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Delivery date</label>
              <div className="col-9">
                <DateInput value={delivery} max={new Date()} onBlur={this.handleChange} name={"delivery"} />
              </div>
            </div>
            {commodityOrder.warehouseDestination && (
              <div className="form-group row my-5">
                <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Delivered Amount</label>
                <div className="col-9">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      value={deliveredAmount}
                      name="deliveredAmount"
                      onChange={order ? undefined : this.handleChange}
                      disabled={!!order}
                      min={0}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">kg</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <span className="kt-font-bold kt-font-dark">
                Please enter some the following information for the batch.
              </span>
            </div>
            <div className="form-group row mt-5">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">LOT</label>
              <div className="col-9">
                <input
                  className="form-control"
                  type="text"
                  value={lot}
                  name="lot"
                  onChange={order && commodityOrder.lot ? undefined : this.handleChange}
                  disabled={!!(order && commodityOrder.lot)}
                  placeholder="LOT"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Expiry</label>
              <div className="col-9">
                <input
                  className="form-control"
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  value={expiry.toISOString().split("T")[0]}
                  name="expiry"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Note</label>
              <div className="col-9">
                <textarea className="form-control" rows={3} value={note} name="note" onChange={this.handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label kt-font-dark kt-font-bold text-right">Files</label>
              <div className="col-9">
                {files.map((f, key) => (
                  <div className="kt-widget4 mb-2" key={f.path + f.date.toString() + key}>
                    <div className="kt-widget4__item">
                      <div className="kt-widget4__pic kt-widget4__pic--icon">
                        <img src={toAbsoluteUrl("/media/icons/pdf_icon.png")} alt="pdf icon" />
                      </div>
                      <div className="kt-widget4__info">
                        <div>
                          <a
                            href={f.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="kt-widget4__username mr-2"
                          >
                            {(f.title ? f.title : "File #" + (key + 1)) + " - " + baseUtils.formatDate(f.date)}
                          </a>
                        </div>
                        <p className="kt-widget4__text">{dateUtils.getTimeAgo(f.date)}</p>
                      </div>
                      <button className="btn btn-danger btn-sm p-2" onClick={() => this.handleDeleteUpload(f.path)}>
                        <i className="flaticon2-cross px-1" />
                      </button>
                    </div>
                  </div>
                ))}
                {files.length === 0 && (
                  <button className="btn btn-secondary" onClick={() => this.setState({ showUploadFileModal: true })}>
                    Upload
                  </button>
                )}
              </div>
              {showUploadFileModal && (
                <UploadMaterialFileModal
                  material={commodity}
                  type=""
                  addUpload={this.handleAddUpload}
                  onClose={() => this.setState({ showUploadFileModal: false })}
                  hideSupplier={true}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={this.handleHide}>
              Close
            </button>
            {errors.length > 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id={"commodity-invalid"}>
                    {errors.map((e, key) => {
                      return (
                        <React.Fragment key={key}>
                          <span className="text-danger">
                            <b>{e}</b>
                          </span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </Tooltip>
                }
                placement={"left"}
              >
                <button className="btn btn-success disabled">Deliver</button>
              </OverlayTrigger>
            ) : (
              <button
                className={"btn btn-success" + (saving ? " disabled" : "")}
                disabled={saving}
                onClick={
                  order && commodityOrder.warehouseDestination ? this.handleClickDeliverSTO : this.handleClickDeliver
                }
              >
                Deliver
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DeliverCommodityOrderModal;
