import React from "react";
import Select from "react-select";
import { ManufacturerFilter, MarginFilter, OwnerFilter, PriorityFilter, SearchBar } from "./common/Filters";
import orderUtils from "../../utils/orderUtils";
import { T_CAPSULE, T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL, T_TABLET } from "../order/OrderHelper";
import { UserdataDocument } from "../../model/userdata.types";
import { ManufacturersDocument } from "../../model/manufacturers.types";
import {
  V_FULFILLMENT,
  V_INVOICING,
  V_ORDERCOMMODITIES,
  V_ORDERPACKAGING,
  V_PRODUCTION,
  V_READY,
  V_WAITING,
  VIEWS,
  VIEWS_PRODUCTION
} from "./orderListingUtils";
import DateInput from "../common/DateInput";
import accessUtils from "../../utils/accessUtils";
import { OrderState } from "../../model/orders.types";

export const FILTER_STATES = [
  OrderState.OFFER,
  OrderState.OFFER_PENDING,
  OrderState.OFFER_APPROVED,
  OrderState.OFFER_RELEASED,
  OrderState.ORDER_COMMODITIES,
  OrderState.CONTRACT,
  OrderState.WAITING,
  OrderState.PRODUCTION_QUEUE,
  OrderState.PRODUCTION,
  OrderState.FULFILLMENT,
  OrderState.CREATE_INVOICE
].map(state => {
  return {
    value: state,
    label: orderUtils.getStateDescription(state)!
  };
});

interface OrdersFilterProps {
  search: string;
  filterState: "" | { value: string; label: string };
  owner: "" | { value: string; label: string };
  minVolume: "" | { value: string; label: string };
  maxVolume: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  priority: "" | { value: string; label: string };
  margin: "" | { value: string; label: string };
  manufacturerLocked: boolean;
  period: { start: Date; end: Date };
  filterType: "" | { value: string; label: string };
  dateType: { value: string; label: string };
  sortValue: { value: "AT"; label: "AT-Number" } | { value: string; label: string };
  sortOrder: { value: "asc"; label: "Ascending" } | { value: "desc"; label: "Descending" };
  view: "" | { value: string; label: string };
  invoiceFilter: "" | { value: string; label: string };
  selectableUsers: Array<UserdataDocument>;
  manufacturers: Array<ManufacturersDocument>;
  onSelectChange: (name: string, entry: { value: string; label: string } | "") => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPeriodChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onViewChange: (entry: string | "") => void;
}

const OrdersFilter: React.FunctionComponent<OrdersFilterProps> = ({
  search,
  filterState,
  owner,
  minVolume,
  maxVolume,
  manufacturer,
  manufacturerLocked,
  priority,
  margin,
  period,
  filterType,
  sortOrder,
  sortValue,
  dateType,
  view,
  invoiceFilter,
  selectableUsers,
  manufacturers,
  onSelectChange,
  onSearch,
  onPeriodChange,
  onViewChange
}) => {
  const canSeeFinanceData = accessUtils.canSeeFinanceData();
  return (
    <>
      <div className="row align-items-center">
        <SearchBar onSearch={onSearch} search={search} />
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            className="select-default"
            options={[
              { value: "AT", label: "AT-Number" },
              { value: "TITLE", label: "Title" },
              { value: "CUSTOMER", label: "Customer" },
              { value: "DATE", label: "Order Date" },
              { value: "VOLUME", label: "Volume" },
              { value: "DELIVERY", label: "Delivery/Target Date" }
            ]}
            value={sortValue}
            onChange={(value: any) => onSelectChange("sortValue", value)}
          />
        </div>
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            className="select-default"
            options={[
              { value: "asc", label: "Ascending " },
              { value: "desc", label: "Descending" }
            ]}
            value={sortOrder}
            onChange={(value: any) => onSelectChange("sortOrder", value)}
          />
        </div>
        <OwnerFilter owner={owner} selectableUsers={selectableUsers} onFilterChange={onSelectChange} noLabel={true} />
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            className="select-default"
            isClearable={true}
            isDisabled={[
              V_ORDERPACKAGING,
              V_ORDERCOMMODITIES,
              V_WAITING,
              V_READY,
              V_PRODUCTION,
              V_FULFILLMENT
            ].includes(view ? view.value : "")}
            options={FILTER_STATES}
            value={filterState ? filterState : { value: "", label: "All States" }}
            onChange={(value: any) => onSelectChange("filterState", value || "")}
          />
        </div>
        <ManufacturerFilter
          manufacturer={manufacturer}
          manufacturerLocked={manufacturerLocked}
          manufacturers={manufacturers}
          onFilterSelect={onSelectChange}
          additionalSizeClasses={"col-md-2"}
          noLabel={true}
        />
      </div>
      <div className="row align-items-center mt-3">
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <DateInput value={period.start} onBlur={onPeriodChange} name={"start"} />
        </div>
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <DateInput value={period.end} onBlur={onPeriodChange} name={"end"} />
        </div>
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            className="select-default"
            options={[
              { value: "ordered", label: "Order Date" },
              { value: "invoice", label: "Invoice Date" },
              { value: "delivery", label: "Delivery/Target CW" }
            ]}
            value={dateType}
            onChange={(value: any) => onSelectChange("dateType", value)}
          />
        </div>
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            isClearable={true}
            className="select-default"
            options={[
              { value: "", label: "All Types" },
              { value: T_CAPSULE, label: "Capsules" },
              { value: T_TABLET, label: "Tablets" },
              { value: T_LIQUID, label: "Liquids" },
              { value: T_POWDER, label: "Powders" },
              { value: T_SOFTGEL, label: "Softgels" },
              { value: T_CUSTOM, label: "Purchased" },
              {
                value: T_SERVICE,
                label: "Services",
                isDisabled: [
                  V_ORDERPACKAGING,
                  V_ORDERCOMMODITIES,
                  V_WAITING,
                  V_PRODUCTION,
                  V_READY,
                  V_FULFILLMENT,
                  V_INVOICING
                ].includes(view ? view.value : "")
              }
            ]}
            value={filterType ? filterType : { value: "", label: "All Types" }}
            onChange={(value: any) => onSelectChange("filterType", value || "")}
          />
        </div>
        {!view ? (
          <>
            <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                isClearable={true}
                isDisabled={!canSeeFinanceData}
                options={[
                  { value: "0", label: "0€" },
                  { value: "2500", label: "2.500€" },
                  { value: "5000", label: "5.000€" },
                  { value: "10000", label: "10.000€" },
                  { value: "25000", label: "25.000€" },
                  { value: "50000", label: "50.000€" },
                  { value: "100000", label: "100.000€" },
                  { value: "250000", label: "250.000€" }
                ]}
                value={minVolume ? minVolume : { value: "", label: "Min. Volume" }}
                onChange={canSeeFinanceData ? (value: any) => onSelectChange("minVolume", value || "") : undefined}
              />
            </div>
            <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                className="select-default"
                isClearable={true}
                isDisabled={!canSeeFinanceData}
                options={[
                  { value: "2500", label: "2.500€" },
                  { value: "5000", label: "5.000€" },
                  { value: "10000", label: "10.000€" },
                  { value: "25000", label: "25.000€" },
                  { value: "50000", label: "50.000€" },
                  { value: "100000", label: "100.000€" },
                  { value: "250000", label: "250.000€" },
                  { value: "500000", label: "500.000€" },
                  { value: "1000000", label: "1.000.000€" }
                ]}
                value={maxVolume ? maxVolume : { value: "", label: "Max. Volume" }}
                onChange={canSeeFinanceData ? (value: any) => onSelectChange("maxVolume", value || "") : undefined}
              />
            </div>
          </>
        ) : [V_ORDERPACKAGING, V_ORDERCOMMODITIES, V_WAITING, V_PRODUCTION, V_READY, V_FULFILLMENT].includes(
            view.value
          ) ? (
          <>
            <PriorityFilter priority={priority} onFilterSelect={onSelectChange} noLabel={true} />
            <MarginFilter
              margin={margin}
              onFilterSelect={onSelectChange}
              noLabel={true}
              isDisabled={!canSeeFinanceData}
            />
          </>
        ) : view.value === V_INVOICING ? (
          <>
            <MarginFilter
              margin={margin}
              onFilterSelect={onSelectChange}
              noLabel={true}
              isDisabled={!canSeeFinanceData}
            />
            <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
              <Select
                isClearable={true}
                className="select-default"
                options={[
                  { value: "notInvoiced", label: "Not Invoiced" },
                  { value: "notPaid", label: "Not Paid" }
                ]}
                value={invoiceFilter ? invoiceFilter : { value: "", label: "All Invoiced" }}
                onChange={canSeeFinanceData ? (value: any) => onSelectChange("invoiceFilter", value || "") : undefined}
                isDisabled={!canSeeFinanceData}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className="row align-items-center mt-3">
        <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
          <Select
            isClearable={true}
            className="select-default"
            value={view ? view : { value: "", label: "Default View" }}
            onChange={(value: any) => onViewChange(value ? value.value : "")}
            options={canSeeFinanceData ? VIEWS : VIEWS_PRODUCTION}
          />
        </div>
      </div>
    </>
  );
};

export default OrdersFilter;
