import React from "react";
import { LengthUnit } from "../../../model/common.types";

interface LengthUnitSelectProps {
  selectedUnit: string;
  disabled?: boolean;
  onUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LengthUnitSelect: React.FunctionComponent<LengthUnitSelectProps> = ({ selectedUnit, disabled, onUnitChange }) => {
  return (
    <select className="form-control" value={selectedUnit} onChange={onUnitChange} name="unit" disabled={disabled}>
      {Object.entries(LengthUnit).map(([key, value]) => (
        <option key={key} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default LengthUnitSelect;
