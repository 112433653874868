import { BSON } from "realm-web";
import * as RealmWeb from "realm-web";
import userService from "./userService";
import authenticationService from "./authenticationService";
import { WarehouseConfiguration } from "../model/configuration/warehouseConfiguration.types";
import { ConfigurationKeys } from "../model/configuration/configuration.types";

export const ADDITIVES = "additives";
export const ACTIVESUBSTANCES = "activesubstances";
export const ALLERGENS = "allergens";
export const BATCH = "batch";
export const CAPSULES = "capsules";
export const COLORS = "colors";
export const COMMODITIES = "commodities";
export const COMMODITYPROPERTIES = "commodityproperties";
export const COMMODITYCATEGORIES = "commoditycategories";
export const COMPANIES = "companies";
export const COMPOSITIONS = "compositions";
export const CONFIGURATION = "configuration";
export const DELIVERY = "delivery";
export const DELIVERY_ANNOUNCEMENT = "deliveryAnnouncement";
export const EMORDERS = "externalManufacturerOrders";
export const FAQS = "faqs";
export const GENERAL = "general";
export const INDIVIDUALBARCODE = "individualBarcode";
export const MANUFACTURERS = "manufacturers";
export const NEWS = "news";
export const ORDERS = "orders";
export const ORDERFEEDBACK = "orderFeedback";
export const OUTGOING = "outgoing";
export const PACKAGINGS = "packagings";
export const PASSWORDCHANGETOKENS = "passwordChangeTokens";
export const PACKAGINGORDERS = "packagingOrders";
export const PACKAGINGSTOCK = "packagingStock";
export const POHODAHISTORY = "pohodaHistory";
export const PRODUCTIONPLAN = "productionPlan";
export const REQUESTS = "requests";
export const RECENTACTIVITIES = "recentActivities";
export const RESERVATION = "reservation";
export const SAMPLERECIPES = "sampleRecipes";
export const SOLVENTS = "solvents";
export const SUPPLIERS = "suppliers";
export const TABLETS = "tablets";
export const UNIVERSALBARCODE = "universalBarcode";
export const USERDATA = "userdata";

export type Action = UpdateAction | InsertAction;

export const UPDATE = "UPDATE";
export const INSERT = "INSERT";

export interface UpdateAction {
  action?: typeof UPDATE;
  collection: string;
  filter: object;
  update?: object;
  push?: object;
  pull?: object;
  inc?: object;
  replace?: object;
  unset?: object;
  arrayFilters?: Array<object>;
}
export interface InsertAction {
  action: typeof INSERT;
  collection: string;
  object: object;
}

function getDb() {
  return userService.getUser()?.mongoClient("mongodb-atlas").db(process.env.REACT_APP_DATABASE!);
}

function getDbCollection(collection: string) {
  return getDb()?.collection(collection);
}

/**
 * Receive a change stream iterator for the given collection.
 * @param collection Name of the collection
 * @param options Contains a filter or ids
 * @returns ChangeStreamIterator of the collection
 */
async function receiveStreamIterator(collection: string, options?: {}) {
  const dbCol = userService
    .getUser()
    ?.mongoClient("mongodb-atlas")
    .db(process.env.REACT_APP_DATABASE!)
    ?.collection(collection);
  if (!dbCol) {
    return;
  }
  return dbCol.watch(options);
}

/**
 * Listen to database collections
 * @param updateDatabase function called on receiving database change to update context
 */
export function listener(updateDatabase: (change: any) => void) {
  listen(ADDITIVES, updateDatabase);
  listen(GENERAL, updateDatabase);
  listen(PACKAGINGS, updateDatabase);
  listen(COMMODITIES, updateDatabase);
  listen(SUPPLIERS, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(COMPANIES, updateDatabase);
  listen(ORDERS, updateDatabase);
  listen(CAPSULES, updateDatabase);
  listen(INDIVIDUALBARCODE, updateDatabase);
  listen(MANUFACTURERS, updateDatabase);
  listen(NEWS, updateDatabase);
  listen(REQUESTS, updateDatabase);
  listen(SOLVENTS, updateDatabase);
  listen(SAMPLERECIPES, updateDatabase);
  listen(ACTIVESUBSTANCES, updateDatabase);
  listen(ALLERGENS, updateDatabase);
  listen(COMMODITYCATEGORIES, updateDatabase);
  listen(COMMODITYPROPERTIES, updateDatabase);
  listen(COMPOSITIONS, updateDatabase);
  listen(COLORS, updateDatabase);
  listen(PRODUCTIONPLAN, updateDatabase);
  listen(EMORDERS, updateDatabase);
  listen(PACKAGINGORDERS, updateDatabase);
  listen(PACKAGINGSTOCK, updateDatabase);
  listen(POHODAHISTORY, updateDatabase);
  listen(ORDERFEEDBACK, updateDatabase);
  listen(BATCH, updateDatabase);
  listen(RESERVATION, updateDatabase);
  listen(UNIVERSALBARCODE, updateDatabase);
  listen(DELIVERY_ANNOUNCEMENT, updateDatabase);
  listen(DELIVERY, updateDatabase);
  listen(OUTGOING, updateDatabase);
}

/**
 * Listen to database collections relevant for external manufacturers
 * @param updateDatabase function called on receiving database change to update context
 */
export function listenEMCollections(updateDatabase: (change: any) => void) {
  listen(COMMODITIES, updateDatabase);
  listen(SOLVENTS, updateDatabase);
  listen(ACTIVESUBSTANCES, updateDatabase);
  listen(ALLERGENS, updateDatabase);
  listen(COMMODITYCATEGORIES, updateDatabase);
  listen(COMMODITYPROPERTIES, updateDatabase);
  listen(COMPOSITIONS, updateDatabase);
  listen(COLORS, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(EMORDERS, updateDatabase);
}

/**
 * Listen to database collections relevant for manufacturer related delivery calendar users
 * @param updateDatabase function called on receiving database change to update context
 */
export function listenDeliveryCalendarUserCollections(updateDatabase: (change: any) => void) {
  listen(COMMODITIES, updateDatabase);
  listen(PACKAGINGS, updateDatabase);
  listen(PACKAGINGORDERS, updateDatabase);
  listen(COLORS, updateDatabase);
}

/**
 * Listen to updated on warehouse configuration and update the context
 * @param updateWarehouseContext function called on receiving database change to update context
 */
async function listenerWarehouseContext(
  updateWarehouseContext: (change: Realm.Services.MongoDB.UpdateEvent<WarehouseConfiguration>) => void
) {
  const stream: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<WarehouseConfiguration>> | undefined =
    await receiveStreamIterator(CONFIGURATION, { key: ConfigurationKeys.WAREHOUSE });
  if (stream)
    try {
      for await (const change of await stream) {
        if (change.operationType === "update") updateWarehouseContext(change);
      }
    } catch (e) {
      console.error(e);
    }
}

/**
 * Listen to updated on db collection and call function to update the context
 * @param collection the collection to listen to
 * @param updateDatabase function called on receiving database change to update context
 */
async function listen(collection: string, updateDatabase: (change: any) => void) {
  const stream = await receiveStreamIterator(collection);
  try {
    for await (const change of await stream!) {
      updateDatabase(change);
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * Load the latest orders that where created within the given amount of days
 * @param days amount of days to go back to
 * @returns {Array<OrdersDocument>} list of orders that where created within the given amount of day
 */
export async function loadLatestOrders(days: number) {
  const db = getDb()!;
  const user = authenticationService.getUserDataID().toString();
  try {
    let date = new Date(new Date().setDate(new Date().getDate() - days));
    if (user) {
      return db.collection(ORDERS).find({
        createdOn: {
          $gte: date,
          $lt: new Date()
        },
        createdFrom: new RealmWeb.BSON.ObjectId(user),
        state: { $nin: ["declined", "archive"] }
      });
    } else {
      return db.collection(ORDERS).find({
        createdOn: {
          $gte: date,
          $lt: new Date()
        },
        state: { $nin: ["declined", "archive"] }
      });
    }
  } catch (e) {
    console.error("ERROR: ", e);
  }
}

/**
 * Returns the given collection as whole.
 * @param collection Name of the collection
 * @returns Content of the collection
 */
async function getCollection<T = any>(collection: string): Promise<Array<T>> {
  const documents = await userService
    .getUser()
    ?.mongoClient("mongodb-atlas")
    .db(process.env.REACT_APP_DATABASE!)
    ?.collection(collection)
    .find();
  return documents ? documents : [];
}

/**
 * Returns all documents of the given collection that matches the filter.
 * @param collection Name of the collection
 * @param filter Filter that should be applied
 */
async function getFilteredCollection(collection: string, filter: any) {
  const documents = await userService
    .getUser()
    ?.mongoClient("mongodb-atlas")
    .db(process.env.REACT_APP_DATABASE!)
    ?.collection(collection)
    .find(filter);
  return documents ? documents : [];
}

/**
 * Returns a single document from the given collection.
 * @param collection Name of the collection
 * @param id ID of the document
 * @returns Document or null if not found
 */
async function getDocumentFromCollection(collection: string, id: string) {
  const document = await userService
    .getUser()
    ?.mongoClient("mongodb-atlas")
    .db(process.env.REACT_APP_DATABASE!)
    ?.collection(collection)
    .findOne({ _id: { $oid: id } });
  return document ? document : null;
}

/**
 * Call a backend function
 * @param functionName the name of the backend function
 * @param args list of parameter
 * @param noEnv flag to indicate whether NODE_ENV should be included in the parameters or not
 * @returns {Promise<unknown>} result of the backend function
 */
async function callFunction<T = any>(functionName: string, args: Array<any>, noEnv?: boolean): Promise<T> {
  return userService.getUser()?.callFunction<any>(functionName, noEnv ? [...args] : [process.env.NODE_ENV, ...args]);
}

/**
 * Inserts a document into the given collection
 * @param collection Collection name
 * @param document Document that should be inserted
 * @returns Result of the query
 */
async function insertDocument(collection: string, document: any) {
  return getDb()?.collection(collection).insertOne(document);
}

/**
 * Inserts documents into the given collection
 * @param collection Collection name
 * @param documents Documents that shall be inserted
 * @returns Result of the query
 */
async function insertDocuments(collection: string, documents: Array<any>) {
  return getDb()?.collection(collection).insertMany(documents);
}

/**
 * Replaces a document in the given collection
 * @param collection Collection name
 * @param _id ID of the document
 * @param document Document that should be inserted
 * @returns Result of the query
 */
async function replaceDocument(collection: string, _id: BSON.ObjectId, document: any) {
  return getDb()?.collection(collection).updateOne({ _id }, document);
}

/**
 * Updates a document in the given collection
 * @param collection Collection name
 * @param _id ID of the document
 * @param update Update that should be performed
 * @returns Result of the query
 */
async function updateDocument(collection: string, _id: BSON.ObjectId, update: any) {
  return getDb()?.collection(collection).updateOne({ _id }, { $set: update });
}

/**
 * Updates multiple documents in the given collection.
 * WARNING: Sloppy queries can lead to massive changes inside the database that can not be undone easily.
 * @param collection Collection name
 * @param _ids IDs of the documents that should be updated
 * @param update Update that should be performed
 * @returns Result of the query
 */
async function updateDocuments(collection: string, _ids: Array<BSON.ObjectId>, update: any) {
  return getDb()
    ?.collection(collection)
    .updateMany({ _id: { $in: _ids } }, { $set: update });
}

/**
 * Deletes a document from the given collection
 * @param collection Collection name
 * @param _id ID of the document
 * @returns Result of the query
 */
async function deleteDocument(collection: string, _id: BSON.ObjectId) {
  return getDb()?.collection(collection).deleteOne({ _id });
}

/**
 * @deprecated use transaction instead
 * Performs multiple database updates as a transaction.
 * @param actions Updates that should be performed as transaction
 * @returns { boolean } True if the transaction was successful, False if not
 */
async function updatesAsTransaction(actions: Array<UpdateAction>) {
  return callFunction("updateAsTransaction", [actions]);
}
/**
 * Performs multiple database updates and/or insertions as a transaction.
 * @param actions Updates/Insertions that should be performed as transaction
 * @returns { boolean } True if the transaction was successful, False if not
 */
async function transaction(actions: Array<Action>) {
  return callFunction("transaction", [actions]);
}

/**
 * @deprecated not updated any more
 * Calls the order as JSON function in backend and returns the JSON.
 * @param orderId ID of the order
 * @returns { string } Order as JSON
 */
async function exportOrderAsJSON(orderId: BSON.ObjectId) {
  return callFunction("orderAsJSON", [{ orderId }]);
}

// eslint-disable-next-line
export default {
  callFunction,
  deleteDocument,
  exportOrderAsJSON,
  getCollection,
  getDb,
  getDocumentFromCollection,
  getFilteredCollection,
  insertDocument,
  insertDocuments,
  receiveStreamIterator,
  replaceDocument,
  transaction,
  updatesAsTransaction,
  updateDocument,
  updateDocuments,
  getDBCollection: getDbCollection,
  listener,
  listenEMCollections,
  listenDeliveryCalendarUserCollections,
  listenerWarehouseContext,
  loadLatestOrders
};
