import _ from "lodash";
import React, { useCallback } from "react";
import i18n from "../../../../translations/i18n";
import { OrderReservationStateOverview } from "../../../../utils/orderUtils";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import { OrdersDocument } from "../../../../model/orders.types";
import Tooltip from "../../../common/Tooltip";

interface OrderReservationStateInformationProps {
  orderState: OrderReservationStateOverview;
  order: OrdersDocument;
}

const OrderReservationStateInformation: React.FC<OrderReservationStateInformationProps> = ({ orderState, order }) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch({
        type: WarehouseActionType.TRIGGER_ACTION,
        payload: {
          actionNumber: WarehouseActionNumber.CREATE_RESERVATION,
          orderId: order._id.toString()
        }
      });
    },
    [order._id]
  );

  return (
    <div className="kt-user-card-v2" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <Tooltip
          tooltipText={
            <div className="text-left">
              <div>{i18n.t("warehouse:commoditiesAvailable", { amount: orderState.ready })}</div>
              <div>{i18n.t("warehouse:commoditiesReservedForOther", { amount: orderState.possible })}</div>
              <div>{i18n.t("warehouse:commoditiesMissing", { amount: orderState.missing })}</div>
            </div>
          }
        >
          <span className={"kt-user-card-v2__name kt-font-bold " + orderState.color}>
            {_.upperFirst(i18n.t("warehouse:" + orderState.state))}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default OrderReservationStateInformation;
