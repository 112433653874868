import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { useDataContext } from "../../../../context/dataContext";
import { resolveTranslation } from "../../../../utils/translationUtils";
import {
  DEFAULTWEIGHTUNIT,
  SelectedBatchEntryType,
  SelectedCommodityEntryType
} from "../../../../utils/warehouseUtils";
import {
  BaseActionModalProps,
  LocationType,
  PUStorageSpaceAssignment,
  StorageSpaceOccupations
} from "../../../../model/warehouse/common.types";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { getStorageSpaceSelectOptions, hasDuplicateLocations } from "../../../../utils/warehouseStorageSpaceUtils";
import dbService, { BATCH } from "../../../../services/dbService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import PackagingUnitEntry from "../../common/PackagingUnitEntry";
import { getNumericValue } from "../../../../utils/baseUtils";
import { isSelectedBatchEntries, isSelectedCommodityEntries } from "../../../../utils/warehouseActionUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { compareBatchLocations } from "../../../../utils/batchUtils";
import StorageSpaceRecommendations from "./StorageSpaceRecommendations";

enum ViewAction {
  NEXT = "next",
  PREVIOUS = "previous"
}

interface AssignStorageSpaceModalState {
  view: number;
  saving: boolean;
  batchEntry?: Batch;
  relevantLocations: Array<BatchLocation>;
  currentLocation?: BatchLocation;
  pUStorageSpaceAssignment: PUStorageSpaceAssignment;
  initialStorageSpaceAssignment: PUStorageSpaceAssignment;
  storageSpaceSearch: string;
  lastSelected: {
    packagingUnitId: string;
    index: number;
  };
}

const getDefaultState = (): AssignStorageSpaceModalState => {
  return {
    view: 0,
    saving: false,
    batchEntry: undefined,
    relevantLocations: [],
    currentLocation: undefined,
    pUStorageSpaceAssignment: {},
    initialStorageSpaceAssignment: {},
    storageSpaceSearch: "",
    lastSelected: {
      packagingUnitId: "",
      index: 0
    }
  };
};

const getUpdatedStorageSpaceAssignment = (newLocation: BatchLocation | undefined, initial?: boolean) => {
  const updatedData: PUStorageSpaceAssignment = {};
  if (newLocation && newLocation.packagingUnits) {
    newLocation.packagingUnits.forEach(packagingUnit => {
      const key = packagingUnit._id.toString();
      updatedData[key] = [
        {
          quantity: packagingUnit.quantity ?? 0,
          location: initial
            ? undefined
            : newLocation.location.storageSpace?._id.toString() ?? newLocation.location.warehouseArea._id.toString(),
          locationType: newLocation.location.storageSpace ? LocationType.STORAGESPACE : LocationType.PHYSICAL_WAREHOUSE
        }
      ];
    });
  }
  return updatedData;
};

const AssignStorageSpaceModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { batch, updateDocumentInContext } = dataContext;
  const { selectedEntries, configuration } = warehouseContext;

  const [state, setState] = useState(getDefaultState());

  useEffect(() => {
    if (show) {
      const newState = getDefaultState();
      let selectedLocations: Array<string> = [];
      if (selectedEntries.length > 0 || actionTrigger) {
        if (actionTrigger) {
          newState.batchEntry = batch.find(b => b._id.toString() === actionTrigger.batchId);
          if (newState.batchEntry) selectedLocations = actionTrigger.locationId ? [actionTrigger.locationId] : [];
        } else if (isSelectedBatchEntries(selectedEntries)) {
          // parent batch from selected location
          newState.batchEntry = batch.find(b => b._id.toString() === selectedEntries[0].parentId);
          if (newState.batchEntry) {
            // all locations from selection
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedBatchEntryType.LOCATION)
              .map(sE => sE.childId!);
          }
        } else if (isSelectedCommodityEntries(selectedEntries)) {
          const batchLocationEntry = selectedEntries.find(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION);
          newState.batchEntry = batchLocationEntry
            ? batch.find(b => b._id.toString() === batchLocationEntry.batchId)
            : undefined;
          if (newState.batchEntry) {
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedCommodityEntryType.BATCH_LOCATION)
              .map(sE => sE.locationId!);
          }
        }
        if (newState.batchEntry) {
          newState.relevantLocations = newState.batchEntry.locations.filter(
            l =>
              l.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED &&
              selectedLocations.includes(l._id.toString())
          );
          if (newState.relevantLocations.length > 0) {
            // start on first location
            newState.currentLocation = newState.relevantLocations[0];
            if (newState.currentLocation) {
              const assignment = getUpdatedStorageSpaceAssignment(newState.currentLocation, true);
              newState.initialStorageSpaceAssignment = assignment;
              newState.pUStorageSpaceAssignment = assignment;
            }
          }
        }
      }
      setState(newState);
    }
  }, [show, batch, selectedEntries]);

  const currentPhysicalWarehouse = useMemo(() => {
    const { currentLocation } = state;
    const warehouses = configuration?.values.warehouseStructure;
    return warehouses && currentLocation
      ? warehouses
          .flatMap(w => w.physicalWarehouses)
          .find(pW => pW._id.toString() === currentLocation.location.warehouseArea._id.toString())
      : undefined;
  }, [state.currentLocation, configuration?.values.warehouseStructure]);

  const currentLocationName = useMemo(
    () => state.currentLocation?.location.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:incomingTab"),
    [state.currentLocation]
  );

  const storageSpaceOccupations = useMemo(() => {
    const { currentLocation } = state;
    if (!currentLocation || !currentPhysicalWarehouse) return undefined;
    const occupation: StorageSpaceOccupations = {};

    // Create entries for all existing storage spaces of the current physical warehouse, including its entrance
    const entranceKey = currentPhysicalWarehouse._id.toString();
    occupation[entranceKey] = {
      locationSnapshot: {
        locationName: i18n.t("warehouse:incomingTab"),
        warehouseAreaId: currentPhysicalWarehouse._id.toString()
      },
      batchList: new Set<string>(),
      contentList: new Set<string>(),
      totalAmount: { value: 0, unit: DEFAULTWEIGHTUNIT }
    };
    if (currentPhysicalWarehouse.storageSpaces && currentPhysicalWarehouse.storageSpaces.length > 0) {
      for (let storageSpace of currentPhysicalWarehouse.storageSpaces) {
        const storageSpaceKey = `${currentPhysicalWarehouse._id.toString()}_${storageSpace._id.toString()}`;
        occupation[storageSpaceKey] = {
          locationSnapshot: {
            locationName: storageSpace.storageSpaceNo,
            warehouseAreaId: currentPhysicalWarehouse._id.toString(),
            storageSpaceId: storageSpace._id.toString(),
            dimensions: storageSpace.dimensions,
            maxWeight: storageSpace.maxWeight
          },
          batchList: new Set<string>(),
          contentList: new Set<string>(),
          totalAmount: { value: 0, unit: DEFAULTWEIGHTUNIT }
        };
      }
    }

    // Check for each storage space which batches and which commodities are located there, and how much is stored there in total
    for (let batchObj of batch) {
      const batchId = batchObj._id.toString();
      const contentId = batchObj.content.details._id.toString();
      for (let locationObj of batchObj.locations) {
        const { location, amountAtLocation } = locationObj;
        // Only include locations from the current physical warehouse
        if (!compareBatchLocations(location, currentLocation.location, true)) continue;
        const batchLocationKey =
          location.warehouseArea._id.toString() +
          (location.storageSpace ? `_${location.storageSpace?._id.toString()}` : "");
        if (occupation[batchLocationKey]) {
          occupation[batchLocationKey].batchList.add(batchId);
          occupation[batchLocationKey].contentList.add(contentId);
          occupation[batchLocationKey].totalAmount = calculationUtils.addNumValueWeight(
            DEFAULTWEIGHTUNIT,
            occupation[batchLocationKey].totalAmount,
            amountAtLocation
          );
        }
      }
    }
    return occupation;
  }, [batch, state.currentLocation, currentPhysicalWarehouse]);

  const lastPage = useMemo(() => {
    const { relevantLocations, view } = state;
    return relevantLocations === undefined || relevantLocations.length <= 1 || relevantLocations.length - 1 === view;
  }, [state.relevantLocations, state.view]);

  const storageSpaceSelection = useMemo(
    () => getStorageSpaceSelectOptions(currentPhysicalWarehouse),
    [currentPhysicalWarehouse]
  );

  const globalErrors = useMemo(() => {
    const { currentLocation, pUStorageSpaceAssignment } = state;
    const errors = new Set<string>();
    if (!currentLocation) errors.add(i18n.t("warehouse:storageSpaceAssignmentError"));
    if (currentLocation) {
      for (let i = 0; i < currentLocation.packagingUnits.length; i++) {
        const pUId = currentLocation.packagingUnits[i]._id.toString();
        const pUQuantity = currentLocation.packagingUnits[i].quantity;
        const storageSpaceAssignmentEntry = pUStorageSpaceAssignment[pUId];
        if (storageSpaceAssignmentEntry) {
          const totalAmount = storageSpaceAssignmentEntry.reduce((acc, currentEntry) => acc + currentEntry.quantity, 0);
          if (totalAmount !== pUQuantity) {
            errors.add(i18n.t("warehouse:storageSpaceAssignmentQuantityError"));
          }
          const duplicateLocations = hasDuplicateLocations(storageSpaceAssignmentEntry);
          if (duplicateLocations) errors.add(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
        }
      }
      // Only make saving possible if relevant changes were made
      let noChangesOverall = true;
      Object.values(pUStorageSpaceAssignment).forEach(stSpAssignments => {
        const noChangesForPU = stSpAssignments.every(
          stSpA =>
            !stSpA.location ||
            stSpA.quantity === 0 ||
            stSpA.location ===
              (currentLocation.location.storageSpace?._id.toString() ??
                currentLocation.location.warehouseArea._id.toString())
        );
        if (!noChangesForPU) noChangesOverall = false;
      });
      if (noChangesOverall) errors.add(i18n.t("warehouse:storageSpaceAssignmentNoChangeError"));
    }
    return Array.from(errors);
  }, [state.currentLocation, state.pUStorageSpaceAssignment]);

  const handleLocationChange = useCallback(
    (direction: ViewAction) => {
      setState(prevState => {
        const newView = direction === ViewAction.NEXT ? prevState.view + 1 : prevState.view - 1;
        const newLocation = state.relevantLocations.length > 0 ? state.relevantLocations[newView] : undefined;
        const newAssignment = getUpdatedStorageSpaceAssignment(newLocation, true);
        const newLastSelected = {
          packagingUnitId: "",
          index: 0
        };
        const newStorageSpaceSearch = "";
        return {
          ...prevState,
          view: newView,
          currentLocation: newLocation,
          lastSelected: newLastSelected,
          pUStorageSpaceAssignment: newAssignment,
          initialStorageSpaceAssignment: newAssignment,
          storageSpaceSearch: newStorageSpaceSearch
        };
      });
    },
    [state.relevantLocations]
  );

  const handleAddRow = useCallback(
    (packagingUnitId: string) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      rows[packagingUnitId].push({ quantity: 0, location: undefined, locationType: LocationType.STORAGESPACE });
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handleDeleteRow = useCallback(
    (packagingUnitId: string, index: number) => {
      const { pUStorageSpaceAssignment, lastSelected } = state;
      const rows = _.cloneDeep(pUStorageSpaceAssignment);
      let updatedLastSelected = lastSelected;
      rows[packagingUnitId].splice(index, 1);
      if (lastSelected.packagingUnitId === packagingUnitId && lastSelected.index > rows[packagingUnitId].length - 1) {
        updatedLastSelected = {
          packagingUnitId: "",
          index: 0
        };
      }
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows,
        lastSelected: updatedLastSelected
      }));
    },
    [state.pUStorageSpaceAssignment, state.lastSelected]
  );

  const handleChangeAmount = useCallback(
    (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      const value = getNumericValue(e);
      const entry = rows[packagingUnitId][index];
      entry.quantity = Number(value);
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handeChangeLocation = useCallback(
    (packagingUnitId: string, index: number, value?: any) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      const entry = rows[packagingUnitId][index];
      entry.location = value && value.length > 0 ? value[0].value : undefined;
      entry.locationType = value && value.length > 0 ? value[0].data : LocationType.STORAGESPACE;
      setState(prevState => ({
        ...prevState,
        storageSpaceSearch: "",
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handleBlurLocation = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    let value = e.target.value.toLowerCase();
    setState(prevState => ({
      ...prevState,
      storageSpaceSearch: value
    }));
  }, []);

  const handleFocusLocation = useCallback((packagingUnitId: string, index: number) => {
    setState(prevState => ({
      ...prevState,
      lastSelected: {
        packagingUnitId,
        index
      }
    }));
  }, []);

  const handleSelectRecommendation = useCallback(
    (storageSpaceId: string) => {
      const { lastSelected } = state;
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      // If no input was selected, set chosen recommended location for all inputs
      if (!lastSelected.packagingUnitId) {
        for (const key in rows) {
          for (let stSpAssignment of rows[key]) {
            stSpAssignment.location = storageSpaceId;
            stSpAssignment.locationType = LocationType.STORAGESPACE;
          }
        }
      } else {
        // otherwise set only for last selected input field
        const entry = rows[lastSelected.packagingUnitId][lastSelected.index];
        entry.location = storageSpaceId;
        entry.locationType = LocationType.STORAGESPACE;
      }
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment, state.lastSelected]
  );

  const handleSave = useCallback(async () => {
    const { batchEntry, currentLocation, pUStorageSpaceAssignment, initialStorageSpaceAssignment } = state;
    if (!batchEntry || !currentLocation) return;
    setState(prevState => {
      return { ...prevState, saving: true };
    });
    try {
      const result = await dbService.callFunction<boolean>(
        "assignStorageSpaces",
        [batchEntry._id, currentLocation._id, pUStorageSpaceAssignment, initialStorageSpaceAssignment],
        true
      );
      if (result) {
        toast.success(i18n.t("warehouse:storageSpaceAssignmentSuccess"));
        updateDocumentInContext(BATCH, batchEntry._id);
      } else {
        toast.error(i18n.t("warehouse:storageSpaceAssignmentFailure"));
      }
    } finally {
      setState(prevState => {
        return { ...prevState, saving: false };
      });
      if (lastPage) {
        onHide();
      }
    }
  }, [state, lastPage]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:assignStorageSpace")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {state.batchEntry && (
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-10">
                  <span className="kt-font-bold kt-font-dark">
                    {resolveTranslation(state.batchEntry.content.details.title)}
                  </span>
                  <span className="kt-font-dark ml-2">
                    {i18n.t("warehouse:lot")}: {state.batchEntry.lot}
                  </span>
                </div>
                {state.currentLocation && (
                  <div className="col-2 kt-font-dark">
                    <small className="float-right">{`${i18n.t("common:page")} ${state.view + 1}/${
                      state.relevantLocations?.length
                    }`}</small>
                  </div>
                )}
                <div className="col-12 kt-font-bold kt-font-dark">
                  <small className="kt-font-regular">
                    {resolveTranslation(state.batchEntry.content.details.subtitle)}
                  </small>
                </div>
              </div>
              {state.relevantLocations && state.relevantLocations.length > 0 && state.currentLocation ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-2">
                        <li className="breadcrumb-item text-black">
                          {resolveTranslation(state.currentLocation.location.warehouseSnapshot.warehouseName)}
                        </li>
                        <li className="breadcrumb-item text-black">
                          {resolveTranslation(state.currentLocation.location.warehouseArea.warehouseName)}
                        </li>
                        <li className="breadcrumb-item text-danger font-weight-bolder arrow">{currentLocationName}</li>
                      </ul>
                    </div>
                  </div>
                  {state.currentLocation.packagingUnits.map(pU => {
                    return (
                      <PackagingUnitEntry
                        key={pU._id.toString()}
                        pU={pU}
                        currentLocationName={currentLocationName}
                        storageSpaceAssignment={state.pUStorageSpaceAssignment[pU._id.toString()]}
                        selectOptions={storageSpaceSelection}
                        storageSpaceOccupations={storageSpaceOccupations}
                        onAddRow={handleAddRow}
                        onDeleteRow={handleDeleteRow}
                        onChangeAmount={handleChangeAmount}
                        onChangeLocation={handeChangeLocation}
                        onBlurLocation={handleBlurLocation}
                        onFocusLocation={handleFocusLocation}
                      />
                    );
                  })}
                  <StorageSpaceRecommendations
                    storageSpaceOccupations={storageSpaceOccupations}
                    storageSpaceSearch={state.storageSpaceSearch}
                    batch={state.batchEntry}
                    onSelectRecommendation={handleSelectRecommendation}
                  />
                </>
              ) : (
                <div className="text-muted mt-3">{i18n.t("warehouse:storageSpaceAssignmentWarehouseError")}</div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (state.saving ? "disabled" : "")}
          onClick={
            state.saving ? undefined : state.view === 0 ? onHide : () => handleLocationChange(ViewAction.PREVIOUS)
          }
        >
          {i18n.t(`common:${state.view === 0 ? "close" : "back"}`)}
        </button>
        {!lastPage && (
          <button
            type="button"
            className={"btn btn-secondary " + (state.saving ? "disabled" : "")}
            onClick={state.saving ? undefined : () => handleLocationChange(ViewAction.NEXT)}
          >
            {i18n.t("common:next")}
          </button>
        )}
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className={"btn btn-success"}
          errors={globalErrors}
          saving={state.saving}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AssignStorageSpaceModal;
