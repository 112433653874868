import _ from "lodash";
import {
  SelectedBatchEntry,
  SelectedCommodityEntry,
  SelectedDeliveryAnnouncementEntry,
  SelectedDeliveryEntry,
  SelectedDestinationEntry,
  SelectedEntryType,
  SelectedOrderEntry,
  SelectedReservationEntry,
  WarehouseActionType
} from "../context/warehouseContext";
import {
  SelectedBatchEntryType,
  SelectedCommodityEntryType,
  SelectedDestinationEntryType,
  SelectedOrderEntryType
} from "./warehouseUtils";
import { Batch } from "../model/warehouse/batch.types";

export enum WarehouseActionNumber {
  CREATE_STOCK = 0,
  BOOK_DELIVERY = 1,
  ASSIGN_STORAGE_SPACE = 2,
  SPLIT_GROUP = 3,
  CREATE_RESERVATION = 4,
  CANCEL_RESERVATION = 5,
  BOOK_OUT = 6,
  SEND = 7,
  SEND_TO_PRODUCTION = 8,
  PRINT_DELIVERY_NOTES = 9,
  PRINT_PICK_LIST = 10,
  REPORT_DAMAGE = 11,
  CHANGE_BATCH_DATA = 12,
  CHANGE_BDD = 13,
  RETROFITTING_STORAGE = 14,
  EXPORT_CSV = 15,
  READ_CODE = 16
}

/**
 * Checks if all selected entries are from the same parent entry
 * @param selection the selected entries to check
 * @returns true if only one entry is selected, all selected children belong to that entry and/or all selected children have the same parent; false if not
 */
export function selectionFromOneEntry(selection: Array<SelectedEntryType>): boolean {
  // Check if nothing or more than one batch is selected
  if (selection.length === 0) return false;
  if (isSelectedBatchEntries(selection)) {
    const batchSelections = selection.filter(s => s.type === SelectedBatchEntryType.BATCH);
    if (batchSelections.length > 1) {
      return false;
    }
    // Check if selected locations are all from selected batch
    const locationSelections = selection.filter(s => s.type === SelectedBatchEntryType.LOCATION);
    if (batchSelections.length === 1 && locationSelections.length > 0) {
      const batchParentId = batchSelections[0].parentId;
      return locationSelections.every(locationEntry => locationEntry.parentId === batchParentId);
    }
    // Check if one batch and no other locations are selected
    // or if no batch is selected and all selected locations are from the same parent
    return (
      (batchSelections.length === 1 && locationSelections.length === 0) ||
      (batchSelections.length === 0 &&
        locationSelections.length > 0 &&
        new Set(locationSelections.map(entry => entry.parentId)).size === 1)
    );
  } else if (isSelectedCommodityEntries(selection)) {
    const uniqueBatches = Array.from(
      new Set(selection.filter(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION).map(entry => entry.batchId))
    );
    return uniqueBatches.length === 1 && !!uniqueBatches[0];
  }
  return false;
}

/**
 * Checks if the selection is blocked
 * @param selection the selected entries to check
 * @param batches the batch collection to resolve the batch of the selection
 * @returns true if the batch from the selection or all packaging units of it are blocked, false if not
 */
export function selectionIsBlocked(selection: Array<SelectedEntryType>, batches: Array<Batch>): boolean {
  if (selection.length === 0) return false;
  if (isSelectedBatchEntries(selection)) {
    const locationSelections = selection.filter(s => s.type === SelectedBatchEntryType.LOCATION);
    return locationSelections.every(lS => {
      const batch = batches.find(b => b._id.toString() === lS.parentId);
      if (batch && batch.blocked) return true;
      if (batch && lS.childId) {
        return batch.locations.some(bL => bL._id.toString() === lS.childId && bL.packagingUnits.every(p => p.blocked));
      }
      return true;
    });
  } else if (isSelectedCommodityEntries(selection)) {
    const locationSelections = selection.filter(s => s.type === SelectedCommodityEntryType.BATCH_LOCATION);
    return locationSelections.every(lS => {
      const batch = batches.find(b => b._id.toString() === lS.batchId);
      if (batch && batch.blocked) return true;
      if (batch && lS.locationId) {
        return batch.locations.some(
          bL => bL._id.toString() === lS.locationId && bL.packagingUnits.every(p => p.blocked)
        );
      }
      return true;
    });
  }
  return false;
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedBatchEntry>} the updated selected entries
 */
export function updateSelectedBatchEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_BATCH_ENTRY; payload: SelectedBatchEntry }
): Array<SelectedBatchEntry> {
  const entries = isSelectedBatchEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isBatchWithChildren =
    action.payload.type === SelectedBatchEntryType.BATCH &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    entry =>
      entry.type === action.payload.type &&
      entry.parentId === action.payload.parentId &&
      entry.childId === action.payload.childId
  );
  // batch was checked and is now unchecked (deselect batch and locations beneath)
  if (checked && isBatchWithChildren) {
    return entries.filter(e => e.parentId !== action.payload.parentId);
  } else if (checked && action.payload.type === SelectedBatchEntryType.LOCATION) {
    // batch location was checked and is now unchecked (deselect location and parent batch)
    return entries.filter(
      e =>
        !(
          e.parentId === action.payload.parentId &&
          e.childId === action.payload.childId &&
          e.type === SelectedBatchEntryType.LOCATION
        ) && !(e.parentId === action.payload.parentId && e.type === SelectedBatchEntryType.BATCH)
    );
  } else if (checked) {
    return entries.filter(
      e =>
        e.parentId !== action.payload.parentId || e.childId !== action.payload.childId || e.type !== action.payload.type
    );
  }
  // batch was unchecked and is now checked (select batch and locations beneath)
  if (isBatchWithChildren) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedBatchEntryType.LOCATION,
        parentId: action.payload.parentId,
        numberOfChildren: action.payload.currentChildren!.length,
        childId: action.payload.currentChildren![i]
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedBatchEntryType.LOCATION) {
    // batch location was unchecked and is now checked (select location, if all sibling locations are checked, select parent batch)
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.parentId === action.payload.parentId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedBatchEntryType.BATCH,
        parentId: action.payload.parentId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload]; // entry was unchecked and is now checked
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedCommodityEntry>} the updated selected entries
 */
export function updateSelectedCommodityEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_COMMODITY_ENTRY; payload: SelectedCommodityEntry }
): Array<SelectedCommodityEntry> {
  const entries = isSelectedCommodityEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isCommodityWithChildren =
    action.payload.type === SelectedCommodityEntryType.COMMODITY &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    entry =>
      entry.type === action.payload.type &&
      entry.commodityId === action.payload.commodityId &&
      entry.batchId === action.payload.batchId &&
      entry.locationId === action.payload.locationId
  );

  if (checked && isCommodityWithChildren) return entries.filter(e => e.commodityId !== action.payload.commodityId);
  else if (checked && action.payload.type === SelectedCommodityEntryType.BATCH_LOCATION)
    return entries.filter(
      e =>
        !(
          e.commodityId === action.payload.commodityId &&
          e.batchId === action.payload.batchId &&
          e.locationId === action.payload.locationId &&
          e.type === SelectedCommodityEntryType.BATCH_LOCATION
        ) && !(e.commodityId === action.payload.commodityId && e.type === SelectedCommodityEntryType.COMMODITY)
    );
  else if (checked)
    return entries.filter(
      e =>
        e.commodityId !== action.payload.commodityId ||
        e.batchId !== action.payload.batchId ||
        e.locationId !== action.payload.locationId ||
        e.type !== action.payload.type
    );

  if (isCommodityWithChildren) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedCommodityEntryType.BATCH_LOCATION,
        commodityId: action.payload.commodityId,
        batchId: action.payload.currentChildren![i][0],
        locationId: action.payload.currentChildren![i][1],
        numberOfChildren: action.payload.currentChildren!.length
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedCommodityEntryType.BATCH_LOCATION) {
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.commodityId === action.payload.commodityId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedCommodityEntryType.COMMODITY,
        commodityId: action.payload.commodityId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload];
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedDeliveryAnnouncementEntry>} the updated selected entries
 */
export function updateSelectedDeliveryAnnouncements(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_DELIVERY_ANNOUNCEMENT_ENTRY; payload: SelectedDeliveryAnnouncementEntry }
): Array<SelectedDeliveryAnnouncementEntry> {
  const entries = isSelectedDeliveryAnnouncementEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const checked = entries.some(
    e =>
      e.type === action.payload.type &&
      e.deliveryAnnouncementId === action.payload.deliveryAnnouncementId &&
      e.childId === action.payload.childId
  );
  if (checked) {
    return entries.filter(
      e =>
        !(
          e.type === action.payload.type &&
          e.deliveryAnnouncementId === action.payload.deliveryAnnouncementId &&
          e.childId === action.payload.childId
        )
    );
  }

  return [...entries, action.payload];
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedOrderEntry>} the updated selected entries
 */
export function updateSelectedOrderEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_ORDER_ENTRY; payload: SelectedOrderEntry }
): Array<SelectedOrderEntry> {
  const entries = isSelectedOrdersEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isOrderWithCommodities =
    action.payload.type === SelectedOrderEntryType.ORDER &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    e =>
      e.type === action.payload.type &&
      e.orderId === action.payload.orderId &&
      e.recipeCommodityId === action.payload.recipeCommodityId
  );
  // order was checked and is now unchecked (deselect order and commodities beneath)
  if (checked && isOrderWithCommodities) {
    return entries.filter(e => e.orderId !== action.payload.orderId);
  } else if (checked && action.payload.type === SelectedOrderEntryType.COMMODITY) {
    // commodity was checked and is now unchecked (deselect commodity and parent order)
    return entries.filter(
      e =>
        !(
          e.orderId === action.payload.orderId &&
          e.recipeCommodityId === action.payload.recipeCommodityId &&
          e.type === SelectedOrderEntryType.COMMODITY
        ) && !(e.orderId === action.payload.orderId && e.type === SelectedOrderEntryType.ORDER)
    );
  } else if (checked) {
    return entries.filter(
      e =>
        e.orderId !== action.payload.orderId ||
        e.recipeCommodityId !== action.payload.recipeCommodityId ||
        e.type !== action.payload.type
    );
  }
  // order was unchecked and is now checked (select order and commodities beneath)
  if (isOrderWithCommodities) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedOrderEntryType.COMMODITY,
        orderId: action.payload.orderId,
        numberOfChildren: action.payload.currentChildren!.length,
        recipeCommodityId: action.payload.currentChildren![i]
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedOrderEntryType.COMMODITY) {
    // commodity was unchecked and is now checked (select commodity, if all sibling commodities are checked, select parent order)
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.orderId === action.payload.orderId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedOrderEntryType.ORDER,
        orderId: action.payload.orderId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload]; // entry was unchecked and is now checked
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedDestinationEntry>} the updated selected entries
 */
export function updateSelectedDestinationEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_DESTINATION_ENTRY; payload: SelectedDestinationEntry }
): Array<SelectedDestinationEntry> {
  const entries = isSelectedDestinationsEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const isDestinationWithChildren =
    action.payload.type === SelectedDestinationEntryType.DESTINATION &&
    action.payload.currentChildren !== undefined &&
    action.payload.currentChildren.length > 0;
  const checked = entries.some(
    e =>
      e.type === action.payload.type &&
      e.destinationId === action.payload.destinationId &&
      e.batchId === action.payload.batchId &&
      e.packagingUnitId === action.payload.packagingUnitId
  );
  // destination was checked and is now unchecked (deselect location and outgoing objects beneath)
  if (checked && isDestinationWithChildren) {
    return entries.filter(e => e.destinationId !== action.payload.destinationId);
  } else if (checked && action.payload.type === SelectedDestinationEntryType.OUTGOING) {
    // outgoing object was checked and is now unchecked (deselect outgoing object and parent destination)
    return entries.filter(
      e =>
        !(
          e.destinationId === action.payload.destinationId &&
          e.batchId === action.payload.batchId &&
          e.packagingUnitId === action.payload.packagingUnitId &&
          e.type === SelectedDestinationEntryType.OUTGOING
        ) && !(e.destinationId === action.payload.destinationId && e.type === SelectedDestinationEntryType.DESTINATION)
    );
  } else if (checked) {
    return entries.filter(
      e =>
        e.destinationId !== action.payload.destinationId ||
        e.batchId !== action.payload.batchId ||
        e.packagingUnitId !== action.payload.packagingUnitId ||
        e.type !== action.payload.type
    );
  }
  // destination was unchecked and is now checked (select destination and outgoing objects beneath)
  if (isDestinationWithChildren) {
    const newSelectionEntries = [action.payload];
    for (let i = 0; i < action.payload.currentChildren!.length; i++) {
      newSelectionEntries.push({
        type: SelectedDestinationEntryType.OUTGOING,
        destinationId: action.payload.destinationId,
        batchId: action.payload.currentChildren![i].batchId,
        packagingUnitId: action.payload.currentChildren![i].packagingUnitId,
        numberOfChildren: action.payload.currentChildren!.length
      });
    }
    return [...entries, ...newSelectionEntries];
  } else if (action.payload.type === SelectedDestinationEntryType.OUTGOING) {
    // outgoing object was unchecked and is now checked (select outgoing object, if all sibling outgoing objects are checked, select parent destination)
    const newSelectionEntries = [...entries, action.payload];
    const numberOfSelectedSiblingLocations = newSelectionEntries.filter(
      e => e.destinationId === action.payload.destinationId
    ).length;
    if (numberOfSelectedSiblingLocations === action.payload.numberOfChildren) {
      newSelectionEntries.push({
        type: SelectedDestinationEntryType.DESTINATION,
        destinationId: action.payload.destinationId,
        numberOfChildren: action.payload.numberOfChildren
      });
    }
    return newSelectionEntries;
  }
  return [...entries, action.payload]; // entry was unchecked and is now checked
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedDeliveryEntry>} the updated selected entries
 */
export function updateSelectedDeliveryEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_DELIVERY_ENTRY; payload: SelectedDeliveryEntry }
): Array<SelectedDeliveryEntry> {
  const entries = isSelectedDeliveryEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const checked = entries.some(
    entry => entry.type === action.payload.type && entry.deliveryId === action.payload.deliveryId
  );
  if (checked) {
    return entries.filter(e => !(e.type === action.payload.type && e.deliveryId === action.payload.deliveryId));
  }

  return [...entries, action.payload];
}

/**
 * Adjusts the selected entries based on the given selected entry and whether it was selected or deselected
 * @param selectedEntries the selected entries to check
 * @param action the action containing the selected entry which triggered the change
 * @returns {Array<SelectedReservationEntry>} the updated selected entries
 */
export function updateSelectedReservationEntries(
  selectedEntries: Array<SelectedEntryType>,
  action: { type: WarehouseActionType.SELECT_RESERVATION_ENTRY; payload: SelectedReservationEntry }
): Array<SelectedReservationEntry> {
  const entries = isSelectedReservationEntries(selectedEntries) ? _.cloneDeep(selectedEntries) : [];
  const checked = entries.some(
    e =>
      e.type === action.payload.type &&
      e.reservationId === action.payload.reservationId &&
      e.materialId === action.payload.materialId &&
      e.locationId === action.payload.locationId
  );
  if (checked) {
    return entries.filter(
      e =>
        e.type === action.payload.type &&
        !(
          e.reservationId === action.payload.reservationId &&
          e.materialId === action.payload.materialId &&
          e.locationId === action.payload.locationId
        )
    );
  }

  return [...entries, action.payload];
}

/**
 * Check if given entries are of type SelectedCommodityEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedCommodityEntry, else false
 */
export function isSelectedCommodityEntries(
  entries: Array<SelectedEntryType>
): entries is Array<SelectedCommodityEntry> {
  return entries.length === 0 || "commodityId" in entries[0];
}

/**
 * Check if given entries are of type SelectedBatchEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedBatchEntry, else false
 */
export function isSelectedBatchEntries(entries: Array<SelectedEntryType>): entries is Array<SelectedBatchEntry> {
  return entries.length === 0 || "parentId" in entries[0];
}

/**
 * Check if given entry is of type SelectedDeliveryAnnouncementEntry
 * @param entry selected entry
 * @returns {boolean} true if entries are of type SelectedDeliveryAnnouncementEntry, else false
 */
export function isSelectedDeliveryAnnouncement(entry: SelectedEntryType): entry is SelectedDeliveryAnnouncementEntry {
  return "deliveryAnnouncementId" in entry;
}

/**
 * Check if given entries are of type SelectedDeliveryAnnouncementEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedDeliveryAnnouncementEntry, else false
 */
export function isSelectedDeliveryAnnouncementEntries(
  entries: Array<SelectedEntryType>
): entries is Array<SelectedDeliveryAnnouncementEntry> {
  return entries.length === 0 || "deliveryAnnouncementId" in entries[0];
}

/**
 * Check if given entries are of type SelectedOrderEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedOrderEntry, else false
 */
export function isSelectedOrdersEntries(entries: Array<SelectedEntryType>): entries is Array<SelectedOrderEntry> {
  return entries.length === 0 || "orderId" in entries[0];
}

/**
 * Check if given entries are of type SelectedDestinationEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedDestinationEntry, else false
 */
export function isSelectedDestinationsEntries(
  entries: Array<SelectedEntryType>
): entries is Array<SelectedDestinationEntry> {
  return entries.length === 0 || "destinationId" in entries[0];
}

/**
 * Check if given entries are of type SelectedDeliveryEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedDeliveryEntry, else false
 */
export function isSelectedDeliveryEntries(entries: Array<SelectedEntryType>): entries is Array<SelectedDeliveryEntry> {
  return entries.length === 0 || "deliveryId" in entries[0];
}

/**
 * Check if given entries are of type SelectedReservationEntry
 * @param entries list of selected entries
 * @returns {boolean} true if entries are of type SelectedReservationEntry, else false
 */
export function isSelectedReservationEntries(
  entries: Array<SelectedEntryType>
): entries is Array<SelectedReservationEntry> {
  return entries.length === 0 || "reservationId" in entries[0];
}

/**
 * Get the batch id of a selected entry
 * @param entries list of selected commodity or batch entry
 * @returns {string | undefined} id of a selected batch, undefined in case no entry was found
 */
export function getBatchIdFromSelectedEntries(entries: Array<SelectedEntryType>): string | undefined {
  if (isSelectedBatchEntries(entries)) return entries[0].parentId;
  const entry = entries.find(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION) as SelectedCommodityEntry;
  return entry?.batchId;
}
